import { gql } from "@apollo/client";

export const GET_ALL_CUSTOMERS = gql`
  query getAllCustomers(
    $accountid: String
    $page: Int
    $per_page: Int
    $search: String
  ) {
    getAllCustomers(
      accountid: $accountid
      page: $page
      per_page: $per_page
      search: $search
    ) {
      count
      rows {
        id
        firstname
        lastname
        email
        number
        date
        points_balance
        referral_code
        point {
          id
          amount
          transaction_date
          transaction_type
          transaction_description
        }
      }
    }
  }
`;
