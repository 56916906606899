import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
// graphql
import { useQuery } from "@apollo/client";
import { GET_ORDER } from "graphql/orders/order";


const OrderDetail = (props) => {
    // get Id
    const getId = props.match.params.id;
    // query
    const { loading, error, data } = useQuery(GET_ORDER, {
        variables: {
            id: getId
        }
    });

    if (loading)
    return (
      <div className="px-4">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Customer...</span>
        </div>
      </div>
    );

    if (error)
    return <div className="border-t text-center py-3">No Data Found :(</div>;
    
    const order = data.order;
    console.log(order);
      

    return (
        <>
            <div className="relative block w-full">
                <div className="flex flex-wrap">
                    {/* ordery history */}
                    <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white py-3">
                            <div className="rounded-t mb-0 border-0">
                                <div className="flex px-4">
                                    <div className="w-1/2">
                                        <h3 className="font-semibold text-xl mb-2">
                                        Order #{order.order_number}
                                        </h3>
                                    </div>
                                    <div className="w-1/2 text-right">
                                        <Link to={"/admin/orders"} className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                                            <i className="fas fa-chevron-left"></i>
                                            <span className="inline-block ml-1">Orders</span>
                                        </Link>
                                    </div>
                                </div>
                                {/* TABLE */}
                                <table className="items-center w-full bg-transparent border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200">SKU</th>
                                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200">PRODUCT NAME</th>
                                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200">QUANTITY</th>
                                            <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200">ROW TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            order.order_product.map((order,i) => (
                                                <tr key={i}>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                                        {order.sku}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 hover:text-blue-600">
                                                        <Link to={"/admin/products/"+ order.product.id}>{order.product.name}</Link>
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                                        {order.quantity}
                                                    </td>
                                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                                                        {order.row_total}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* Customer Detail */}
                    <div className="w-full xl:w-4/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white py-3">
                            <div className="rounded-t mb-0 px-4 border-0">
                                <Link to={ "/admin/customers/"+order.customer.id } className="font-semibold text-xl hover:text-blue-600">
                                    {order.customer.firstname} {order.customer.lastname}
                                </Link>
                                <Link to={ "/admin/customers/"+order.customer.id } className="block text-sm py-1 hover:text-blue-600">
                                    {order.customer.email}
                                </Link>
                                <p className="py-2 text-sm"><Moment format="YYYY-MM-DD HH:mm">{ order.date }</Moment></p>
                                <p className="mt-4 text-base"><span className="text-gray-600">Total:</span> <strong className="tracking-wide text-xl">${ order.total }</strong></p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default OrderDetail;