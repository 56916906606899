import { gql } from '@apollo/client';

export const ADD_MEDIA = gql `
    mutation addMedia(
        $account_id: String!,
        $filename: String!,
        $type: String!,
        $folder_id: String!
    ) {
        addMedia (
            account_id: $account_id,
            filename: $filename,
            type: $type,
            folder_id: $folder_id
        )
    }
`