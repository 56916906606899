import { gql } from "@apollo/client";

export const CREATE_MEMBER = gql`
  mutation createMembership(
    $access: String!
    $account_id: String!
    $user_id: String!
    $plan_id: String!
  ) {
    createMembership(
      access: $access
      account_id: $account_id
      user_id: $user_id
      plan_id: $plan_id
    )
  }
`;
