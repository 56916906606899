import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

const DealsTable = (props) => {
    useEffect(() =>{
        props.refetch();
    });
    function numberWithCommas(num) {
        if(num != null) {
            var num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return "$" + num_parts.join(".");
        }
    }
    return (
        <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                    <tr>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>NAME</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>VALUE</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>DUE</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>Assigned</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>STAGE</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100 w-10"}>DATE</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.map((deal,i) => 
                            <tr key={i} className="border-t hover:bg-gray-100">
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    <Link to={"/admin/deals/"+ deal.id} className="font-semibold text-blue-600">{deal.name}</Link>
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    {numberWithCommas(deal.amount)}
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    { ( deal.date_due != null ) ? <Moment format="YYYY-MM-DD HH:mm">{deal.date_due}</Moment> : '' }
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                { ( deal.user != null ) ? deal.user.name : '' }
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    {deal.stage}
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    <Moment format="YYYY-MM-DD HH:mm">{deal.createdAt}</Moment>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default DealsTable;