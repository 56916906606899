import React, {useState, useContext, useEffect} from "react";
import { createPopper } from "@popperjs/core";
import { FORMS } from "graphql/forms/forms.js";
import { useQuery } from '@apollo/client';
import LeadsTable from "components/Leads/LeadsTable.js";
import LeadsExport from "components/Leads/LeadsExport.js";
import { ClientContext } from "contexts/ClientContext";
import { Link } from "react-router-dom";

const LeadForms = (props) => {
    // state
    let [ activeName, setActiveName ] = useState("");
    const [ form, setForm ] = useState("");
    // search and sort 
    const [ findSearch, setFindSearch ] = useState("");
    let [ changeStatus, setChangeStatus ] = useState("");
    const [ status, setStatus ] = useState("");
    const [ state, dispatch] = useContext(ClientContext);
   
    // GET ALL FORMS
    const { loading, error, data,refetch } = useQuery(FORMS, {
        variables: {
            account_id: props.accountId,
            page:1,
            per_page: 10,
            search:""
        }
    });

    if(loading) return  <div className="px-4 mb-3">
                            <div className="inline-block bg-white shadow-md py-2 rounded-full">
                                <span className="block px-4">Fetching Forms...</span>
                            </div>
                        </div>;
    if(error) return <p>Error. Please Try Again..</p>;

    const formsList = data.getAllForms.rows.map((form,i) => 
        <option key={i} value={form.id}>
            {form.name}
        </option>
    )

    let initialForm;
    let initialFormName;

    return (
        <>
        {/* FILTERS */}
        <div className="bg-white">
            <div className="py-4 px-6">
                <div className="flex flex-wrap w-full items-center">
                    <div className="w-full lg:w-4/12">
                        {/* SEARCH */}
                        <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                            <div className="relative flex w-full flex-wrap items-stretch">
                                <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-2">
                                    <i className="fas fa-search"></i>
                                </span>
                                <input 
                                // disabled //remove if search is working
                                // read-only //remove if search is working and remove class: bg-gray-200
                                type="text" 
                                placeholder="Search Lead.." 
                                className="px-2 py-2 placeholder-gray-400 text-gray-700 relative bg-white bg-white border text-sm outline-none focus:outline-none focus:shadow-outline w-full pl-10"
                                onChange={ (e) => { setFindSearch(e.target.value) } }
                                />
                            </div>
                        </form>
                    </div>
                    <div className="w-full lg:w-5/12 text-right">
                        {/* Choose Form */}
                        <h3 className="inline-block mr-2">Form: </h3>
                        <select className="border px-2 py-2 focus:outline-none focus:shadow-outline"
                            value={activeName}
                            onChange={ (e) => { setActiveName(e.target.value);
                                setForm(e.target.value); } }
                        >
                            <option value="">All Leads</option>
                            {formsList}
                        </select>
                    </div>
                    {/* Choose Status */}
                    <div className="w-full lg:w-3/12 text-right">
                            <h3 className="inline-block mr-2 ">Status: </h3>
                            <select className="border px-2 py-2 mr-3 focus:outline-none focus:shadow-outline" name="status" value={changeStatus}
                                onChange={ (e) => { setChangeStatus(e.target.value);
                                    setStatus(e.target.value); }  }
                            >
                                <option value="">All</option>
                                <option value="New">New</option>
                                <option value="Contacted">Contacted</option>
                                <option value="Qualified">Qualified</option>
                                <option value="Disqualified">Disqualified</option>
                                
                            </select>
                        </div>
                </div>
            </div>
        </div>
        {/* END OF FILTER */}
        
        {/* TABLE, PAGINATION, CTA */}
        <div className="bg-white mt-5 px-6">
            {/* HEADER */}
            <div className="flex flex-wrap items-center py-3">
                <div className="w-full lg:w-6/12">
                    <h3 className="tracking-wide font-bold">LEADS</h3>
                </div>
                <div className="w-full lg:w-6/12 lg:text-right">
                    <Link to={"/admin/leads/"+ props.accountId + "/new" } className="inline-block bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                        <i className="fas fa-plus-circle"></i>
                        <span className="inline-block ml-1">New</span>
                    </Link>
                    <LeadsExport formId={form} accountId={props.accountId} initialForm={initialForm}/>
                </div>
            </div>
            {/* END OF HEADER */}
            {/* TABLE */}
            {
                loading ? (
                    <div className="px-4 mb-3">
                        <div className="inline-block bg-white shadow-md py-2 rounded-full">
                            <span className="block px-4">Fetching Leads...</span>
                        </div>
                    </div>
                ) : error ? (
                    <div className="border-t text-center py-3">No Data Found</div>
                ) : (
                    <div className="mt-1">
                        <LeadsTable formId={form} accountId={props.accountId} initialForm={initialForm} search={findSearch} status={status}/>
                    </div>
                )
            }
            {/* END OF TABLE */}
        </div>
        </>
    )
}

export default LeadForms;