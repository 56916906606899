import { gql } from '@apollo/client';

export const ADD_DEAL = gql `
    mutation addDeal(
        $account_id: String!, 
        $lead_id: String, 
        $customer_id: String,
        $name: String!,
        $stage: String,
        $description: String,
        $date_due: DateTime,
        $amount: Float
    )
    {
    addDeal(
        account_id: $account_id, 
        lead_id: $lead_id,
        customer_id: $customer_id,
        name: $name,
        stage: $stage,
        description: $description,
        date_due: $date_due,
        amount: $amount
    )
}

`