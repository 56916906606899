import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
// datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// graphql
import { ClientContext } from "contexts/ClientContext";
import { useMutation } from "@apollo/client";
import { ADD_POINT } from "graphql/customers/add-point";

const CustomerAddPoint = (props) => {
    // CONTEXT to get Account Id
    const [ state ] = useContext(ClientContext);
    // STATE
    const [ isTransactionDate, setTransactionDate ] = useState();
    const [ isExpiryDate, setExpiryDate ] = useState();
    // REDIRECT
    let history = useHistory();
    // mutation
    const [ addPointMutation ] = useMutation(ADD_POINT, {
        onCompleted: () => {
            toast.success("New Point Added!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar"
            });
            setTimeout(() => history.push('/admin/customers/'+ props.match.params.id),2000);
        },
        onError: (error) => { console.log(error, "error"); }
    })
    // useForm
    const { handleSubmit, register, errors } = useForm();
    const submitForm = (fields) => {
        addPointMutation({
            variables: {
                account_id: state.clientId,
                customer_id: props.match.params.id,
                amount: parseInt(fields.points_amount),
                salevalue: parseFloat(fields.sales_value),
                transaction_date: isTransactionDate,
                transaction_type: fields.transaction_type,
                transaction_description: fields.description,
                expiry: isExpiryDate,
            }
        });
    }

    return (
        <>
            <div className="relative block w-full">
                <div className="px-4">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
                        {/* Header */}
                        <div className="rounded-t mb-0 px-2 py-3 border-0">
                            <div className="flex flex-wrap items-center">
                                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3 className={ "font-semibold text-lg "}>
                                        Add New Point
                                    </h3>
                                </div>  
                                <div>
                                    <Link to={"/admin/customers/"+ props.match.params.id} className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                                        <i className="fas fa-chevron-left"></i>
                                        <span className="inline-block ml-1">Back</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* END OF HEADER */}
                        {/* FORM */}
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className="px-6 py-3">
                                <div className="flex flex-wrap mb-3">
                                    {/* TRANSACTION TYPE */}
                                    <div className="w-full lg:w-6/12 pr-0 pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                Transaction Type</label>
                                            <select 
                                            name="transaction_type"
                                            ref={(register)}
                                            className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                                <option value="sales">Sales</option>
                                                <option value="referral">Referral</option>
                                                <option value="review">Review</option>
                                                <option value="signup">Signup</option>
                                                <option value="imported">Imported</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* TRANSACTION DATE */}
                                    <div className="w-full lg:w-6/12 pr-0 lg:pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                Transaction Date</label>
                                                <DatePicker 
                                                    dateFormat="yyyy-MM-dd h:mm aa" 
                                                    name="transaction_date"
                                                    showTimeSelect
                                                    selected={isTransactionDate}
                                                    onChange={ date => { setTransactionDate(date) } } 
                                                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap mb-3">
                                    {/* SALES VALUE */}
                                    <div className="w-full lg:w-6/12 pr-0 pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                Sales Value</label>
                                            <input 
                                            type="text"
                                            name="sales_value"
                                            ref={ (register({
                                                required: {message: "Sales Value is required", value: true}
                                            })) }
                                            className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"/>
                                            { errors.sales_value && (<span className="mt-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-500 bg-red-200 uppercase last:mr-0 mr-1">{ errors.sales_value.message }</span>)  }
                                        </div>
                                    </div>
                                    {/* POINT CONVERSION */}
                                    <div className="w-full lg:w-6/12 pr-0 lg:pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                Points Conversion</label>
                                                <input 
                                                type="text"
                                                name="points_conversion"
                                                ref={(register)}
                                                className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap mb-3">
                                    {/* POINTS AMOUNT */}
                                    <div className="w-full lg:w-6/12 pr-0 pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                Points Amount</label>
                                                <input 
                                                type="text"
                                                name="points_amount"
                                                ref={(register)}
                                                className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"/>
                                        </div>
                                    </div>
                                    {/* EXPIRY */}
                                    <div className="w-full lg:w-6/12 pr-0 lg:pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                EXPIRY</label>
                                                <DatePicker 
                                                    dateFormat="yyyy-MM-dd h:mm aa" 
                                                    name="expiry"
                                                    showTimeSelect 
                                                    selected={isExpiryDate}
                                                    onChange={date => { setExpiryDate(date) }}
                                                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                />
                                        </div>
                                    </div>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    >Description</label>
                                    <textarea 
                                    name="description"
                                    ref={(register)}
                                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                    </textarea>
                                </div>
                                {/* SUBMIT BTN */}
                                <div className="pt-0 pb-6">
                                    <button type="submit" className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">Submit</button>
                                </div>
                                <ToastContainer />
                            </div>
                        </form>
                        {/* END OF FORM */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerAddPoint;