import { gql } from '@apollo/client';

export const UPDATE_DEAL = gql `
    mutation updateDeal(
        $id: String!, 
        $account_id: String!,
        $lead_id: String,
        $customer_id: String, 
        $name: String!,
        $description: String,
        $date_due: DateTime,
        $amount: Float
        ){
        updateDeal(
            id:$id,
            account_id: $account_id,
            lead_id: $lead_id,
            customer_id: $customer_id, 
            name: $name,
            description: $description,
            date_due: $date_due,
            amount: $amount
        )
    }
`