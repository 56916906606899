import React, { useContext } from "react";
import { ME } from "graphql/user/login.js"
import { useQuery } from '@apollo/client';
import { AuthContext } from "contexts/AuthContext.js";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { Link, NavLink } from "react-router-dom";

// function MyProfile() {
  
// }

export default function Navbar(props) {

  const [ state, dispatch ] = useContext(AuthContext);
  console.log ('token', state.token);

  const { loading, error, data } = useQuery(ME);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  // return(
  //   <div>
  //   {/* <p>Account id {data.me.id}</p> */}
  //   <p className="mr-2 text-white">{data.me.name}</p>
  //   {/* <p>Account Email {data.me.email}</p> */}
  //   </div>
  // );
  const dataName = data.me.name;
  const dataEmail = data.me.email;
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-no-wrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-black text-sm uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            { (window.location.href.indexOf("/admin/dashboard") !== -1 ? 'Dashboard': '') }
            { (window.location.href.indexOf("/admin/pages") !== -1 ? 'Contents': '') }
            { (window.location.href.indexOf("/admin/contenttypes") !== -1 ? 'Content Types': '') }
            { (window.location.href.indexOf("/admin/contenttags") !== -1 ? 'Content Tags': '') }
            { (window.location.href.indexOf("/admin/customers") !== -1 ? 'Customers': '') }
            { (window.location.href.indexOf("/admin/leads") !== -1 ? 'Leads': '') }
            { (window.location.href.indexOf("/admin/deals") !== -1 ? 'Deals': '') }
            { (window.location.href.indexOf("/admin/media") !== -1 ? 'Media': '') }
            { (window.location.href.indexOf("/admin/products") !== -1 ? 'Products': '') }
            { (window.location.href.indexOf("/admin/forms") !== -1 ? 'Forms': '') }
            { (window.location.href.indexOf("/admin/mails") !== -1 ? 'Mails': '') }
            { (window.location.href.indexOf("/admin/emailjobs") !== -1 ? 'Email Jobs': '') }
            { (window.location.href.indexOf("/admin/logs") !== -1 ? 'Logs': '') }
            { (window.location.href.indexOf("/admin/settings") !== -1 ? 'Settings': '') }
            { (window.location.href.indexOf("/admin/rewards") !== -1 ? 'Rewards': '') }
            { (window.location.href.indexOf("/admin/orders") !== -1 ? 'Orders': '') }
            {/* <p>Token { state.token }</p> */}
          </a>
          {
            (window.location.href.indexOf("/admin/deals") !== -1 ?
            <div className="ml-3 bg-gray-300 rounded">
              <NavLink exact to={'/admin/deals'} activeClassName="px-3 text-xl inline-block bg-blue-300 text-white rounded" className="px-3 text-xl inline-block text-black rounded hover:text-gray-400" ><i className="fas fa-bars"></i></NavLink>
              <NavLink exact to={'/admin/deals/board'} activeClassName="px-3 text-xl inline-block bg-blue-300 text-white rounded" className="px-3 text-xl inline-block text-black rounded hover:text-gray-400" ><i className="fas fa-columns"></i></NavLink>
              <NavLink exact to={'/admin/deals/calendar'} activeClassName="px-3 text-xl inline-block bg-blue-300 text-white rounded" className="px-3 text-xl inline-block text-black rounded hover:text-gray-400" ><i className="fas fa-calendar-alt"></i></NavLink>
            </div>
            : <></> )
          }
          {/* Form */}
          <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-4">
            <div className="relative flex w-full flex-wrap items-stretch">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-2">
                <i className="fas fa-search"></i>
              </span>
              <input
                type="text"
                placeholder="Search here..."
                className="px-3 py-2 placeholder-gray-400 text-gray-700 relative bg-white bg-white rounded text-sm outline-none focus:outline-none focus:shadow-outline w-full pl-10"
              />
            </div>
          </form>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown userEmail={dataEmail} /> 
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
