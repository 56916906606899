import React, { useContext, useState } from "react";
// gql
import { useQuery } from "@apollo/client";
import { ME } from "graphql/user/login";
import { GENERAL_SETTINGS } from "graphql/account/general-settings";
// components
import { ClientContext } from "contexts/ClientContext";
import ChartMain from "components/Dashboard/ChartMain.js";
import LogsTable from "components/Log/LogsTable";

// greeting
const d = new Date();
const time = d.getHours();
let greeting;
time < 12 ? greeting = "Good Morning" : time < 20 ? greeting = "Good Afternoon" : greeting = "Good Evening";
function Capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}
const Dashboard = () => {
  let moduleCount = 0;
  // get client id
  const [ state ] = useContext(ClientContext);
  const [ fullFeedSize, setFullFeedSize ] = useState(false);
  // query
  const { loading, error, data } = useQuery(ME);
  const { loading: accountLoading,
  error: accountError, data: accountData} = useQuery(GENERAL_SETTINGS, {
    variables: { id: state.clientId }
  });
  if(accountLoading) return <p>Loading</p>;
  if(accountError) return <p>Loading</p>;
  if(accountData.account.general_settings != null) {
    moduleCount = accountData.account.general_settings.modules.length;
  }

  return (
    <div className="px-4">
      {/* Greetings */}
      <div className="mb-2">
        <h1 className="text-2xl font-semibold px-2">{greeting}, { loading ? (<></>) : error ? <></> : data.me.name }</h1>
      </div>
      <div className="flex flex-wrap justify-between">
        {/* Charts */}
        { accountLoading ? (
            <p>Loading</p>
          ) : accountError ? (
            <p>Error Try Again.</p>
          ) : (
            accountData.account.general_settings != null ? (
              accountData.account.general_settings.modules.map((item,i)  => (
                <div className="w-full xl:w-6/12 p-2">
                  <ChartMain clientId={state.clientId} measures={Capitalize(item)}/>
                </div>
              ))
            ) : (<></>)
          )
        }
        {/* FEED */}
        <div className={ `p-2 ${ moduleCount > 2 ? "xl:w-6/12" : "w-full"}` }>
          <div className="bg-white h-full">
            {/* card header */}
            <div className="flex items-center py-2 px-4">
              <div className="w-1/2">
                <h1 className="text-xl font-semibold">Feed</h1>
              </div>
            </div>
            {/* card content */}
            <div className="px-4 pb-4">
              <LogsTable accountId={state.clientId}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;