import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

const MediaTable = (props) => {
    useEffect(() =>{
        props.refetch();
    });
    return (
        <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                    <tr>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>THUMBNAIL</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>FILE NAME</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>TYPE</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>FOLDER ID</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100 w-10"}>CREATED</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100 w-10"}>VIEW</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.map((media,i) => 
                            <tr key={i} className="border-t hover:bg-gray-100">
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    <img src={"https://marketcloudco-resized.s3-ap-southeast-1.amazonaws.com/"+ media.account_id + '/' + media.folder_id + '/' + media.filename} style={{maxHeight: 100 + 'px'}} />
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    <Link to={"/admin/media/"+ media.id} className="font-semibold text-blue-600">{media.filename}</Link>
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    {media.type}
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    {media.folder_id}
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    <Moment format="YYYY-MM-DD HH:mm">{media.createdAt}</Moment>
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    <a target="_blank" href={"https://d2w3j2ad8yyl6t.cloudfront.net/"+ media.account_id + '/' + media.folder_id + '/' + media.filename} className="font-semibold text-blue-600">VIEW</a>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default MediaTable;