import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { ClientContext } from "../../../contexts/ClientContext.js";
import { Account } from "graphql/user/login";
import { CREATE_API_TOKEN } from "../../../graphql/account/create-api-token.js";

import ButtonRow from "components/Settings/ButtonRow.js";
import { ToastContainer, toast } from "react-toastify";

// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

const CardApi = () => {
  const [state] = useContext(ClientContext);
  let history = useHistory();

  const { loading, error, data } = useQuery(Account, {
    variables: {
      id: state.clientId,
    },
  });
  let newApi = "";
  const [createAPITokenMutation, {data: data1, loading: loading1, error: error1}] = useMutation(CREATE_API_TOKEN, {
    onCompleted: (data) => {
      toast.success("API token generated!" + data.generateAPIToken.token, {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
      // console.log('myApitoken', data);
      setTimeout(() => history.push("/admin/settings/api"));
      
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  function createAPI(apiEmail) {
    console.log(apiEmail);
    createAPITokenMutation({
      variables: {
        email: apiEmail,
      },
    });
  }

  let currentId = "";  
  
  if(data1){
    newApi = data1.generateAPIToken.token;
    // console.log('test token', newApi)
  }else{
    newApi = "";
  };
  
  console.log(data1 === undefined ? 'test' : 'ugh')
  console.log("data.account.membership", data)

  if (loading || loading1) return <p>Loading...</p>;
  if (error || error1) return <p>No Data Found :(</p>;

  const userInfo = data.account.membership
    .filter((roleExclude) => roleExclude.user.role.includes("api"))
    .map((membership, i) => (
      <tr key={i} className="border-t hover:bg-gray-100">
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          <Link
            to={"/admin/settings/api/" + membership.id}
            className="font-semibold text-blue-600"
          >
            {membership.user.name} <br></br>{" "}
            <p className="font-bold text-blue-600">{membership.user.email}</p>
          </Link>
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {membership.access}
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {membership.user.role}
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          <button className="text-red-500 font-bold uppercase text-xs outline-none focus:outline-none mr-4 ease-linear transition-all duration-150">
            Remove
          </button>
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          <button
            className="text-blue-500 font-bold uppercase text-xs outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
            onClick={() => {
              currentId = membership.user.email;
              createAPI(currentId);
            }}
          >
            Generate API Token
          </button>
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-pre-line py-4">
          {newApi}
          {/* {tokenDisplay} */}
        </td>
      </tr>
    ));

  if (data.account.membership === null) return <p>No Data Found :(</p>;

  return (
    <div className="px-4">
        <div className="bg-white">
          {/* SETTINGS MENU */}
          <ButtonRow/>
          {/* HEADER */}
          <div className="flex flex-wrap items-center p-4">
            <div className="w-full lg:w-6/12">
              <h3 className="font-semibold tracking-wide">API</h3>
            </div>
            <div className="w-full lg:w-6/12 text-right">
              <Link
                to={"/admin/settings/adduser"}
                className="inline-block bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              >
                <i className="fas fa-plus-circle"></i>
                <span className="inline-block ml-1">Add API</span>
              </Link>
            </div>
            <div className="block w-full overflow-x-auto">
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      className={
                        "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                      }
                    >
                      Team Member
                    </th>
                    <th
                      className={
                        "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                      }
                    >
                      Access
                    </th>
                    <th
                      className={
                        "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                      }
                    >
                      Role
                    </th>
                    <th
                      className={
                        "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                      }
                    >
                      Edit/Remove
                    </th>
                    <th
                      className={
                        "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                      }
                    >
                      Action
                    </th>
                    <th
                      className={
                        "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                      }
                    >
                      Token
                    </th>
                  </tr>
                </thead>
                <tbody>{userInfo}</tbody>
              </table>
              <ToastContainer />
            </div>
          </div>
          {/* FORM OR TABLE */}
          <div className="pb-4 px-4">
          <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    className={
                      "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                    }
                  >
                    Team Member
                  </th>
                  <th
                    className={
                      "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                    }
                  >
                    Access
                  </th>
                  <th
                    className={
                      "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                    }
                  >
                    Role
                  </th>
                  <th
                    className={
                      "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                    }
                  >
                    Edit/Remove
                  </th>
                  <th className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"></th>
                  <th
                    className={
                      "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                    }
                  >
                    Token
                  </th>
                </tr>
              </thead>
              <tbody>{userInfo}</tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
  );
};

export default CardApi;
