import React, {useEffect, useState} from "react";
import { useQuery } from "@apollo/client";
import MediaLibrary from "components/Pages/PageMediaLibrary.js";
import { GET_ALL_MEDIA } from "graphql/media/allmedia";
import PageMediaUpload from "./PageMediaUpload";

const PageMediaLibraryModal = (props) => {
    
    const [ tabActive, setTabActive ] = useState("library");
    // get all media
    const { loading, error, data ,refetch} = useQuery(GET_ALL_MEDIA, {
        variables: {
            account_id: props.accountId,
            page: 1,
            per_page: 1000,
            search: "",
            sortby: "",
            sort_direction: "desc"
        }
    });

    if(loading) return <p>Loading...</p>;
    if(error) return <p>No Data Found...</p>;

    function closeModal() {
        props.toggle();
    }

    return (
        <div className="modal">
            <div className="modal_content p-8">
                {/* modal header */}
                <div className="modal_header relative items-center">
                    <div>
                        <div className="text-xl text-blue-500 tracking-wide font-bold">
                            <i className="fas fa-file-image mr-2"></i>
                            Featured Image
                        </div>
                    </div>
                    <div className="text-right mt-3">
                        <ul className="flex flex-wrap">
                            <li className={`${ tabActive === "upload" ? "border-b-2 border-blue-700 text-blue-600" : "" } tracking-wide px-3 py-2 mr-3 cursor-pointer`}
                            onClick={ () => setTabActive("upload") }
                            >Upload File</li>
                            <li className={`${ tabActive === "library" ? "border-b-2 border-blue-700 text-blue-600" : "" } tracking-wide px-3 py-2 cursor-pointer`}
                            onClick={ () => setTabActive("library") }
                            >Media Library</li>
                        </ul>
                    </div>

                    <div className="absolute modal_close_btn">
                        <button onClick={ () => closeModal() } className="bg-gray-300 w-10 h-10 rounded-full hover:shadow-md">
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                {/* modal content */}
                <div className="border-t">
                    {
                        tabActive === "upload" ? (
                            <PageMediaUpload accountId={props.accountId} pageId={props.pageId} toggle={props.toggle}/>
                        ) : (
                            <MediaLibrary accountId={props.accountId} pageId={props.pageId} toggle={props.toggle} {...{refetch}} allMedia={data.getAllMedia}/>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default PageMediaLibraryModal;