import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { createPopper } from "@popperjs/core";
import { useQuery } from "@apollo/client";
import CustomerTable from "components/Customers/CustomerTable.js";
import { ClientContext } from "contexts/ClientContext";
import CustomersExport from "components/Customers/CustomersExport.js";

const CustomerForms = (props) => {
  const [findSearch, setFindSearch] = useState("");
  // get Client context
  const [state, dispatch] = useContext(ClientContext);


  // // Dropdown
  // const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  // const btnDropdownRef = React.createRef();
  // const popoverDropdownRef = React.createRef();
  // const openDropdownPopover = () => {
  //   createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
  //     placement: "bottom-start",
  //   });
  //   setDropdownPopoverShow(true);
  // };
  // const closeDropdownPopover = () => {
  //   setDropdownPopoverShow(false);
  // };


  return (
    <>
      {/* FILTERS */}
      <div className="bg-white">
            <div className="py-4 px-6">
                <div className="flex flex-wrap w-full items-center">
                    <div className="w-full lg:w-6/12">
                        {/* SEARCH */}
                        <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                            <div className="relative flex w-full flex-wrap items-stretch">
                                <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-2">
                                    <i className="fas fa-search"></i>
                                </span>
                                <input
                                type="text" 
                                placeholder="Search Customers" 
                                className="px-2 py-2 placeholder-gray-400 text-gray-700 relative bg-white bg-white border text-sm outline-none focus:outline-none focus:shadow-outline w-full pl-10"
                                onChange={(e) => {
                                  setFindSearch(e.target.value);
                                }}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      {/* END OF FILTERS */}

      {/* TABLE, PAGINATION, CTA */}
      <div className="bg-white mt-5 px-6">
        {/* HEADER */}
        <div className="flex flex-wrap items-center py-3">
          <div className="w-full lg:w-6/12">
            <h3 className="tracking-wide font-bold">CUSTOMERS</h3>
          </div>
          <div className="w-full lg:w-6/12 lg:text-right">
            <Link to={"/admin/customers/new" } className="inline-block bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
              <i className="fas fa-plus-circle"></i>
              <span className="inline-block ml-1">New</span>
            </Link>
            <CustomersExport accountId={props.accountId} />
          </div>
        </div>
        {/* END OF HEADER */}
        {/* TABLE */}
        <CustomerTable
          search={findSearch}
          accountId={props.accountId}
        />
        {/* END OF TABLE */}
      </div>
      {/* END OF TABLE, PAGINATION, CTA */}
    </>
  );
};

export default CustomerForms;
