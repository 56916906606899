import { gql } from "@apollo/client";

export const GET_CONTENT_TYPE = gql`
  query contenttype(
    $id: String!
  ) {
    contenttype(
      id: $id
    ) {
        id
        account_id
        name
        schema
        allow_child
        page{
          id
          name
          slug
          description
          title
        }
      
    }
  }
`;