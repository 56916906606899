import { gql } from "@apollo/client";

export const ADD_POINT = gql`
    mutation addPoint(
        $account_id: String!,
        $customer_id: String!,
        $reward_id: String,
        $amount: Int!,
        $salevalue: Float,
        $transaction_date: DateTime, 
        $transaction_type: Transaction_Type, 
        $transaction_description: String,
        $expiry: DateTime,
        ){
        addPoint(
            account_id: $account_id, 
            customer_id: $customer_id,
            reward_id: $reward_id,
            amount: $amount,
            salevalue: $salevalue,
            transaction_date: $transaction_date, 
            transaction_type: $transaction_type, 
            transaction_description: $transaction_description,
            expiry: $expiry,
            )
    }
`