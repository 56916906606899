import { gql } from '@apollo/client';

export const FORMS = gql `
query getAllForms($account_id: String, $page: Int, $per_page: Int, $search: String){
    getAllForms(account_id:$account_id, page:$page, per_page:$per_page, search:$search) {
        count
        rows {
            id
            name
            status
            body
            lead 
            {
                firstname
            }
        }
    }
}

`