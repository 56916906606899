import { gql } from "@apollo/client";

export const GET_FORM = gql`
  query form($id: String!) {
    form(id: $id) {
      id
      name
      body
      status
      form_settings {
        autorespond
        notification_email {
          bcc
          cc
          email_template
          from_email
          from_name
          subject
          to
          mail_id
        }
      }
      lead {
        body
      }
    }
  }
`;
