import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// gql
import { DELETE_FORM } from "graphql/forms/delete-form";

const FormsDeleteModal = (props) => {
    // REDIRECT TO SPECIFIC PAGE
    let history = useHistory();
    
    const [ deleteFormMutation ] = useMutation(DELETE_FORM, {
        onCompleted: () => {
            toast.success("Form Deleted!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar"
            });
            setTimeout(() => history.push('/admin/forms'));
        },
        onError: (error) => { console.log(error, "error"); }
    })
    
    function closeModal() {
        props.toggle();
    }

    function deleteForm() {
        deleteFormMutation({
            variables: {
                id: props.formId
            }
        });
    }
    return (
        <>
            <div className="modal ">
                <div className="modal_content p-8" style={ { "maxWidth": "400px" } }>
                    {/* modal content */}
                    <div className="text-center">
                        <div>
                            <i className=" fas fa-exclamation-circle text-4xl text-red-500"></i>
                            <h1 className="ml-3 text-2xl">Are you sure?</h1>
                        </div>
                        <p className="text-base text-gray-600 tracking-wide py-5">Do you want to delete<br/>
                            <strong className="text-black">{props.formName}.</strong>
                            <br/>
                            You will not be able to recover it.
                        </p>
                        <button onClick={ () => deleteForm() } className="inline-block px-6 py-3 mr-4 rounded mb-2 bg-red-500 text-white text-center uppercase font-bold bg-gray-200 text-xs focus:outline-none  hover:shadow-md ease-linear transition-all duration-150">Delete</button>
                        <button onClick={ () => closeModal() } className="inline-block px-6 py-3 rounded mb-2 bg-gray-500 text-gray-600 text-center uppercase font-bold bg-gray-200 text-xs focus:outline-none  hover:shadow-md ease-linear transition-all duration-150">Cancel</button>
                    </div>
                    {/* modal footer */}
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default FormsDeleteModal;