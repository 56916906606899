import React, {useEffect, useState} from "react";
import { DragDropContext, Draggable, Droppable,resetServerContext } from 'react-beautiful-dnd';
import { ToastContainer, toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { UPDATE_DEAL_STAGE } from "graphql/deals/update-deal-stage";

const DealBoard = (props) => {

    const [updateStageMutation] = useMutation(UPDATE_DEAL_STAGE, {
        onCompleted: () => console.log("DEAL UPDATED!"),
        onError: (error) => console.log("ERROR:", error)
    });

    function onDragUpdate(update) {
        if(!update.destination) return;

        updateStageMutation({
            variables: {
                id: update.draggableId,
                stage: update.destination.droppableId
            }
        });
    }

    function handleOnDragEnd(result) {
        if(!result.destination) return;
        
        updateStageMutation({
            variables: {
                id: result.draggableId,
                stage: result.destination.droppableId
            }
        });
    }

    resetServerContext();

    return(
        <div className="flex w-full overflow-x-auto ">
            <DragDropContext onDragStart={ handleOnDragEnd } onDragUpdate={onDragUpdate} onDragEnd={handleOnDragEnd}>
                <div style={{ width: "180px" }} className="mr-2 pb-2">
                    <div className="px-3 font-bold py-3 border-b bg-gray-300">Prospect</div>
                    <Droppable droppableId="Prospect">
                        {
                            (provided) => (
                                <ul className="p-3 bg-white" style={{ width: "180px" }} {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        props.prospect.map((prospect,i) => 
                                            <Draggable draggableId={prospect.id} key={prospect.id} index={i} type="Columns">
                                                {
                                                    (provided) => (
                                                        <li className="shadow p-3 mb-2 rounded bg-white text-xs"
                                                        ref={provided.innerRef} 
                                                        {...provided.draggableProps} 
                                                        {...provided.dragHandleProps}>
                                                            {prospect.name}
                                                        </li>
                                                    )
                                                }
                                            </Draggable>
                                        )
                                    }
                                    {provided.placeholder}
                                </ul>
                            )
                        }
                    </Droppable>
                </div>
                {/* Qualified */}
                <div style={{ width: "180px" }} className="mr-2">
                    <div className="px-3 font-bold py-3 border-b bg-gray-300">Qualified</div>
                    <Droppable droppableId="Qualified">
                        {
                            (provided) => (
                                <ul className="p-3 bg-white" style={{ width: "180px" }}  {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        props.qualified.map((qualified,i) => 
                                            <Draggable draggableId={qualified.id} key={qualified.id} index={i} type="Columns">
                                                {
                                                    (provided) => (
                                                        <li className="shadow p-3 mb-2 rounded bg-white text-xs"
                                                        ref={provided.innerRef} 
                                                        {...provided.draggableProps} 
                                                        {...provided.dragHandleProps}>
                                                            {qualified.name}
                                                        </li>
                                                    )
                                                }
                                            </Draggable>
                                        )
                                    }
                                    {provided.placeholder}
                                </ul>
                            )
                        }
                    </Droppable>
                </div>
                {/* Proposal */}
                <div style={{ width: "180px" }} className="mr-2">
                    <div className="px-3 font-bold py-3 border-b bg-gray-300">Proposal</div>
                    <Droppable droppableId="Proposal">
                        {
                            (provided) => (
                                <ul className="p-3 bg-white" style={{ width: "180px" }}  {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        props.proposal.map((proposal,i) => 
                                            <Draggable draggableId={proposal.id} key={proposal.id} index={i} type="Columns">
                                                {
                                                    (provided) => (
                                                        <li className="shadow p-3 mb-2 rounded bg-white text-xs"
                                                        ref={provided.innerRef} 
                                                        {...provided.draggableProps} 
                                                        {...provided.dragHandleProps}>
                                                            {proposal.name}
                                                        </li>
                                                    )
                                                }
                                            </Draggable>
                                        )
                                    }
                                    {provided.placeholder}
                                </ul>
                            )
                        }
                    </Droppable>
                </div>
                {/* ON HOLD */}
                <div style={{ width: "180px" }} className="mr-2">
                    <div className="px-3 font-bold py-3 border-b bg-gray-300">On Hold</div>
                    <Droppable droppableId="On Hold">
                        {
                            (provided) => (
                                <ul className="p-3 bg-white" style={{ width: "180px" }}  {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        props.onHold.map((hold,i) => 
                                            <Draggable draggableId={hold.id} key={hold.id} index={i} type="Columns">
                                                {
                                                    (provided) => (
                                                        <li className="shadow p-3 mb-2 rounded bg-white text-xs"
                                                        ref={provided.innerRef} 
                                                        {...provided.draggableProps} 
                                                        {...provided.dragHandleProps}>
                                                            {hold.name}
                                                        </li>
                                                    )
                                                }
                                            </Draggable>
                                        )
                                    }
                                    {provided.placeholder}
                                </ul>
                            )
                        }
                    </Droppable>
                </div>
                {/* Won */}
                <div style={{ width: "180px" }} className="mr-2">
                    <div className="px-3 font-bold py-3 border-b bg-gray-300">Won</div>
                    <Droppable droppableId="Won">
                        {
                            (provided) => (
                                <ul className="p-3 bg-white" style={{ width: "180px" }}  {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        props.won.map((won,i) => 
                                            <Draggable draggableId={won.id} key={won.id} index={i} type="Columns">
                                                {
                                                    (provided) => (
                                                        <li className="shadow p-3 mb-2 rounded bg-white text-xs"
                                                        ref={provided.innerRef} 
                                                        {...provided.draggableProps} 
                                                        {...provided.dragHandleProps}>
                                                            {won.name}
                                                        </li>
                                                    )
                                                }
                                            </Draggable>
                                        )
                                    }
                                    {provided.placeholder}
                                </ul>
                            )
                        }
                    </Droppable>
                </div>
                {/* Lost */}
                <div style={{ width: "180px" }} className="mr-2">
                    <div className="px-3 font-bold py-3 border-b bg-gray-300">Lost</div>
                    <Droppable droppableId="Lost">
                        {
                            (provided) => (
                                <ul className="p-3 bg-white" style={{ width: "180px" }}  {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        props.lost.map((lost,i) => 
                                            <Draggable draggableId={lost.id} key={lost.id} index={i} type="Columns">
                                                {
                                                    (provided) => (
                                                        <li className="shadow p-3 mb-2 rounded bg-white text-xs"
                                                        ref={provided.innerRef} 
                                                        {...provided.draggableProps} 
                                                        {...provided.dragHandleProps}>
                                                            {lost.name}
                                                        </li>
                                                    )
                                                }
                                            </Draggable>
                                        )
                                    }
                                    {provided.placeholder}
                                </ul>
                            )
                        }
                    </Droppable>
                </div>
            </DragDropContext>
        </div>
    )
}

export default DealBoard;