import React from "react";
import { useQuery } from "@apollo/client";
import DealComment from "components/Deals/DealComment";
import DealInformation from "components/Deals/DealInformation";
import { ME } from "graphql/user/login.js"

const DealDetail = (props) => {
    const getId = props.match.params.id;
    const { loading, error, data } = useQuery(ME);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    const adminName = data.me.name;
    
    return (
        <>
            <div className="relative block w-full overflow-x-auto">
                <div className="flex flex-wrap">
                    <div className="w-full xl:w-4/12 px-4">
                        {/* Deal Information */}
                        <DealInformation accountName={adminName} dealId={getId}/>
                    </div>
                    <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                        {/* Comment Section */}
                        <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"}>
                            {/* Header */}
                            <div className="rounded-t mb-0 px-4 py-3 border-0">
                                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3 className="font-semibold text-lg ">
                                        Comments
                                    </h3>
                                </div>
                                <DealComment accountName={adminName} dealId={getId}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DealDetail