import { gql } from "@apollo/client";

export const UPDATE_FORM = gql`
  mutation updateForm(
    $id: String!
    $account_id: String!
    $name: String
    $body: JSON
    $from_name: String
    $from_email: String
    $to: String
    $subject: String
    $status: Int
    $mail_id: String
  ) {
    updateForm(
      id: $id
      account_id: $account_id
      name: $name
      body: $body
      form_settings: {
        notification_email: {
          from_name: $from_name
          from_email: $from_email
          to: $to
          subject: $subject
          mail_id: $mail_id
        }
      }
      status: $status
    )
  }
`;
