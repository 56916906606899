import React from "react";
import DealForm from "components/Deals/DealForm";

const DealAddFormModal = (props) => {
    function closeModal(){
        props.toggle();
    }

    return (
        <>
            <div className="modal">
                <div className="modal_content p-8">
                    {/* modal header */}
                    <div className="modal_header relative">
                        <div class="text-xl text-blue-500 tracking-wide font-bold">
                            <i class="fas fa-hand-holding-usd mr-2"></i>
                            Create Deal
                        </div>
                        <div className="absolute modal_close_btn">
                            <button onClick={ () => closeModal() } className="bg-gray-300 w-10 h-10 rounded-full hover:shadow-md">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    {/* modal content */}
                    <div className="py-3 mt-4">
                        <DealForm form={props.form} lead={props.lead} forms={false} leads={false}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DealAddFormModal;