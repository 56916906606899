import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { ApolloClient, ApolloLink, createHttpLink, ApolloProvider, InMemoryCache, concat, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";

// plugins
import Moment from 'react-moment';
import 'moment-timezone';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Cookies from 'universal-cookie';
import { AuthContextProvider } from "./contexts/AuthContext.js";
import { ClientContextProvider } from "./contexts/ClientContext.js";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";

// const history = useHistory();
const cookies = new Cookies();

const httpLink = createHttpLink({
  uri: 'https://backend.marketcloud.co/graphql',
});

// On graphql connection error
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  // if 401 or 500 error remove token and client id
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if ((networkError.statusCode === 500) || (networkError.statusCode === 401)) {
      cookies.remove('token', { path: '/' });
      cookies.remove('clientId', { path: '/' });
      console.log('removing cookies');
      console.log(cookies.getAll());
    }
  }
});

// add the authorization middleware to the headers
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = cookies.get('token')
  // const token = localStorage.getItem('token');
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }));
  
  return forward(operation);
})

const client = new ApolloClient({
  link: from([
    authMiddleware,
    errorLink,
    httpLink
  ]),
  cache: new InMemoryCache(),
  fetchPolicy: "cache-first" // default
});
// const client = new ApolloClient({
//   link: concat(authMiddleware, httpLink),
//   cache: new InMemoryCache(),
//   fetchPolicy: "cache-first" // default
// });

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
    <ClientContextProvider>
    <ApolloProvider client={client}>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/" exact component={Index} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
    </ApolloProvider>
    </ClientContextProvider>
    </AuthContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
