import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ClientContext } from "../../../contexts/ClientContext.js";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";

import { ADD_PAGE } from "graphql/pages/add-pages.js";
import { getAllPages } from "graphql/pages/pages.js";
import { GET_ALL_CONTENT_TYPES } from "graphql/content-types/content-types.js";
import { GET_CONTENT_ALLOW_CHILD } from "graphql/content-types/content-allow-child.js";
// import { GET_ALL_CONTENT_TAGS } from "graphql/content-tags/content-tags.js";

// // components

const AddPages = () => {
  let history = useHistory();
  const [state] = useContext(ClientContext);
  const [ showError, setShowError ] = useState("");
  const [ slugIndicator, setSlugIndicator ] = useState("");
  const [ slugByTyping, setSlugByTyping ] = useState("");

  // const { loading, error, data, refetch } = useQuery(getAllPages, {
  //   variables: {
  //     account_id: state.clientId,
  //     page: 1,
  //     per_page: 50,
  //     search: "",
  //     status: true
  //   },
  // });
  const { loading, error, data, refetch } = useQuery(GET_CONTENT_ALLOW_CHILD, {
    variables: {
      account_id : state.clientId
    }
  })

  const {
    loading: loading1,
    error: error1,
    data: data1,
  } = useQuery(GET_ALL_CONTENT_TYPES, {
    variables: {
      account_id: state.clientId,
      page: 1,
      per_page: 20,
      search: "",
    },
  });

  // const {
  //   loading: loading2,
  //   error: error2,
  //   data: data2,
  // } = useQuery(GET_ALL_CONTENT_TAGS, {
  //   variables: {
  //     account_id: state.clientId,
  //     page: 1,
  //     per_page: 20,
  //     search: "",
  //   },
  // });

  const [addPageMutation] = useMutation(ADD_PAGE, {
    onCompleted: () => {
      refetch();
      toast.success("New page added!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
      setTimeout(() => history.push("/admin/pages/"), 2000);
    },
    onError: (error) => {
      // console.log(error.message, "error");
      setShowError(error.message);
    },
  });

  const { handleSubmit, register,errors } = useForm();

  if (loading || loading1)
    return (
      <div className="px-4 mb-3">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Pages..</span>
        </div>
      </div>
    );
  if (error || error1)
    return <div className="border-t text-center py-3">No Data Found :(</div>;

  const onSubmit = (fields) => {
    let path;
    let slug = fields.slug.split(" ").join("-");
    if (fields.parent === "root") {
      path = "/" + slug;
    } else {
      path = fields.parent + "/" + slug;
    }
    // console.log("path", path);
    // console.log("fields", fields);

    addPageMutation({
      variables: {
        account_id: state.clientId,
        parent: fields.parent,
        name: fields.name,
        slug: fields.slug,
        path: path,
        title: fields.title,
        description: fields.description,
        status: false,
        contenttype_id: fields.contenttype,
        contenttag_id: fields.contenttag,
      },
    });
  };

  const contentTypeList = data1.getAllContentTypes.rows.map(
    (contenttype, i) => (
      <option key={i} value={contenttype.id}>
        {contenttype.name}
      </option>
    )
  );

  // const contentTagList = data2.getAllContentTags.rows.map((contenttag, i) => (
  //   <option key={i} value={contenttag.id}>
  //     {contenttag.name}
  //   </option>
  // ));

  // slug indicator
  const onSlugIndicator = (val) => {
    if(val != "root") {
      setSlugIndicator(val);
    } else {
      setSlugIndicator("");
    }
  }
  return (
    <>
      <div className="relative block w-full">
        <div className="px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
            {/* Header */}
            <div className="rounded-t mb-0 px-2 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3 className={"font-semibold text-lg "}>Add Page</h3>
                </div>
                <div className="pr-4">
                  <Link
                    to={"/admin/pages"}
                    className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  >
                    <i className="fas fa-chevron-left"></i>
                    <span className="inline-block ml-1">Pages</span>
                  </Link>
                </div>
              </div>
            </div>
            {/* FORM */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="px-2 py-3">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Parent
                      </label>
                      <select
                        name="parent"
                        ref={register()}
                        className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        onChange={ (e) => { onSlugIndicator(e.target.value)  } }
                      >
                        <option value="root">Root</option>
                        {
                        data.getContentAllowChild.map((dropdown, i) => {
                            return (
                              <option
                                key={i}
                                // dropdown={dropdown}
                                value={dropdown.slug}
                              >
                                {dropdown.name}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="w-full lg:w-12/12 px-4 flex flex-wrap">
                    <div className="relative w-full mb-3">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Name
                        </label>
                        <input
                          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          name="name"
                          ref={register()}
                          onChange={ (e) => { 
                            let slugGenerator = e.target.value;
                            const updatedSlug = slugGenerator.toLowerCase()
                            .replace(/ /g,'-')
                            .replace(/[^\w-]+/g,'')
                            setSlugByTyping(updatedSlug);
                          } }
                        />
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <div className="relative w-full mb-3">
                        <label
                          className="block text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          <span className="uppercase">Slug:</span>
                          <span className="ml-2 text-gray-500">
                            {slugIndicator}/{slugByTyping}
                          </span>
                        </label>
                        <input
                          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          name="slug"
                          ref={register({
                            required: true
                          })}
                          defaultValue={slugByTyping}
                        />
                      </div>

                      {errors.slug && (
                        <span className="block text-red-500 font-semibold pb-2" role="alert">
                        <i className="fas fa-times-circle"></i> This field is required</span>
                      )}
                      {showError ? (
                        <span className="block text-red-500 font-semibold pb-2" role="alert">
                        <i className="fas fa-times-circle"></i> {showError}</span>
                      ) : (null)}
                    </div>
                    <div className="relative w-full mb-3">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Title
                        </label>
                        <input
                          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          name="title"
                          ref={register()}
                        />
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Description
                        </label>
                        <input
                          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          name="description"
                          ref={register()}
                        />
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Content Type
                        </label>
                        <select
                          name="contenttype"
                          className="border w-full p-3 mt-1 focus:outline-none"
                          ref={register()}
                        >
                          {contentTypeList}
                          <option value="">
                            NIL
                          </option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="relative w-full mb-3">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Content Tag
                        </label>
                        <select
                          name="contenttag"
                          className="border w-full p-3 mt-1 focus:outline-none"
                          ref={register()}
                          onChange={ (e) => { console.log(e); } }
                        >
                          {contentTagList}
                          <option value="">
                            NIL
                          </option>
                        </select>
                      </div>
                    </div> */}
                  </div>

                  <div className="pt-0 pb-6 px-4 text-left w-full">
                    <button
                      className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <ToastContainer />
            {/* END OF FORM */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPages;
