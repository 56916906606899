import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ClientContext } from "../../../contexts/ClientContext.js";
import { GET_EMAIL_JOB } from "graphql/email-jobs/email-job.js";

let account_id;

const EmailJobDetail = (props) => {
  const [state] = useContext(ClientContext);
  const getId = props.match.params.id;
  account_id = state.clientId;
  const currentDate = new Date();

  const { loading, error, data } = useQuery(GET_EMAIL_JOB, {
    variables: {
      id: parseInt(getId),
    },
  });

  if (loading)
    return (
      <div className="px-4 mb-3">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Email Job...</span>
        </div>
      </div>
    );
  if (error)
    return (
      console.log(error),
      (<div className="border-t text-center py-3">No Data Found :(</div>)
    );

  const email = data.emailJob;
  console.log("mailData", email.mailData);

  return (
    <>
      <div className="relative block w-full overflow-x-auto">
        <div className="px-4 mb-4">
          <Link to="/admin/emailjobs">
            <i className="text-lg fas fa-chevron-left"></i> &nbsp;{" "}
            <span className="text-xl">Back</span>
          </Link>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full mb-12 px-4">
            <div
              className={
                "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
              }
            >
              <div className="rounded-t mb-0 px-4 py-6 border-0">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3 className="font-semibold text-xl pb-4">
                    Subject: {email.emailOptions.subject}
                  </h3>
                </div>
                <div className="px-4 py-2 text-sm">
                  <span className="text-gray-600 tracking-wide">To: </span>{" "}
                  <span className="text-black">{email.emailOptions.to}</span>
                </div>
                <div className="px-4 py-2 text-sm">
                  <span className="text-gray-600 tracking-wide">From: </span>{" "}
                  <span className="text-black">
                    {email.emailOptions.from_email}
                  </span>
                </div>
                <div className="px-4 py-2 text-sm">
                  <span className="text-gray-600 tracking-wide">
                    Email Template:
                  </span>{" "}
                  <span className="text-black">
                    {email.emailOptions.email_template}
                  </span>
                </div>

                <h4 className="font-semibold text-md px-4 mt-4">Mail Information</h4>
                {email.mailData === null ? '' : Object.keys(email.mailData).map((key, i) => (
                  <div key={i} className="px-4 py-2 text-sm">
                    <span className="text-gray-600 tracking-wide">{key}: </span>
                    <span className="text-black">{email.mailData[key] === null ? '' :email.mailData[key]}</span>
                  </div>
                ))}

                <h4 className="font-semibold text-md px-4 mt-4">Mailing Status</h4>
                <div className="px-4 py-2 text-sm">
                  <span className="text-gray-600 tracking-wide">
                    Send Status:
                  </span>{" "}
                  {email.isSent === true && (
                    <span className="text-green-500">Sent</span>
                  )}
                  {email.isSent === false &&
                    new Date(email.time).getTime() < currentDate.getTime() && (
                      <span className="text-red-500">Error</span>
                    )}
                  {email.isSent === false &&
                    new Date(email.time).getTime() > currentDate.getTime() && (
                      <span className="text-blue-500">Queued</span>
                    )}
                </div>
                <div className="px-4 py-2 text-sm">
                  <span className="text-gray-600 tracking-wide">Opened:</span>{" "}
                  {email.opened >= 1 ? (
                    <span className="text-green-500">
                      Email has been opened {email.opened} time(s)!
                    </span>
                  ) : (
                    <span className="text-red-500">
                      Email has not been opened yet.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailJobDetail;
