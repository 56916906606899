import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
// calendar
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
// gql
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_DEALS } from "graphql/deals/deals";
import { ClientContext } from "contexts/ClientContext";
import { UPDATE_DEAL_DATE_DUE } from "graphql/deals/update-deal-date-due";
// toast
import { ToastContainer, toast } from "react-toastify";

const DealCalendar = () => {
    // REDIRECT TO SPECIFIC PAGE
    let history = useHistory();
    // state 
    const [ formatEvents, setFormatEvents ] = useState("");
    // get client id
    const [ client ] = useContext(ClientContext);
    // mutation 
    const [ dealMutation ] = useMutation(UPDATE_DEAL_DATE_DUE, {
        onCompleted: () => {
            toast.success("Deal Updated", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        }, 
        onError: (error) => {
            console.log("ERROR");
        }
    });
    // get all deals
    const { loading, error, data } = useQuery(GET_ALL_DEALS, {
        variables: {
            account_id: client.clientId,
            stage: "",
            page: 1,
            per_page: 10000,
            search: "",
            sortby: "",
            sort_direction: "DESC"
        },
        onCompleted : () => {
            // console.log(data.getAllDeals.rows);
            let newFormat = [];
            data.getAllDeals.rows.map((deal) => {
                const { id, name, date_due } = deal;
                newFormat.push({id: id, title:name, date: new Date(date_due)});
            });
            setFormatEvents(newFormat);
        }
    });
    
    if(loading) return <p>Loading...</p>;
    if(error) return <p>Error Try Again</p>;

    function handleEventClick({event}) {
        console.log(event.id);
        history.push('/admin/deals/'+event.id); 
    }
    const handleEventDrop = (info) => {
        const newDate = info.event.start;
        const newId = info.event._def.publicId;

        dealMutation({
            variables: {
                id: newId,
                date_due: Date.parse(newDate)
            }
        })
   }
    return (
        <>
            <div className="px-4">
                <div className="bg-white px-6">
                    {/* HEADER */}
                    <div className="flex flex-wrap items-center py-3">
                        <div className="w-full lg:w-6/12">
                            <h3 className="tracking-wide font-bold inline-block">Calendar</h3>
                        </div>
                        <div className="w-6/12 rounded-t bg-white mb-0 text-right">
                            <Link to={"/admin/deals"} className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                                <i className="fas fa-chevron-left"></i>
                                <span className="inline-block ml-1">Deals</span>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* calendar */}
                <div className="border-t bg-white px-6 py-3">
                <FullCalendar
                        plugins={[ dayGridPlugin, interactionPlugin,listPlugin ]}
                        editable={true}
                        eventDrop={handleEventDrop}
                        eventClick={handleEventClick}
                        initialView="dayGridMonth"
                        events={formatEvents}
                    />
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}

export default DealCalendar;