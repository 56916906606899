import React, { useContext } from "react";
import { ClientContext } from "../../contexts/ClientContext.js";
import RewardTable from "components/Rewards/RewardTable.js";

export default function Rewards() {
    // GET ACCOUNT ID
    const [ state ] = useContext(ClientContext);

    return (
        <>
            <div className="relative block w-full overflow-x-auto px-4">
                <div className="bg-white px-6">
                    {/* HEADER */}
                    <div className="flex flex-wrap items-center py-3">
                        <div className="w-full lg:w-6/12">
                            <h3 className="tracking-wide font-bold">REWARDS</h3>
                        </div>
                    </div>
                    {/* TABLE */}
                    <RewardTable accountId={state.clientId}/> 
                </div>
            </div>
        </>
    )
}