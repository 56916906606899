import React, { useState,useEffect } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_PAGE } from "graphql/pages/update-pages.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PageMediaLibrary = (props) => {
    const [ imageId, setImageId  ] = useState();
    const [ imageUrl, setImageUrl ] = useState();

    useEffect(() =>{
        props.refetch();
    });
    // mutations
    const [updatedPageMutation] = useMutation(UPDATE_PAGE, {
        onCompleted: () => {
            toast.success("Image Updated!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        },
        onError: (error) => {
            console.log(error, "error");
        },
    });
    
    const submittedImage = () => {
        console.log(imageUrl);
        updatedPageMutation({
            variables: {
                id: props.pageId,
                account_id: props.accountId,
                image: imageUrl,
                path: props.pagePath,
                slug: props.pageSlug
            }
        });
        props.toggle();
    }

    return (
        <>
        <div className="overflow-y-auto mt-3" style={{ maxHeight: "300px"}}>
            <ul className="grid gap-4 grid-cols-4">
                {
                    props.allMedia.map((media, i) => 
                        <li key={i} className="cursor-pointer hover:shadow-md relative"
                        onClick={ () => { 
                            setImageId(media.id);
                            setImageUrl("https://d2w3j2ad8yyl6t.cloudfront.net/"+ media.account_id + '/' + media.folder_id + '/' + media.filename)
                        }
                        }
                        >
                            <div className="overflow-hidden" style={{height: "150px"}}>
                                <img 
                                style={{ width: "100%", height: "150px", objectFit : "cover" }}
                                src={"https://marketcloudco-resized.s3-ap-southeast-1.amazonaws.com/"+ media.account_id + '/' + media.folder_id + '/' + media.filename} />
                            </div>
                            {
                                imageId === media.id ? (
                                    <div className="absolute top-0 left-0 w-full h-full" 
                                    style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
                                    >
                                        <div className="v-center text-center">
                                            <i className="text-5xl fas fa-check-circle text-white"></i>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </li>
                    )
                }
            </ul>
        </div>
        <div className="border-t mt-4 text-right">
            <button className={`${imageId != null ? "bg-green-500 ": "bg-green-200" } text-center rounded uppercase font-bold px-3 py-2 text-white text-xs mt-2 outline-none`}
            
            onClick={() => submittedImage() }
            disabled={ imageId != null ? false : true }
            >Set Featured Image</button>
        </div>
        </>
    )
}

export default PageMediaLibrary;