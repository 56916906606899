import React, { useState } from "react";
import ReactQuill from 'react-quill';
import Moment from 'react-moment';
import { useMutation, useQuery } from "@apollo/client";
import { GET_DEAL } from "graphql/deals/deal";
import { UPDATE_DEAL_COMMENT } from "graphql/deals/update-deal-comment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DealComment = (props) => {
    // Toggle Comment Editor
    const [ editActive, setEditActive ] = useState();
    // add new data info
    let newComment; 
    let updatedText;

    const [ commentMutation ] = useMutation(UPDATE_DEAL_COMMENT, 
        {
            onCompleted: () => refetch(),
            onError: (error) => {
                console.log("Error", error);
            }
        }
    );
    // Get the Deal Information
    const { loading, error, data, refetch } = useQuery(GET_DEAL, {
        variables: {
            id: props.dealId,
            fetchPolicy: "network-first"
        }
    })
    if(loading) return <div className="px-4 mb-3">
                        <div className="inline-block bg-white shadow-md py-2 rounded-full">
                            <span className="block px-4">Fetching...</span>
                        </div>
                    </div>;
    if(error) return <div className="border-t text-center py-3">No Data Found :(</div>;
    
    const dealData = data.deal;
    let comments =  (dealData.comments != null) ? dealData.comments : [];

    // add new comment 
    const addNew = () => {
        const shortId = require('shortid');
        var newItem = {
            id: shortId.generate(),
            name: props.accountName,
            date: new Date().toISOString(),
            comment: newComment
        }
        comments = comments.concat(newItem);
        const myData = [].concat(comments)
            .sort((a, b) => a > b ? 1 : -1);
        commentMutation({
            variables: {
                id: props.dealId,
                comments: myData
            }
        })
        toast.success("Comment Added", {
            position: "bottom-right",
            className: "black-background",
            bodyClassName: "grow-font-size",
            progressClassName: "fancy-progress-bar",
        });
    }

    // update comment
    function updateComment(i) {
        let items = [...comments];
        let item = { ...items[i] };
        item.comment = updatedText;
        items[i] = item;
        
        commentMutation({
            variables: {
                id: props.dealId,
                comments: items
            }
        })
        toast.success("Comment Updated", {
            position: "bottom-right",
            className: "black-background",
            bodyClassName: "grow-font-size",
            progressClassName: "fancy-progress-bar",
        });
    }

    // delete comment
    function removeComment(i) {
        let items = comments.slice();
        items.splice(i, 1);
        comments = items;
        
        commentMutation({
            variables: {
                id: props.dealId,
                comments: items
            }
        })
        toast.error("Comment Deleted", {
            position: "bottom-right",
            className: "black-background",
            bodyClassName: "grow-font-size",
            progressClassName: "fancy-progress-bar",
        });
    }


    return (
        <div className="px-4">
            <div className="py-3">
                <ToastContainer />
                <ReactQuill onChange={ (e) => { newComment = e; }  }className="pb-4"/>
                <button className="bg-purple-500 inline-block text-center rounded uppercase font-bold px-3 py-2 text-white text-xs"
                    onClick={
                        () => {
                            addNew();
                        }
                    }
                >
                    <i className="fas fa-comments mr-2"></i>
                    ADD COMMENT
                </button>
            </div>
            {/* lists */}
            {
                comments.map( (comment, i) =>
                    <div className="py-3 border-t" key={i}>
                        <div className="flex">
                            {/* comment name */}
                            <div className="w-1/2 text-sm font-bold tracking-wider">{ comment.name }</div>   
                            {/* comment cta */}
                            <div className="w-1/2 text-right">
                                {
                                    editActive == i ? (
                                        <button className="text-gray-400 mr-3 focus:outline-none"
                                            onClick={ () => {setEditActive();} }
                                        >
                                            <i className="fas fa-pen"></i>
                                        </button>
                                    ) : (
                                        <button className="text-gray-400 mr-3 focus:outline-none"
                                            onClick={ () => {setEditActive(i);} }
                                        >
                                            <i className="fas fa-pen"></i>
                                        </button>
                                    )
                                }
                                
                                <button className="text-gray-400 focus:outline-none"
                                    onClick={ () => {removeComment(i)} }
                                >
                                    <i className="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                        {/* comment date */}
                        <div className="text-xs pb-1 text-gray-600">
                            <Moment format="YYYY-MM-DD HH:mm">{ comment.date }</Moment>
                        </div>
                        {/* comment text */}
                        {
                            editActive != i ? (
                                <p className="text-black py-2 tracking-wider text-base" dangerouslySetInnerHTML={{__html: comment.comment}}></p>
                            ) : (
                                <div className="mt-3">
                                    <ReactQuill value={comment.comment}  onChange={ (e) => {
                                        (e === "") ? updatedText = comment.comment : updatedText = e;
                                    } } className="mb-3"/>
                                    <button className="bg-green-500 inline-block text-center rounded uppercase font-bold px-3 py-2 text-white text-xs"
                                        onClick={
                                            () => {
                                                updateComment(i)
                                                setEditActive();
                                            }
                                        }
                                    >
                                        <i className="fas fa-comments mr-2"></i>
                                        Update Comment
                                    </button>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default DealComment;