import React, { useContext, useState } from "react";
import { ClientContext } from "../../../contexts/ClientContext.js";
import EmailJobsTable from "components/EmailJobs/emailJobsTable.js";
import { GET_EMAIL_JOBS } from "graphql/email-jobs/email-jobs.js";

export default function EmailJobs() {
  // GET ACCOUNT ID
  const [state] = useContext(ClientContext);

  const [findSearch, setFindSearch] = useState("");
  const [isSentStatus, setIsSentStatus] = useState("all");

  return (
    <>
      <div className="relative block w-full overflow-x-auto">
        <div className="px-4 ">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <select
                  name="status-filter"
                  onChange={(e) => {
                    setIsSentStatus(e.target.value);
                  }}
                >
                  <option value="all">All</option>
                  <option value="sent">Sent</option>
                  <option value="error">Error</option>
                  <option value="queued">Queued</option>
                </select>
                <div className="relative w-full px-2 max-w-full flex-grow flex-1">
                  <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                    <div className="relative flex w-full flex-wrap items-stretch">
                      <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                        <i className="fas fa-search"></i>
                      </span>
                      <input 
                      type="text" 
                      placeholder="Search..." 
                      className="px-2 py-2 placeholder-gray-400 text-gray-700 relative bg-white bg-white border text-sm outline-none focus:outline-none focus:shadow-outline w-full pl-10"
                      onChange={(e) => {
                        setFindSearch(e.target.value);
                      }}
                      />
                  </div>
              </form>
            </div>
            {/* <EmailJobsTable isSent={isSentStatus} search={findSearch} accountId={state.clientId} /> */}
          </div>
        </div>
      </div>
      {/* END OF FILTERS */}

      {/* TABLE */}
      <div className="bg-white mt-5 px-6">
          {/* HEADER */}
          <div className="flex flex-wrap items-center py-3">
              <div className="w-full lg:w-6/12">
                  <h3 className="tracking-wide font-bold">EMAIL JOBS</h3>
              </div>
          </div>
          {/* END OF HEADER */}
          {/* TABLE */}
          <div className="mt-1">
            <EmailJobsTable isSent={isSentStatus} search={findSearch} accountId={state.clientId} />
          </div>
          {/* END OF TABLE */}
      </div>
    </div>
    </div>
    </>
  );
}
