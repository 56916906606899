import React from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

const MediaInformation = (props) => {
    const media = props.information;  
    console.log(media);  
    return (
        <>
            <div className="bg-white py-4 px-6">
                <h1 className="text-2xl font-semibold mb-3">{ media.filename }</h1>
                <div className="py-1">
                    <span className="text-gray-600">Type: </span>
                    <span>{media.type}</span>
                </div>
                <div className="py-1">
                    <span className="text-gray-600">Folder: </span>
                    <span>{media.folder_id}</span>
                </div>
                <div className="py-1">
                    <span className="text-gray-600">Uploaded: </span>
                    <span><Moment format="YYYY-MM-DD HH:mm">{media.createdAt}</Moment></span>
                </div>
                <div className="py-4">
                    <a
                    target="_blank"
                    href={"https://d2w3j2ad8yyl6t.cloudfront.net/"+ media.account_id + '/' + media.folder_id + '/' + media.filename}
                    className="bg-purple-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    >View</a> 
                    <Link to="/admin/media"
                    className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    >Back</Link> 
                </div>
                
            </div>
        </>
    )
}

export default MediaInformation;