import { gql } from "@apollo/client";

export const UPDATE_MAIL = gql`
  mutation updateMail(
    $id: String!
    $account_id: String!
    $name: String
    $title: String
    $file: String
    $body: JSON
  ) {
    updateForm(
      id: $id
      account_id: $account_id
      name: $name
      title: $title
      file: $file
      body: $body
    )
  }
`;
