import React, { useContext, useEffect } from "react";
import { createPopper } from "@popperjs/core";
import { ME } from "graphql/user/login.js";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { Redirect } from "react-router-dom";
import { ClientContext } from "../../contexts/ClientContext.js";
import { AuthContext } from "../../contexts/AuthContext.js";

const cookies = new Cookies();

export default function NotificationDropdown() {
  const [state, dispatch] = useContext(ClientContext);
  const [stateAuth, dispatchAuth] = useContext(AuthContext);
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);

  const { loading, error, data } = useQuery(ME);

  var account_id;
  var clients;
  var clientData;

  // choose specific client then save to state
  const ChooseClient = (clientId, clientName) => {
    clientData = {
      id: clientId,
      name: clientName,
      form_id: "",
    };
    console.log('Client data', clientData);
    cookies.set('clientId',  clientId);
    dispatch({ type: "CLIENT", payload: clientData });
  };

  useEffect(() => {
    console.log("Use Effect on first load");
    let token = cookies.get('token');
    if (token) {
      console.log("Token: ", token);
      dispatchAuth({ type: "SET_TOKEN", payload: token})
    }
  }, []);

  useEffect(() => {
    console.log("Use Effect data:", data);
    let clientId = cookies.get('clientId');
    if (data) {
      if (!clientId) {
        ChooseClient(data.me.membership[0].account.id, data.me.membership[0].account.name)
      } else {
        const memberships = data.me.membership;
        console.log('memberships', memberships);
        console.log("Select client", clientId);
        const result = memberships.find(membership => membership.account.id === clientId);
        console.log('Result', result);
        ChooseClient(result.account.id, result.account.name)
      }
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <Redirect to="/auth/login" />;

  clients = data.me.membership;
  account_id = data.me.id;
  // test initial client
  // ChooseClient(account_id, clients[0].account.id, clients[0].account.name)

  // list of clients
  const ClientList = () => {
    return (
      <div>
        <ul>
          {clients.map((item, i) => (
            <li key={i}>
              <a
                href="#"
                className="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
                onClick={(e) => {
                  e.preventDefault();
                  ChooseClient(item.account.id, item.account.name);
                }}
              >
                {item.account.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // dropdown props

  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="text-gray-600 block py-1 px-3"
        href="#"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        {state.clientName} <i className="fas fa-angle-down"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        onClick={closeDropdownPopover}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
        }
      >
        <ClientList />
      </div>
    </>
  );
}
