import { gql } from '@apollo/client';

export const GET_DEAL = gql`
    query deal($id: String!){
        deal(id: $id) {
            id
            name
            description
            amount
            stage
            date_due
            account_id
            lead {
                id
                firstname
                lastname
            }
            createdAt
            updatedAt
            comments {
                id
                date
                name
                comment
            }
            user{
                id
                name
            }
        }
    }

`;
