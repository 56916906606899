import { gql } from "@apollo/client";

export const ADD_PAGE = gql`
  mutation addPage(
    $account_id: String!
    $name: String!
    $title: String
    $description: String
    $slug: String
    $path: String
    $status: Boolean
    $contenttype_id: String
  ) {
    addPage(
      account_id: $account_id
      name: $name
      title: $title
      description: $description
      slug: $slug
      path: $path
      status: $status
      contenttype_id: $contenttype_id
    )
  }
`;
