import { gql } from "@apollo/client";

export const ADD_CUSTOMER = gql `
    mutation addCustomer(
    $account_id: String!,
    $firstname: String!,
    $lastname: String,
    $email: String,
    $number: String,
    $gender: String,
    $points_balance: Int,
    $date: DateTime
    ){
    addCustomer(
        account_id: $account_id, 
        firstname: $firstname,
        lastname: $lastname,
        email: $email,
        number: $number,
        gender: $gender,
        points_balance: $points_balance,
        date: $date
    )
}

`