import React, { useContext } from "react";
import { ClientContext } from "../../../contexts/ClientContext.js";
import { Account } from "graphql/user/login";
import { UPDATE_REWARD_SETTINGS } from "graphql/account/update-reward-settings";
import { useQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ButtonRow from "components/Settings/ButtonRow.js";

// components
const CardRewardSettings = () => {
  const [state] = useContext(ClientContext);

  const [rewardMutation] = useMutation(UPDATE_REWARD_SETTINGS, {
    onCompleted: () => {
      refetch();
      toast.success("Reward Settings Updated!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    },
    onError: (error) => {
      console.log(error, "error");
      toast.error("Update Failed. Please try again!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    },
  });

  const onSubmit = (fields) => {
    console.log(fields, "testing");
    rewardMutation({
      variables: {
        account_id: state.clientId,
        sales: parseFloat(fields.sales),
        sales_type: fields.sales_type,
        referral: parseFloat(fields.referral),
        referral_type: fields.referral_type,
        review: parseInt(fields.review),
        review_type: fields.review_type,
        signup: parseInt(fields.signup),
        signup_type: fields.signup_type,
      },
    });
  };

  const { handleSubmit, register } = useForm();

  const { loading, error, data, refetch } = useQuery(Account, {
    variables: {
      id: state.clientId,
      fetchPolicy: "network-first",
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>No Data Found :(</p>;

  const rewardSettingsData = data.account.earn_settings;
  console.log("reward", rewardSettingsData);

  return (
    <>
      <div className="px-4">
        <div className="bg-white">
          {/* SETTINGS MENU */}
          <ButtonRow/>
          {/* HEADER */}
          <div className="flex flex-wrap items-center p-4">
            <div className="w-full lg:w-6/12">
              <h3 className="font-semibold tracking-wide">REWARD SETTINGS</h3>
            </div>
          </div>
          {/* FORM OR TABLE */}
          <div className="pb-4 px-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap justify-center">
              <div className="w-full">
                <div className="relative w-full mt-3 mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Sales points to dollar ratio or points per sale
                  </label>
                  <input
                    name="sales"
                    ref={register()}
                    type="number"
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={
                      rewardSettingsData === null
                        ? ""
                        : rewardSettingsData.sales
                    }
                  />
                </div>
              </div>
              <div className="w-full my-2">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Points award type
                  </label>
                  <select
                    name="sales_type"
                    ref={register()}
                    className="border w-full rounded px-3 py-2 mr-3"
                    defaultValue={
                      rewardSettingsData === null
                        ? ""
                        : rewardSettingsData.sales_type
                    }
                  >
                    <option value="flat">Flat</option>
                    <option value="ratio">Ratio</option>
                  </select>
                </div>
              </div>
              <div className="w-full my-2">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Signup points awarded
                  </label>
                  <input
                    name="referral"
                    ref={register()}
                    type="number"
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={
                      rewardSettingsData === null
                        ? ""
                        : rewardSettingsData.referral
                    }
                  />
                </div>
              </div>
              <div className="w-full my-2">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Points award type
                  </label>
                  <select
                    name="referral_type"
                    ref={register()}
                    className="border w-full rounded px-3 py-2 mr-3"
                    defaultValue={
                      rewardSettingsData === null
                        ? ""
                        : rewardSettingsData.referral_type
                    }
                  >
                    <option value="flat">Flat</option>
                  </select>
                </div>
              </div>
              <div className="w-full my-2">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Review points awarded
                  </label>
                  <input
                    name="review"
                    ref={register()}
                    type="number"
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={
                      rewardSettingsData === null
                        ? ""
                        : rewardSettingsData.review
                    }
                  />
                </div>
              </div>
              <div className="w-full my-2">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Points award type
                  </label>
                  <select
                    name="review_type"
                    ref={register()}
                    className="border w-full rounded px-3 py-2 mr-3"
                    defaultValue={
                      rewardSettingsData === null
                        ? ""
                        : rewardSettingsData.review_type
                    }
                  >
                    <option value="flat">Flat</option>
                  </select>
                </div>
              </div>
              <div className="w-full my-2">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Referral sales points to dollar ratio or points per
                    sale
                  </label>
                  <input
                    name="signup"
                    ref={register()}
                    type="number"
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={
                      rewardSettingsData === null
                        ? ""
                        : rewardSettingsData.signup
                    }
                  />
                </div>
              </div>
              <div className="w-full my-2">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Points award type
                  </label>
                  <select
                    name="signup_type"
                    ref={register()}
                    className="border w-full rounded px-3 py-2 mr-3"
                    defaultValue={
                      rewardSettingsData === null
                        ? ""
                        : rewardSettingsData.signup_type
                    }
                  >
                    <option value="flat">Flat</option>
                    <option value="ratio">Ratio</option>
                  </select>
                </div>
              </div>
              <div className="text-left w-full">
                <button
                  className="inline-block bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Submit
                </button>
              </div>
              <ToastContainer />
            </div>
          </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardRewardSettings;
