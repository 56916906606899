import { gql } from "@apollo/client";

export const GET_ALL_ORDERS = gql`
query getAllOrders($accountid: String, $page: Int, $per_page: Int, $search: String, $sortby: String, $sort_direction: String) {
  getAllOrders(
    accountid:$accountid, 
    page:$page, 
    per_page:$per_page, 
    search:$search, 
    sortby: $sortby, 
    sort_direction: 
    $sort_direction) {
    count
    rows {
    id
    total
    order_number
    date
    customer {
      id
      firstname
      lastname
      email
    }
    }
  }
}

`