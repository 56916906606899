import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
// graphql
import { ADD_CONTENT_TAG } from "graphql/content-tags/add-content-tag.js";
// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContentTypeCreate = (props) => {
  let history = useHistory();
  const accountId = props.match.params.account_id;
  // mutation
  const [addContentTypeMutation] = useMutation(ADD_CONTENT_TAG, {
    onCompleted: () => {
      toast.success("New Content Tag added!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });

      setTimeout(() => history.push('/admin/contenttags'), 3000 );
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  // form
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (fields) => {
    console.log(fields);
    addContentTypeMutation({
      variables: {
        account_id: accountId,
        name: fields.name,
        slug: fields.slug,
      },
    });
  };

  return (
    <div className="relative block w-full">
      <div className="px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
          {/* Header */}
          <div className="rounded-t mb-0 px-2 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className={"font-semibold text-lg "}>
                  Add New Content Tag
                </h3>
              </div>
              <div>
                <Link
                  to="/admin/contenttags"
                  className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                >
                  <i className="fas fa-chevron-left"></i>
                  <span className="inline-block ml-1">Content Tags</span>
                </Link>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-6 py-3">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Name
                </label>
                <input
                  name="name"
                  ref={register({
                    required: {
                      message: "Name is required",
                      value: true,
                    },
                  })}
                  type="text"
                  className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Slug
                </label>
                <input
                  name="slug"
                  ref={register()}
                  type="text"
                  className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
            {/* SUBMIT BTN */}
            <div className="pt-0 pb-6 px-6">
              <button
                type="submit"
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              >
                Submit
              </button>
            </div>
            {/* ALERT */}
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContentTypeCreate;
