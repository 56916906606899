import { gql } from '@apollo/client';

export const MAILS = gql `
query getAllMails($account_id: String, $page: Int, $per_page: Int, $search: String){
    getAllMails(account_id:$account_id, page:$page, per_page:$per_page, search:$search) {
        count
        rows {
            id
            name
            title
            body
            file
        }
    }
}

`