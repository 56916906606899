import { gql } from "@apollo/client";

export const GENERAL_SETTINGS = gql `
    query account($id: String!) {
        account (id: $id) {
            id
            name
            general_settings {
                modules
            }
        }
    }
`

// account {
//     id
//     name
//     general_settings {
//       modules
//     }