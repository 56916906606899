import React, {useEffect, useState} from "react";
// component
import PageMediaLibraryModal from "components/Pages/PageMediaLibraryModal.js";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PAGE } from "graphql/pages/page";

const PageImageForm = (props) => {
    // STATE
    const [ showModal, setShowModal ] = useState(false);
    // GET PAGE
    const { loading, error, data } = useQuery(GET_PAGE, {
        variables: {
            id:props.pageId,
            account_id: props.accountId
        },
        pollInterval: 10
    });
    if(loading) return <p>Loading...</p>;
    if(error) return <p>Error</p>;

    return (
        <>
            <div className="relative mb-3" style={{width:"300px"}}>
              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Image</label>
              <div className="overflow-hidden mb-1" style={{width:"300px" ,height:"150px"}}>
                <img src={data.page.image} style={{ width: "100%", height: "150px", objectFit : "cover" }}/>
              </div>
              <button type="button" className="w-full bg-gray-300 text-center rounded uppercase font-bold px-3 py-2 text-xs hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
              onClick={ () => { props.setShowModal(true); } }>Select Image</button>
            </div>
        </>
    )
}

export default PageImageForm;