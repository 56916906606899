import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { GET_EMAIL_JOBS } from "graphql/email-jobs/email-jobs.js";
import { useQuery } from "@apollo/client";
import { ClientContext } from "../../contexts/ClientContext.js";
import Moment from "react-moment";

const EmailJobsTable = (props) => {
  // state
  const [activePage, setActivePage] = useState(1);
  // LEADS FIRST LOAD
  const [state] = useContext(ClientContext);

  const currentDate = new Date();
  // variables
  var page = activePage;
  var totalPage = "";
  const per_page = 20;

  let isSentStatus;

  if (props.isSent === "all") {
    isSentStatus = null;
  } else if (props.isSent === "sent") {
    isSentStatus = true;
  } else if (props.isSent === "error" || props.isSent === "queued") {
    isSentStatus = false;
  }

  // Query
  const { loading, error, data, fetchMore } = useQuery(GET_EMAIL_JOBS, {
    variables: {
      account_id: props.accountId,
      page: page,
      per_page: per_page,
      sortby: "time",
      search: props.search,
      isSent: isSentStatus,
    },
  });

  if (loading)
    return (
      <div className="px-4 mb-3">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Email Jobs...</span>
        </div>
      </div>
    );
  if (error)
    return (
      console.log(error),
      (<div className="border-t text-center py-3">No Data Found :(</div>)
    );

  const pageCalculated = Math.ceil(data.getEmailJobs.count / per_page);
  totalPage = Math.round(pageCalculated);
  console.log("Count:", data.getEmailJobs.rows[0]);
  
  const emailJobs = data.getEmailJobs.rows
    .filter((data) => {
      if (
        isSentStatus === false &&
        new Date(data.time).getTime() < currentDate.getTime()
      ) {
        return data;
      } else if (
        isSentStatus === false &&
        new Date(data.time).getTime() > currentDate.getTime() &&
        props.isSent === "queued"
      ) {
        return data;
      } else if (isSentStatus === true || isSentStatus === null) {
        return data;
      }
    })
    .map((email, i) => (
      <tr key={i} className="border-t w-full border-b hover:bg-gray-100">
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          <Link to={"/admin/emailjobs/" + email.id} className="font-semibold text-blue-600">
            <Moment format="YYYY-MM-DD HH:mm">{email.time}</Moment>
          </Link>
        </td>
        <td
          className={
            email.isSent === true
              ? "text-green-500 border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4"
              : "" +
                (email.isSent === false &&
                new Date(email.time).getTime() < currentDate.getTime()
                  ? "text-red-500 border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4"
                  : "") +
                (email.isSent === false &&
                new Date(email.time).getTime() > currentDate.getTime()
                  ? "text-yellow-400 border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4"
                  : "")
          }
        >
          <Link to={"/admin/emailjobs/" + email.id} className = "font-semibold">
            {email.isSent === true && "Sent"}
            {email.isSent === false &&
              new Date(email.time).getTime() < currentDate.getTime() &&
              "Error"}
            {email.isSent === false &&
              new Date(email.time).getTime() > currentDate.getTime() &&
              "Queued"}
          </Link>
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {email.emailOptions.from_name}
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {email.emailOptions.to}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">
            {email.emailOptions.subject}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-no-wrap p-4">
            {email.opened === null ? 0 : email.opened}
        </td>
      </tr>
    ));
  // pagination
  const pages = Math.ceil(data.getEmailJobs.count / per_page);
  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers
    .slice(activePage - 1, activePage + 9)
    .map((number) => {
      return (
        <button
          className={
            activePage === number
              ? "bg-blue-500 text-white inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:text-blue-400 hover:text-white focus:outline-none" :
              "inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none border"} 
          key={number}
          onClick={() => {
            setActivePage(number);
            page = number;
            fetchMore({
              variables: { page: number },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                fetchMoreResult.getEmailJobs.rows = [
                  ...fetchMoreResult.getEmailJobs.rows,
                ];
                return fetchMoreResult;
              },
            });
          }}
        >
          {number}
        </button>
      );
    });
  return (
    <div>
      {/* TABLE */}
      <div className="relative flex flex-col min-w-0 break-words w-8/12">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
              >
                Scheduled
              </th>
              <th
                className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
              >
                Status
              </th>
              <th
                className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
              >
                From
              </th>
              <th
                className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
              >
                To
              </th>
              <th
                className="px-6 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
              >
                Subject
              </th>
              <th
                className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
              >
                Opened
              </th>
            </tr>
          </thead>
          <tbody>{emailJobs}</tbody>
        </table>
      </div>
      <div className="py-2 border-t text-right">
        {activePage > 1 ? (
          <button
            className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
            onClick={() => {
              const prevPage = activePage > 1 ? activePage - 1 : 1;
              setActivePage(prevPage);
              page = prevPage;
              fetchMore({
                variables: { page: prevPage },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  fetchMoreResult.getEmailJobs.rows = [
                    ...fetchMoreResult.getEmailJobs.rows,
                  ];
                  return fetchMoreResult;
                },
              });
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        ) : (
          ""
        )}
        {renderPageNumbers}
        {activePage < pages ? (
          <button
            className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
            onClick={() => {
              // const nextPageNum = activePage + 1;
              const nextPageNum = activePage < pages ? activePage + 1 : pages;
              setActivePage(nextPageNum);
              page = nextPageNum;
              fetchMore({
                variables: { page: nextPageNum },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  fetchMoreResult.getEmailJobs.rows = [
                    ...fetchMoreResult.getEmailJobs.rows,
                  ];
                  return fetchMoreResult;
                },
              });
            }}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default EmailJobsTable;
