import React from "react";
import { Link } from "react-router-dom";

const CustomersImport = () => {
    return(
        <>
            <div className="relative block w-full">
                <div className="px-4">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                        {/* Header */}
                        <div className="rounded-t mb-0 px-2 py-3 border-0">
                            <div className="flex flex-wrap items-center">
                                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3 className={ "font-semibold text-lg "}>
                                        Import Customers
                                    </h3>
                                </div>  
                                <div>
                                    <Link to="/admin/customers" className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                                        <i className="fas fa-chevron-left"></i>
                                        <span className="inline-block ml-1">Customers</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* END OF HEADER */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomersImport;