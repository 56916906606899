import React from "react";
import { Link } from "react-router-dom";

const ButtonRow = () => {
  const viewBtns = [
    {
      name: "Account",
      link: "/admin/settings/account",
    },
    {
      name: "Users",
      link: "/admin/settings/users",
    },
    {
      name: "Mail",
      link: "/admin/settings/mail",
    },
    {
      name: "API",
      link: "/admin/settings/api",
    },
    {
      name: "Rewards",
      link: "/admin/settings/rewards",
    },
  ];
  return (
    <>
      <div className="px-4 border-b">
      {viewBtns.map((item) => {
        return (
          <Link key={item.name} to={item.link}>
            <button
              className={
                window.location.href.indexOf(item.link) !== -1
                  ? "border-b-2 pr-3 py-3 focus:outline-none border-blue-500 text-blue-500 hover:text-blue-600 mr-4 tracking-wide"
                  : "border-b-2 pr-3 border-white py-3 focus:outline-none text-gray-700 hover:text-blue-600 mr-4 tracking-wide"
              }
              key={item.name}
            >
              {item.name}
            </button>
          </Link>
        );
      })}
      </div>
    </>
  );
};
export default ButtonRow;
