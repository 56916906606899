import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { ClientContext } from "../../../contexts/ClientContext.js";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CREATE_MEMBER } from "graphql/account/create-member";
import { User } from "graphql/user/login";

// components
let updateEmail;
let updateAccess;
let account_id;

const CardAddUsers = () => {
  const [state] = useContext(ClientContext);
  account_id = state.clientId;
  console.log("account_id", account_id);

  const [userID, setUserID] = useState([]);

  const [addUserMutation] = useMutation(CREATE_MEMBER, {
    onCompleted: () => {
      toast.success("New member added!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  const { handleSubmit, register, errors } = useForm();
  let addedUser = {};

  // run this everytime addedUser changes
  useEffect(() => {
    if (addedUser) {
      console.log("Use Effect get userid", addedUser.id);
      setUserID(addedUser.id);
    }
  }, [addedUser]);

  useEffect(() => {
    if (userID) {
      addUserMutation({
        variables: {
          access: updateAccess,
          account_id: account_id,
          user_id: userID,
          plan_id: "7624c11c-8e62-4c18-b4db-e3e718f9b419",
        },
      });
    }
  }, [userID]);

  const onSubmit = (fields) => {
    updateEmail = fields.email;
    updateAccess = fields.access;
  };

  const { loading, error, data } = useQuery(User, {
    variables: {
      email: updateEmail,
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>No Data Found :(</p>;

  addedUser = data.user;

  return (
    <>
    <div className="px-4">
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 border-0">
        <div className="bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Add New User</h6>
            <div className="w-1/2 text-right">
              <Link
                to={"/admin/settings/users"}
                className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              >
                <i className="fas fa-chevron-left"></i>
                <span className="inline-block ml-1">Settings</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="py-10 pt-0 bg-white">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-6">
                <div className="relative w-full mt-3 mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email Address
                  </label>
                  <input
                    name="email"
                    ref={register()}
                    type="email"
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full px-6">
                <div className="relative w-full mt-3 mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Access Level
                  </label>
                  <select
                    name="access"
                    ref={register()}
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  >
                    <option value="admin">Admin</option>
                    <option value="standard">Standard</option>
                  </select>
                </div>
              </div>
              <div className="mt-6 px-6">
                <button
                  className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Submit
                </button>
              </div>
              <ToastContainer />
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default CardAddUsers;
