import { gql } from "@apollo/client";

export const GET_PAGE = gql`
  query page($id: String, $account_id: String!, $path: String) {
  page(
    id: $id
    account_id: $account_id
    path: $path) {
        id
        name
        title
        description
        path
        contenttype {
          id
          name
          schema
        }
      contenttag_page {
        contenttag {
          id
          name
        }
      }
        slug
        path
        image
        body
        parent_id
        status
        order
      }
  }
`;

export const ASSIGN_CONTENT_TAG = gql`
  mutation assignContenttag(
    $account_id: String
    $page_id: String
    $contenttag_id: String
  ) {
    assignContenttag(
      account_id: $account_id
      page_id: $page_id
      contenttag_id: $contenttag_id
    )
  }
`;
