import React from "react";
import { useQuery } from "@apollo/client";
import { GET_LEAD } from "graphql/leads/lead.js";
import LeadComment from "components/Leads/LeadComment";
import LeadInformation from "components/Leads/LeadInformation";
import { ME } from "graphql/user/login";

const LeadDetail = (props) => {
    const getId = props.match.params.id;
    // static
    const { loading, error, data } = useQuery(GET_LEAD, {
        variables: {
            id: getId,
        }
    });
    const { loading: accountLoading, error: accountError, data: accountData } = useQuery(ME);

    if(loading) return  <div className="px-4">
                            <div className="inline-block bg-white shadow-md py-2 rounded-full">
                                <span class="block px-4">Fetching Lead...</span>
                            </div>
                        </div>;
    if(error) return <div className="border-t text-center py-3">No Data Found :(</div>;
    
    if(accountLoading) return <p>Loading...</p>;
    if(accountError) return <p>No Data Found :(</p>;

    const leadData = data.lead;
    let keys;
    if( leadData.body != null  ) { keys = Object.keys(leadData.body)};
    const adminName = accountData.me.name;
    
    return (
        <>
        <div className="relative block w-full overflow-x-auto">
            <div className="flex flex-wrap">
                <div className="w-full xl:w-4/12 px-4">
                    {/* MODAL AND LEFT SECTION LEAD INFORMATIONS */}
                    <LeadInformation accountName={adminName} leadId={getId}/>
                </div>
                <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                    {/* Custom Fields */}
                    {
                        leadData.body != null ? (
                            keys.map((key, i) => 
                                <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white mb-2"}>
                                    <div className="rounded-t mb-0 px-4 py-3 border-0">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className="font-semibold text-lg" style={{textTransform: "capitalize"}}>
                                                {key}
                                            </h3>
                                            <p className="text-black py-2 tracking-wider text-base">{leadData.body[key]}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div></div>
                        )
                    }
                    {/* comment section */}
                    <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"}>
                        {/* Header */}
                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3 className="font-semibold text-lg ">
                                    Comment
                                </h3>
                            </div>
                            <LeadComment accountName={adminName} leadId={getId}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default LeadDetail;