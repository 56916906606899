import { gql } from '@apollo/client';

export const ADD_LEAD = gql `
mutation addLead(
    $account_id: String!,
    $form_id: String!, 
    $firstname: String,
    $lastname: String,
    $email: String,
    $company: String,
    $phone: String,
    $body: JSONObject
    ){
    addLead(
        account_id: $account_id, 
        form_id: $form_id,
        firstname: $firstname,
        lastname: $lastname,
        email: $email,
        company: $company,
        phone: $phone,
        body: $body
        )
}

`