import React, {useContext, useEffect, useState} from "react";
// Context
import { AuthContext } from "contexts/AuthContext";
// axios
import axios from "axios";
// csv link
import { CSVLink } from "react-csv";
// moment js
import moment from 'moment';
import Customers from "views/admin/Customers/Index";

const CustomersExport = (props) => {
    const [ state ] = useContext(AuthContext);
    let [ customers, setCustomers ] = useState("");

    function fetchData() {
        const filter = {
            page: 1,
            per_page: 20,
            total_pages: 1,
            search: "",
            sortby: "",
            sort_direction: "desc",
        }
        axios({
            "method": "GET",
            "url": "https://backend.marketcloud.co/customer-download",
            "headers" : {
                "Authorization": "Bearer " + state.token
            },
            "params":{
                "account_id": props.accountId,
                "filter": filter
            }
        }).then((response) => {
            setCustomers(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect( () => {
        fetchData();
    },[fetchData, props.accountId] );


    return (
        <div className="inline-block ">
            <CSVLink className="inline-block active:bg-green-600 font-bold uppercase text-xs px-4 py-2 rounded hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                data={customers} 
                filename={"customers_"+ moment(new Date()).format()+ ".csv"}
            >
                <i className="fas fa-file-export"></i>
                <span className="inline-block ml-1">Export</span>    
            </CSVLink>
        </div>
    )
}

export default CustomersExport;