import { gql } from "@apollo/client";

export const UPDATE_CONTENT_TYPE = gql`
  mutation updateContentType(
    $id: String!
    $account_id: String!
    $name: String!
    $schema: JSON
  ) {
    updateContentType(id: $id, account_id: $account_id, name: $name, schema: $schema)
  }
`;
