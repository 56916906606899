import React, { useEffect } from "react";
// toast
import { toast } from "react-toastify";
// gql
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_CONTENT_TAGS } from "graphql/content-tags/content-tags";
import { ASSIGN_CONTENT_TAG } from "graphql/pages/page";
import { GET_PAGE } from "graphql/pages/page";
import { REMOVE_CONTENT_TAG } from "graphql/content-tags/remove-content-tag";

const PageUpdateTags = (props) => {
    // MUTATION: UPDATE ASSIGN CONTENT TAG
    const [ updateContentTagMutation ] = useMutation(ASSIGN_CONTENT_TAG, {
        onCompleted: () => {
            toast.success("Content Tag Updated!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        },
        onError: (error) => {
            console.log(error, "error");
        },
    })
    // MUTATION: REMOVE ASSIGN CONTENT TAG
    const [ removeContentTagMutation ] = useMutation(REMOVE_CONTENT_TAG, {
        onCompleted: () => {
            toast.success("Content Tag Removed!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        },
        onError: (error) => {
            console.log(error, "error");
        },
    })

    // QUERY: GET ALL CONTENT TAGS
    const { loading, error, data } = useQuery(GET_ALL_CONTENT_TAGS, {
        variables: {
            account_id: props.account_id,
            page: 1,
            per_page: 200,
            search: ""
        }
    });
    // QUERY: GET PAGE
    const { loading: pageLoading, error: pageError, data: pageData , refetch } = useQuery(GET_PAGE, {
        variables: {
            id: props.page_id,
            account_id: props.account_id
        }
    });

    useEffect(() => {
        refetch();
    }, [refetch]);

    // Change Content tag
    const onChangeTag = (e) => {
        console.log("UPDATE TAG:" , e);
        updateContentTagMutation({
            variables: {
                account_id: props.account_id,
                page_id: props.page_id,
                contenttag_id: e
            }
        });
        refetch();
    }
    // Remove Content tag
    const removeTag = (e) => {
        console.log(e);
        removeContentTagMutation({
            variables: {
                account_id: props.account_id,
                page_id: props.page_id,
                contenttag_id: e
            }
        })
    }
    
    return (
        <>
        <div className="relative mb-3">
            <div className="flex flex-wrap w-full">
                <div className="w-full lg:w-6/12 pr-4">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Content Tag</label>
                    {
                        loading ? (
                            <p className="py-3">Loading...</p>
                        ) : error ? (
                            <p className="py-3">Error. Please Try Again</p>
                        ) : (
                            <select
                            className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            onChange={ (e) => {
                                onChangeTag(e.target.value);
                            } }
                            >
                                <option selected="selected" value="">
                                NIL
                                </option>
                                {
                                    data.getAllContentTags.rows.map((contenttag, i) => (
                                        <option key={i} value={contenttag.id}>
                                            {contenttag.name}
                                        </option>
                                    ))
                                }
                            </select>
                        )
                    }
                </div>
                <div className="w-full lg:w-6/12 pl-4">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Content Tag</label>
                    <ul className="flex flex-wrap">
                        {
                            pageLoading ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Please Try Again...</p>
                            ) : (
                                pageData.page.contenttag_page.map((currentTag, index) => (
                                    <li key={index} className="mr-3 mb-1">
                                        <button className="px-3 py-1 border hover:text-red-500 outline-none focus:outline-none rounded text-sm"
                                        onClick={
                                            () => {removeTag(currentTag.contenttag.id)}
                                        }
                                        >
                                            <i className="fa fa-times text-xs inline-block"></i>
                                            <span className="ml-2 inline-block">
                                                {currentTag.contenttag.name}
                                            </span>
                                        </button>
                                    </li>
                                ))
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}

export default PageUpdateTags;