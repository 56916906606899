import { gql } from "@apollo/client";

export const UPDATE_STATUS = gql`
  mutation updatePageStatus(
    $id: String!,
    $status: Boolean
  ) {
    updatePageStatus(
      id: $id,
      status: $status
    )
  }
`;
