import React from "react";
import { useQuery } from "@apollo/client";
import { ME } from "graphql/user/login";

import ButtonRow from "components/Settings/ButtonRow.js";

const CardSettings = () => {
  const { loading, error, data } = useQuery(ME);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  var userSettings = data.me;

  console.log(userSettings);

  return (
    <>
      <div className="px-4">
        <div className="bg-white">
          {/* SETTINGS MENU */}
          <ButtonRow/>
          {/* HEADER */}
          <div className="flex flex-wrap items-center p-4">
            <div className="w-full lg:w-6/12">
              <h3 className="font-semibold tracking-wide uppercase">My Account</h3>
            </div>
          </div>
          {/* FORM OR TABLE */}
          <div className="pb-4 px-4">
          <form>
              <div className="flex flex-wrap justify-between mb-4">
                <div className="w-full lg:w-6/12 pr-4">
                  <label
                    className="block uppercase text-gray-700 text-xs font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={userSettings.name}
                  />
                </div>
                <div className="w-full lg:w-6/12 pr-4">
                  <label
                    className="block uppercase text-gray-700 text-xs font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={userSettings.email}
                  />
                </div>
              </div>
              <div className="pt-4 pr-4">
                <label
                  className="block uppercase text-gray-700 text-xs font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  Role
                </label>
                <input
                  type="text"
                  className="bg-gray-300 border border-gray-500 px-3 py-3 placeholder-gray-400 text-gray-600 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  defaultValue={userSettings.role}
                  disabled
                />
              </div>
              <div className="mt-6">
                <button
                  className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default CardSettings;
