import { gql } from '@apollo/client';

export const GET_ALL_PRODUCTS = gql `
query getAllProducts(
    $accountid: String, 
    $page: Int, 
    $per_page: Int, 
    $search: String, 
    $sortby: String, 
    $sort_direction: String){
  getAllProducts(accountid:$accountid, page:$page, per_page:$per_page, search:$search, sortBy: $sortby, sortDirection: $sort_direction) {
    count
    rows {
      id
      name
      date
      sku
      price
    }
  }
}

`