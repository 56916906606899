import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_MAIL } from "graphql/mails/mail.js";
import Moment from "react-moment";

const MailDetail = (props) => {
  const getId = props.match.params.id;
    const [inputList, setInputList] = useState([
        { fieldName: "", fieldLabel: "" },
      ]);

  const { loading, error, data } = useQuery(GET_MAIL, {
    variables: {
      id: getId,
    },
  });

  if (loading)
    return (
      <div className="px-4">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Mail...</span>
        </div>
      </div>
    );
  if (error)
    return <div className="border-t text-center py-3">No Data Found :(</div>;
  console.log("name", data.mail.body);

  //adding custom fields
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { fieldName: "", fieldLabel: "" }]);
  };

  const mailCustomFields = inputList.map((customField, i) => (
    <div key={i} className="w-full lg:w-12/12 px-4 flex flex-wrap">
      <div className="relative w-6/12 mt-3 mb-3 pr-4">
        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Field Name
          </label>
          <input
            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            name="fieldName"
            //   ref={register()}
            defaultValue={data.mail.body.name}
            onChange={(e) => handleInputChange(e, i)}
          />
        </div>
      </div>
      <div className="relative w-6/12 mt-3 mb-3">
        <div className="relative w-full mb-3 pl-4">
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Mail Label
          </label>
          <input
            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            name="fieldLabel"
            //   ref={register()}
            value={customField.label}
            onChange={(e) => handleInputChange(e, i)}
          />
        </div>
      </div>
      <div className="btn-box">
        {inputList.length !== 1 && (
          <button
            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            onClick={() => handleRemoveClick(i)}
          >
            Remove
          </button>
        )}
        {inputList.length - 1 === i && (
          <button
            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            onClick={handleAddClick}
          >
            Add
          </button>
        )}
      </div>
    </div>
  ));

  return (
    <>
      {mailCustomFields}
      {/* <div className="relative flex flex-col break-words w-3/4 mb-6 shadow-lg rounded bg-white">
        {/* TABLE */}
      {/* <div className="rounded-t mb-0 px-2 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className={"font-semibold text-lg "}>Points History</h3>
            </div>
          </div>
        </div>
        <div className="rounded-t mb-0 px-2 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className={"font-semibold text-lg "}>
                {data.customer.firstname}&nbsp;{data.customer.lastname}
              </h3>
              <h4 className={"font-semibold text-lg text-gray-500"}>
                {data.customer.points_balance}&nbsp;points
              </h4>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                  }
                >
                  Date
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                  }
                >
                  Points
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                  }
                >
                  Expiry
                </th>
              </tr>
            </thead>
            <tbody>{customerData}</tbody>
          </table>
        </div>
      </div> */}
    </>
  );
};

export default MailDetail;
