import { gql } from "@apollo/client";

export const UPDATE_MAIL_SETTINGS = gql`

mutation updateEmailSettings($account_id: String, $host: String, $port: Int, $emailuser: String, $emailpass: String){
    updateEmailSettings(
        account_id: $account_id,
        host: $host,
        port: $port,
        emailuser: $emailuser,
        emailpass: $emailpass
        )
    }
`;
