import React, { useEffect, useState } from "react";
import ChartContent from "./ChartContent";

const ChartMain = (props) => {
    const [ dateRange, setDateRange ] = useState("This Year");
    const [ measures, setMeasures ] = useState(`${props.measures}.count`);
    const [ filter, setFilter ] = useState(`${props.measures}.createdat`);
    const [ member, setMember ] = useState(`${props.measures}.accountId`);
    const [ measureName, setMeasureName ] = useState("Count");
    const [ dropDownShow, setDropDownShow ] = useState(false);
    const [ timeFilter, setTimeFilter ] = useState(false);
    const [ filterName, setFilterName ] = useState("Year");

    const changeMeasure = (measure) => {
        console.log(measures);
        setMeasures(`${props.measures}.${measure}`);
        setFilter(`${props.measures}.createdat`);
        setMember(`${props.measures}.accountId`);
    }
    
    const Options = () => {
        switch(props.measures) {
            case "Leads" : 
                return <>
                    <li onClick={ () => { setDropDownShow(false); setMeasureName("Count"); changeMeasure("count")} } className="cursor-pointer hover:bg-gray-100 p-2 text-sm">Count</li>
                </>;
            case "Deals" : 
                return <>
                    <li onClick={ () => { setDropDownShow(false); setMeasureName("Count"); changeMeasure("count")} } className="cursor-pointer hover:bg-gray-100 p-2 text-sm">Count</li>
                    <li onClick={ () => { setDropDownShow(false); setMeasureName("Amount"); changeMeasure("amount")} } className="cursor-pointer hover:bg-gray-100 p-2 text-sm">Amount</li>
                </>;
            case "Customers" : 
                return <>
                    <li onClick={ () => { setDropDownShow(false); setMeasureName("Count"); changeMeasure("count")} } className="cursor-pointer hover:bg-gray-100 p-2 text-sm">Count</li>
                    <li onClick={ () => { setDropDownShow(false); setMeasureName("Points Balance"); changeMeasure("pointsBalance")} } className="cursor-pointer hover:bg-gray-100 p-2 text-sm">Points Balance</li>
                </>;
            case "Orders" : 
                return <>
                    <li onClick={ () => { setDropDownShow(false); setMeasureName("Count"); changeMeasure("count")} } className="cursor-pointer hover:bg-gray-100 p-2 text-sm">Count</li>
                    <li onClick={ () => { setDropDownShow(false); setMeasureName("Total"); changeMeasure("total")} } className="cursor-pointer hover:bg-gray-100 p-2 text-sm">Total</li>
                </>;
            default: 
                return <></>;
        }
    }

    const query = [
        {
            measures : [
              measures  
            ],
            "timeDimensions" : [
                {
                    "dimension": filter,
                    "granularity": "month",
                    "dateRange": dateRange
                }
            ],
            "order" : {
                [filter] : "asc",
            },
            "filters" : [
                {
                    "member": member,
                    "operator": "equals",
                    "values" : [
                        props.clientId
                    ]
                }
            ]
        }
    ];

    return (
        <>
        <div className="bg-white h-full">
            {/* card header */}
            <div className="flex items-center py-2 px-4">
                <div className="w-1/2">
                    <h1 className="text-xl font-semibold">{props.measures}</h1>
                </div>
                <div className="w-1/2 text-right relative">
                <div className="inline-block relative text-left z-50">
                    <button onClick={ () => { dropDownShow ? setDropDownShow(false) : setDropDownShow(true); setTimeFilter(false);  } } className="border-0 text-sm text-gray-700 focus:outline-none px-3 cursor-pointer">
                        {measureName}
                        <i className={ `${dropDownShow ? 'rotate-180' : '' } fas fa-chevron-down text-sm ease-linear transition-all duration-150 ml-2` }></i>
                    </button>
                    {
                        dropDownShow ? (
                            <ul className="absolute right-0 bg-white shadow" style={{width: "150px", top: "30px"}}>
                                <Options/>
                            </ul>
                        ) : (<></>)
                    }
                </div>
                <div className="inline-block relative text-left z-50 border-l ml-1">
                    <button onClick={ () => { timeFilter ? setTimeFilter(false) : setTimeFilter(true); setDropDownShow(false) } } className="border-0 text-sm text-gray-700 focus:outline-none pl-3 cursor-pointer">
                        {filterName}
                        <i className={ `${timeFilter ? 'rotate-180' : '' } fas fa-chevron-down text-sm ease-linear transition-all duration-150 ml-2` }></i>
                    </button>
                    {
                        timeFilter ? (
                            <ul className="absolute right-0 bg-white shadow" style={{width: "150px", top: "30px"}}>
                                <li className="cursor-pointer hover:bg-gray-100 p-2 text-sm" onClick={ () => { setDateRange("This Year"); setFilterName("This Year"); setTimeFilter(false) } }>This Year</li>
                                <li className="cursor-pointer hover:bg-gray-100 p-2 text-sm" onClick={ () => { setDateRange("This Month"); setFilterName("This Month"); setTimeFilter(false) } }>This Month</li>
                            </ul>
                        ) : (<></>)
                    }
                </div>
                </div>
            </div>
            {/* card content */}
            <div className="px-4 pb-4">
                <ChartContent accountId={props.clientId} measures={query[0]} dataKey={measures} />
            </div>
        </div>
        </>
    )
}

export default ChartMain;