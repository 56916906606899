import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { GET_ALL_LOGS } from "graphql/logs/logs";
import { useQuery } from "@apollo/client";
import { ClientContext } from "../../contexts/ClientContext.js";
import Moment from "react-moment";

const LogsTable = () => {
  // state
  const [activePage, setActivePage] = useState(1);
  // LEADS FIRST LOAD
  const [state] = useContext(ClientContext);

  const currentDate = new Date();
  // variables
  var page = activePage;

  // Query for logs
  const per_page = 20;
  const { loading, error, data, fetchMore } = useQuery(GET_ALL_LOGS, {
    variables: {
      account_id: state.clientId,
      stage: "",
      page: page,
      per_page: per_page,
      search: "",
      sortby: "createdAt",
      sort_direction: "desc",
    }
  });

  if (loading)
    return (
      <div className="px-4 mb-3">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Logs...</span>
        </div>
      </div>
    );
  if (error)
    return (
      console.log(error),
      (<div className="border-t text-center py-3">No Log Found :(</div>)
    );

  //Logs

  console.log("logs data:", data.getAllLogs)


  // Pagination
  const count = (loading) ? 0 : (( error )) ? 0 : data.getAllLogs.length;
  const pages = Math.ceil(count / 10);
  console.log("Log pages:", pages);
  
  const pageNumbers = [];
  for(let i = 1; i <= pages; i++) {
      pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers
  .slice(activePage - 1, activePage + 9)
  .map((number) => {
    return (
      <button
        className={
          activePage === number
            ? "bg-blue-500 text-white inline-block align-middle text-xs text-center w-8 h-8 bg-white mr-2 mb-2 hover:text-blue-400 hover:text-white focus:outline-none" :
            "inline-block align-middle text-xs text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none border"} 
        key={number}
        onClick={() => {
          setActivePage(number);
          page = number;
          fetchMore({
            variables: { page: number },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              fetchMoreResult.getAllLogs = [
                ...fetchMoreResult.getAllLogs,
              ];
              return fetchMoreResult;
            },
          });
        }}
      >
        {number}
      </button>
    );
  });

 
  return (
    <div className="overflow-x-scroll">
      <div className="flex flex-wrap flex-col border mb-2 overflow-x-scroll">
        <table>
          <tbody>
          { data.getAllLogs.map((log,i) => 
        <tr key={i} className="w-full border-b hover:bg-gray-100 text-left text-sm">
          <td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-no-wrap py-3 text-sm">
            <Moment format="YYYY-MM-DD HH:mm" className="text-gray-600 px-4">{log.createdAt}</Moment>
            {log.user_name} 
            <span className="font-semibold"> • {log.action} </span>
            {log.model}
            <Link to={"/admin/"+ log.model + "/" + log.object_id} className="font-semibold text-blue-600"> {log.name}</Link>
            </td>
        </tr>
          )}
          </tbody> 
          </table>
        </div>
        {/* PAGINATION */}
        <div className="pt-2 text-right">
            {/* PREV Button */}
            { (activePage > 1) ? (
                <button className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                onClick={
                    () => {
                        const prevPage = (activePage > 1) ? activePage - 1 : 1;
                        setActivePage(prevPage);
                        page = prevPage;
                        fetchMore({
                            variables: { page: prevPage },
                            updateQuery: (prevResult, { fetchMoreResult }) => {
                                fetchMoreResult.getAllLogs = [
                                    ...fetchMoreResult.getAllLogs
                                ];
                                return fetchMoreResult;
                            }
                        })
                    }
                } >
                    <i className="fas fa-chevron-left"></i>
                </button>
            ) : (
                <button 
                disabled 
                className="text-gray-500 align-middle inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                >
                    <i className="fas fa-chevron-left"></i>
                </button>
            ) }
            {/* render numbers */}
            {renderPageNumbers}
            {/* NEXT Button */}
            { (activePage < pages) ? (
                    <button className="inline-block align-middle text-base text-center w-8 h-8 bg-white mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                    onClick={
                        () => {
                            // const nextPageNum = activePage + 1;
                            const nextPageNum = (activePage < pages) ? activePage + 1 : pages;
                            setActivePage(nextPageNum);
                            page = nextPageNum;
                            fetchMore({
                                variables: { page: nextPageNum },
                                updateQuery: (prevResult, { fetchMoreResult }) => {
                                    fetchMoreResult.getAllLogs = [
                                        ...fetchMoreResult.getAllLogs
                                    ];
                                    return fetchMoreResult;
                                }
                            })
                        }
                    } >
                        <i className="fas fa-chevron-right"></i>
                    </button>
                    ) : (
                        <button 
                        disabled 
                        className="text-gray-500 inline-block align-middle text-base text-center w-8 h-8 bg-white mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                        >
                            <i className="fas fa-chevron-right"></i>
                        </button>
                    )
                }
      </div>
    </div>
     

  )
}
export default LogsTable;
