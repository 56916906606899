import React, { useEffect,useState,useContext } from "react";
import { useQuery,useMutation } from "@apollo/client";
import { GETS3SIGNED_URL } from "graphql/media/gets3signed-url";
import {useForm} from "react-hook-form";
import { ClientContext } from "contexts/ClientContext";
import nanoid from 'nanoid';
// axios
import axios from "axios";
// toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ADD_MEDIA } from "graphql/media/add-media";
import { UPDATE_PAGE } from "graphql/pages/update-pages";

const PageMediaUpload = (props) => {
    let newId = nanoid(6);
    const [s3url, setS3url] = useState('');
    const [ newFolderId ] = useState(newId);
    const [ fileSizeError, setFileSizeError ] = useState("");
    const [ uploadingProgress, setUploadingProgress ] = useState();
    const [ selectedFile, setSelectedFile ] = useState();

    const [ state ] = useContext(ClientContext);
    const [ getS3SignedURLMutation ] = useMutation(GETS3SIGNED_URL, {
        onCompleted: (res) => {
            setS3url(res)
            console.log('s3 signed url', s3url);
        },
        onError: (error) =>{
            toast.error(error, {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        }
    })
    // mutation for adding to database
    const [ mediaDBStoreMutation ] = useMutation(ADD_MEDIA, {
        onCompleted: () => {
            toast.success("uploaded to DB successfully!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              });
        },
        onError: (error) => {
            console.log(error);
            toast.error("NOT SAVED TO DATABASE", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        }
    })

    // mutation for update page
    const [ updatedPageMutation ] = useMutation(UPDATE_PAGE, {
        onCompleted: () => {
            toast.success("Image Updated!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        },
        onError: (error) => {
            console.log(error, "error");
        },
    })

    const { register, handleSubmit, errors } = useForm() 

    const onChange = (e) => {
        const fileSize = (e.target.files[0] != null) ? e.target.files[0].size : "";
        setSelectedFile(e.target.files[0]);
        if(e.target.files[0] != null && fileSize > 2000000) {
            setFileSizeError("Please select file size less than 2 MB");
        } else {
            setFileSizeError("");
        };
        if(e.target.files[0] != null) {
            getS3SignedURLMutation({
                variables: {
                    account_id: state.clientId,
                    filename: e.target.files[0].name,
                    content_type: e.target.files[0].type,
                    folder_id: newFolderId
                }
            });
        }
    };

    const onSubmit = (data) => {
        axios.put(
        s3url.getS3signedurl, 
        data.file[0], 
        {
            headers: {
            'Content-Type': data.file[0].type
            },
            onUploadProgress(progressEvent) {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setUploadingProgress(progress);
            }
        }).then(response => {
            console.log("Success", response);
            setUploadingProgress("Completed");
            toast.success("File uploaded successfully! ", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        }).catch(error => {
            console.log("Error", error);
            toast.error(error, {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        });
        // save media to database
        mediaDBStoreMutation({
            variables: {
                account_id: state.clientId,
                filename: data.file[0].name,
                type: data.file[0].type,
                folder_id: newFolderId
            }
        });
        // save mutation for update stage
        setTimeout(() => {
            updatedPageMutation({
                variables: {
                    id: props.pageId,
                    account_id: props.accountId,
                    image: "https://d2w3j2ad8yyl6t.cloudfront.net/"+ state.clientId + '/' + newFolderId + '/' + data.file[0].name,
                }
            });
            props.toggle();
        }, 2000 );
    }

    return (
        <>
            <div className="mt-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap items-start">
                        {/* new design for uploading */}
                        <div className={`${selectedFile ? "mx-0" : "mx-auto" } w-1/2 border-2 border-dashed bg-gray-200 py-6 px-4 text-center rounded-lg relative cursor-pointer hover:shadow-md ease-linear transition-all duration-150`}>
                            <i className="fas fa-cloud-upload-alt text-4xl text-gray-700"></i>
                            <div className="py-2 px-4">
                                <h1 className="text-lg tracking-wide text-gray-700">Drop your file here or browse</h1>
                                <span className="text-sm tracking-wider text-gray-600">Max. File Size: 2MB</span>
                            </div>
                            <input ref={register({ required: true })} onChange={onChange} type="file" name="file" 
                            className="absolute top-0 left-0 border px-2 py-2 w-full h-full opacity-0 cursor-pointer" />
                        </div>
                        {/* new design for file information */}
                        <div className={`${selectedFile ? "opacity-1 block" : "opacity-0 hidden" } w-1/2 pl-6 ease-linear transition-all duration-150`}>
                            <div className="">
                                {errors.file && (
                                    <span className="block text-red-500 font-semibold pb-2" role="alert">
                                        <i className="fas fa-times-circle"></i> This field is required</span>
                                )}
                                { fileSizeError ? (
                                    <span className="block text-red-500 font-semibold pb-2" role="alert"><i className="fas fa-times-circle"></i> {fileSizeError}</span>
                                ): ( null) }
                            </div>

                            {
                                selectedFile === undefined ? (
                                    // <h1 className="text-lg text-gray-600">Drop or browse file</h1>
                                    null
                                ) : (
                                    <>
                                    <div className="pb-2">
                                        <h1 className="text-lg tracking-wide font-semibold pb-2">{selectedFile.name}</h1>
                                        <span className="text-gray-600 text-sm">{formatBytes(selectedFile.size)}  &#8226; {selectedFile.type}</span>
                                    </div>
                                    {
                                        uploadingProgress && uploadingProgress != "Completed" ? (
                                            <div className={`${uploadingProgress && uploadingProgress != "Completed" ? "opacity-1": "opacity-0" } pb-2`}>
                                                <h3 className="text-sm font-semibold text-gray-500">Uploading... {uploadingProgress}%</h3>
                                                <div className="py-1 bg-blue-500 mt-2" style={{ width: uploadingProgress + "%"}}></div>
                                            </div>
                                        ) : (uploadingProgress === "Completed") ? ( 
                                            <div className={`${uploadingProgress && uploadingProgress != "Completed" ? "opacity-0": "opacity-1" } pb-2`}>
                                                <h3 className="text-base font-semibold text-green-500"><i className="fas fa-check-circle"></i> Completed</h3>
                                            </div>
                                        ) : (
                                            null
                                        )
                                    }
                                    <div className="pt-1">
                                        <button 
                                            className={`${fileSizeError ? "bg-blue-300 " : "bg-blue-500 hover:shadow-md"} text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none focus:outline-none mr-1 ease-linear transition-all duration-150`}
                                            type="submit"
                                            disabled={ fileSizeError ? true : false }
                                            > <i className="fas fa-file-upload"></i> Upload
                                        </button>
                                    </div>
                                    </>
                                )
                            }
                        </div>
                        {/* end of file information */}
                    </div>
                </form>
            </div>
        </>
    )
}

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default PageMediaUpload;