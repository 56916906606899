import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      id
      name
      email
      role
      membership {
        id
        access
        account {
          id
          name
        }
      }
    }
  }
`;

export const Account = gql`
  query Account($id: String!) {
    account(id: $id) {
      id
      name
      membership {
        id
        access
        user {
          id
          name
          email
          role
        }
      }
      email_settings {
        host
        port
        emailuser
        emailpass
      }
      earn_settings {
        sales
        sales_type
        referral
        referral_type
        review
        review_type
        signup
        signup_type
      }
    }
  }
`;

export const User = gql`
  query user($id: String, $email: String) {
    user(id: $id, email: $email) {
      id
      name
      email
      role
      membership {
        access
        account {
          id
          name
        }
      }
    }
  }
`;
