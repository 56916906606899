import React , {useContext, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
// datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// graphql
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_LEADS } from "graphql/leads/leads";
import { ADD_DEAL } from "graphql/deals/add-deal.js";
import { ClientContext } from "contexts/ClientContext";

const DealForm = (props) => {
    // CONTEXT
    const [ state] = useContext(ClientContext);
    // STATE
    const [ isDate, setDate ] = useState("");
    const [ isFormId, setFormId ] = useState();
    // REDIRECT TO SPECIFIC PAGE
    let history = useHistory();
    // GET ALL LEADS
    const { loading, error, data } = useQuery(GET_ALL_LEADS, {
        enabled: false,
        variables: {
            account_id: state.clientId,
            form_id: ( isFormId != undefined) ? isFormId : props.initialForm,
            page:1,
            per_page: 1000,
            search: ""
        } 
    })
    // MUTATION
    const [ addDealMutation ] = useMutation(ADD_DEAL, {
        onCompleted: () => {
            toast.success("New Deal Added!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar"
            });
            setTimeout(() => history.push('/admin/deals'), 2000 );
        },
        onError: (error) => {
            toast.error(error, {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
         }
    });

    const { handleSubmit, register, errors } = useForm();
    const onSubmit = (fields) => {
        console.log(isDate);
        addDealMutation({
            variables: {
                account_id: state.clientId,
                lead_id: (!props.leads) ? props.lead.id : fields.lead,
                name: fields.name,
                description: fields.description,
                amount: parseFloat(fields.amount),
                stage: fields.stage,
                date_due: (isDate != "") ? isDate : undefined,
            }
        });
    }


    return (
        <div className="pt-9">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    {/* FORM & LEAD */}
                    <div className="w-full">
                        <div className="flex flex-wrap">
                            {/* SELECT FORM */}
                            <div className="mb-3 w-full lg:w-6/12 pr-4">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Form:</label>
                            { 
                                !props.forms ? (
                                    <div className="block text-gray-700 text-xs mb-2">{props.form.name}</div>
                                ) : (
                                    <select className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    name="form"
                                    ref={ (register) }
                                    value={isFormId}
                                    onChange={ (e) => {setFormId(e.target.value)}  }
                                    >
                                        {
                                            props.form.map((form,i) =>
                                                <option key={i} value={form.id}>{form.name}</option>
                                            )
                                        }
                                    </select>
                                )
                            }
                            </div>
                            <div className="mb-3 w-full lg:w-6/12">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Select Lead</label>
                            {
                                !props.leads ? (
                                    <div className="block text-gray-700 text-xs mb-2">{props.lead.firstname}  {props.lead.lastname}</div>
                                ) : (
                                    (loading) ? <p>Loading</p> : error ? (<p>Error :( Try Again</p>) : (
                                            <select 
                                            name="lead"
                                            register={(register)}
                                            className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                                {
                                                    data.getAllLeads.rows.map((lead,i) => 
                                                        <option key={i} value={ lead.id }>{lead.firstname} {lead.lastname}</option>
                                                    )
                                                }
                                            </select>
                                    )
                                )
                            }
                            </div>
                        </div>
                    </div>
                    {/* NAME */}
                    <div className="w-full">
                        <div className="mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Name</label>
                            <input type="text" className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                name="name"
                                ref={ (register({
                                    required: {message: "Name is required", value: true}
                                })) }
                            />
                            { errors.name && (<span className="mt-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-500 bg-red-200 uppercase last:mr-0 mr-1">{ errors.name.message }</span>)  }
                        </div>
                    </div>
                    {/* DESCRIPTION */}
                    <div className="w-full">
                        <div className="mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Description</label>
                            <textarea type="text" className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            name="description"
                            ref={ (register ( { 
                                required: {message: "Description is required", value:true},
                                minLength: {
                                    value: 10,
                                    message: "Description must be at least 10 characters long."
                                }
                             })) }
                            ></textarea>
                            {errors.description && ( <span className="mt-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-500 bg-red-200 uppercase last:mr-0 mr-1">{errors.description.message}</span> )}
                        </div>
                    </div>
                    {/* AMOUNT, DUE DATE & STAGE */}
                    <div className="flex flex-wrap justify-between">
                        {/* AMOUNT */}
                        <div className="w-full lg:w-4/12">
                            <div className="relative mb-3 pr-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Amount</label>
                                <input className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                name="amount"
                                ref={(register)}
                                type="text"
                                />
                            </div>
                        </div>
                        {/* DUE DATE */}
                        <div className="w-full lg:w-4/12">
                            <div className="relative mb-3 px-2">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Due Date For Proposal</label>
                                <DatePicker 
                                    dateFormat="yyyy-MM-dd h:mm aa" 
                                    name="due_date"
                                    selected={isDate}
                                    showTimeSelect 
                                    onChange = { date => { setDate(date) } }
                                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                />
                            </div>
                        </div>
                        {/* STAGE */}
                        <div className="w-full lg:w-4/12">
                            <div className="relative mb-3 pl-2">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Due Date For Proposal</label>
                                <select 
                                name="stage"
                                ref={ (register ( { required: { message: "Deal Stage is required", value: true } } )) }
                                className="border w-full rounded px-3 py-3 mr-3">
                                    <option>Prospect</option>
                                    <option>Qualified</option>
                                    <option>Proposal</option>
                                    <option>On Hold</option>
                                    <option>Won</option>
                                    <option>Lost</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* CTA */}
                    <div className="mt-2">
                        <button type="submit" className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">Submit Form</button>
                    </div>
                    {/* TOAST notification */}
                    {
                        props.leads ? (
                            <ToastContainer />
                        ) : (
                            null
                        )
                    }
                </div> {/* END OF MAIN DIV */}
            </form> {/* END OF FORM */}
        </div>
    )
}

export default DealForm;