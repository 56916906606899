import React, { useContext } from "react";
import { ClientContext } from "../../../contexts/ClientContext.js";
import { Account } from "graphql/user/login";
import { UPDATE_MAIL_SETTINGS } from "graphql/account/update-mail-settings";
import { useQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ButtonRow from "components/Settings/ButtonRow.js";

// components
const CardMailSettings = () => {
  const [state] = useContext(ClientContext);

  const [mailMutation] = useMutation(UPDATE_MAIL_SETTINGS, {
    onCompleted: () => {
      refetch();
      toast.success("Mail Settings Updated!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    },
    onError: (error) => {
      console.log(error, "error");
      toast.error("Update Failed. Please try again!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    },
  });

  const onSubmit = (fields) => {
    console.log(fields, "testing");
    mailMutation({
      variables: {
        account_id: state.clientId,
        host: fields.host,
        port: parseInt(fields.port),
        emailuser: fields.emailuser,
        emailpass: fields.emailpass,
      },
    });
  };

  const { handleSubmit, register } = useForm();

  const { loading, error, data, refetch } = useQuery(Account, {
    variables: {
      id: state.clientId,
      fetchPolicy: "network-first",
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>No Data Found :(</p>;

  const mailSettingsData = data.account;

  return (
    <>
      <div className="px-4">
        <div className="bg-white">
          {/* SETTINGS MENU */}
          <ButtonRow/>
          {/* HEADER */}
          <div className="flex flex-wrap items-center p-4">
            <div className="w-full lg:w-6/12">
              <h3 className="font-semibold tracking-wide">MAIL</h3>
            </div>
          </div>
          {/* FORM OR TABLE */}
          <div className="pb-4 px-4">
          <form onSubmit={handleSubmit(onSubmit)}>
              <div className="w-full">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Host
                </label>
                <input
                  name="host"
                  ref={register()}
                  type="text"
                  className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  defaultValue={
                    mailSettingsData.email_settings === null
                      ? ""
                      : mailSettingsData.email_settings.host
                  }
                />
              </div>
              <div className="w-full my-4">
                <div className="w-full">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Port
                  </label>
                  <input
                    name="port"
                    ref={register()}
                    type="number"
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={
                      mailSettingsData.email_settings === null
                        ? ""
                        : mailSettingsData.email_settings.port
                    }
                  />
                </div>
              </div>
              <div className="w-full my-4">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email Address
                </label>
                <input
                  name="emailuser"
                  ref={register()}
                  type="email"
                  className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  defaultValue={
                    mailSettingsData.email_settings === null
                      ? ""
                      : mailSettingsData.email_settings.emailuser
                  }
                />
              </div>
              <div className="w-full my-4">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  name="emailpass"
                  ref={register()}
                  type="password"
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  defaultValue={
                    mailSettingsData.email_settings === null
                      ? ""
                      : mailSettingsData.email_settings.emailpass
                  }
                />
              </div>
              <div className="mt-6">
                <button
                  className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Submit
                </button>
              </div>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardMailSettings;
