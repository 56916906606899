import { gql } from "@apollo/client";

export const UPDATE_CONTENT_TAG = gql`
  mutation updateContentTag(
    $id: String!
    $account_id: String
    $name: String!
    $slug: String
  ) {
    updateContentTag(id: $id, account_id: $account_id, name: $name, slug: $slug)
  }
`;
