import React, { useContext } from "react";
import { GET_ALL_REWARDS } from "graphql/rewards/rewards.js";
import { useQuery } from "@apollo/client";
import { ClientContext } from "../../contexts/ClientContext.js";

const RewardTable = (props) => {
  // CUSTOMERS FIRST LOAD
  const [state] = useContext(ClientContext);
  // variables
  var page = 1;
  var totalPage = "";
  const per_page = 20;
  // Query
  const { loading, error, data, fetchMore } = useQuery(GET_ALL_REWARDS, {
    variables: {
      accountid: props.accountId,
      page: page,
      per_page: per_page,
      search: "",
    },
  });

  if (loading)
    return (
      <div className="px-4 mb-3">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Rewards...</span>
        </div>
      </div>
    );
  if (error)
    return <div className="border-t text-center py-3">No Data Found :(</div>;

  const pageCalculated = Math.ceil(data.getAllRewards.count / per_page);
  totalPage = Math.round(pageCalculated);
  console.log("Count:", data.getAllRewards.count);

  const rewards = data.getAllRewards.rows.map((reward, i) => (
    <tr key={i} key={i} className="border-t hover:bg-gray-100">
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        {reward.name}
      </td>
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        {reward.points_required}
      </td>
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        <button className="font-semibold text-blue-600 pr-4">
          Update
        </button>
        &nbsp;<button className="font-semibold text-red-500 px-4 border-l">Delete</button>
      </td>
    </tr>
  ));
  // pagination
  var pages = [];
  for (var i = 1; i < totalPage; i++) {
    pages.push(i);
  }

  const pageList = pages.map((num, i) => (
    <button
      className={
        "bg-blue-500 text-white inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:text-blue-400 hover:text-white focus:outline-none"
      }
      key={i}
      onClick={() => {
        page = num;
        fetchMore({
          variables: { page: num },
          updateQuery: (prevResult, { fetchMoreResult }) => {
            fetchMoreResult.getAllRewards.rows = [
              ...fetchMoreResult.getAllRewards.rows,
            ];
            return fetchMoreResult;
          },
        });
      }}
    >
      {num}
    </button>
  ));

  return (
    <div>
      {/* TABLE */}
      <div>
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Name
              </th>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Points
              </th>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >Action</th>
            </tr>
          </thead>
          <tbody>{rewards}</tbody>
        </table>
      </div>
      <div className="py-2 border-t text-right">{pageList}</div>
    </div>
  );
};
export default RewardTable;
