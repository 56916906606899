import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_LEAD } from "graphql/leads/lead.js";
import { UPDATE_LEAD_STATUS } from "graphql/leads/update-lead-status.js";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DealAddFormModal from "components/Deals/DealAddFormModal.js"

const LeadInformation = (props) => {
    // state for modal
    const [ showModal, setShowModal ] = useState(false);
    //  mutation
    const [ statusMutation ] = useMutation(UPDATE_LEAD_STATUS, {
        onCompleted: () => 
        {
            refetch();
            toast.success("Status Updated!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        },
        onError: (error) => {
            console.log("Error: ",error);
            toast.error("Try Again", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        }
    })

    const { loading, error, data, refetch } = useQuery(GET_LEAD, {
        variables: {
            id: props.leadId,
        }
    });

    if(loading) return <div className="px-4">
                            <div className="inline-block bg-white shadow-md py-2 rounded-full">
                                <span class="block px-4">Fetching Lead...</span>
                            </div>
                        </div>;
    if(error) return <div className="border-t text-center py-3">No Data Found :(</div>;
    
    const leadData = data.lead;
    console.log(leadData);
    let keys;
    if( leadData.body != null  ) { keys = Object.keys(leadData.body)};

    // change status
    function updateStatus(val) {
        statusMutation({
            variables: {
                id: props.leadId,
                status: val
            }
        })
    }
    return (
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded bg-white py-3">
            <div className="rounded-t mb-0 px-4 border-0">
                <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-2xl ">
                        {leadData.firstname} {leadData.lastname}
                    </h3>
                </div>
            </div>
            <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Email: </span> <span className="text-black">{ leadData.email }</span>
            </div>
            <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Phone: </span> <span className="text-black">{ leadData.phone }</span>
            </div>
            <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Created: </span> <span className="text-black"><Moment format="YYYY-MM-DD HH:mm">{ leadData.createdAt }</Moment></span>
            </div>
            <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Updated: </span> <span className="text-black"><Moment format="YYYY-MM-DD HH:mm">{ leadData.updatedAt }</Moment></span>
            </div>
            <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Lead Status: </span> 
                <select className="border w-full p-3 mt-1" 
                    value={leadData.status}
                    onChange={
                        (e) => {
                            updateStatus(e.target.value);
                        }
                    }
                >
                    <option value="New" class="bg-transparent">New</option>
                    <option value="Contacted">Contacted</option>
                    <option value="Qualified">Qualified</option>
                    <option value="Disqualified">Disqualified</option>
                </select>
            </div>
            {/* <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Assigned Account Rep: </span> 
                <select class="border w-full p-3 mt-1">
                    <option>Unassigned</option>
                    <option>
                        Account Rep Name
                    </option>
                </select>
            </div> */}
            {/* Deal */}
            <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Deal: </span> 
                {leadData.deal.length > 0 &&
                    <Link to={"/admin/deals/"+ leadData.deal[0].id} className="text-blue-600">{leadData.deal[0].name}</Link>
                }
            </div>
            <div className="px-4 py-3">
                <button onClick={ () => setShowModal(true) } className="inline-block px-3 py-3 rounded mb-2 bg-blue-500 text-white text-center uppercase font-bold bg-gray-200 text-xs focus:outline-none hover:shadow-md ease-linear transition-all duration-150 mr-1">
                    <i class="fas fa-hand-holding-usd"></i> Create Deal
                </button>
                <button className="inline-block px-3 py-3 rounded mb-2 bg-green-500 text-white text-center uppercase font-bold bg-gray-200 text-xs focus:outline-none  hover:shadow-md ease-linear transition-all duration-150">
                    <i class="fas fa-users"></i> Create Customer
                </button>
            </div>
            {/* Modal */}
            {
                showModal ? (
                    <DealAddFormModal toggle={setShowModal} form={leadData.form} lead={leadData}/>
                ) : (
                    null
                )
            }
        </div>

    )
}

export default LeadInformation;