import React from "react";
import DealAddForm from "components/Deals/DealAddForm";

const DealCreate = (props) => {
    return (
        <div>
            <DealAddForm/>
        </div>
    )
}

export default DealCreate;