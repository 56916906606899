import { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, useQuery, useApolloClient,  useLazyQuery } from "@apollo/client";

import { get, useForm } from "react-hook-form";
import { LOGIN, ME } from "../../graphql/user/login.js";
import { AuthContext } from "../../contexts/AuthContext.js";
import Cookies from "universal-cookie";

const cookies = new Cookies();


export default function Login() {
  const client = useApolloClient();
  const history = useHistory();
  const [state, dispatch] = useContext(AuthContext);
  const [getUser, { data: userData }] = useLazyQuery(ME);

  // reaact hook for use mutation
  const [
    loginMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(LOGIN, {
    onCompleted: (data) => {
      client.resetStore();
      console.log("login ", data);
      dispatch({ type: "LOGIN", payload: data.login });
      getUser();
    },
    onError: (error) => {
      console.log("error", error);
      if (error) {
        toast.error("Wrong Email/Password!", {
          position: "bottom-right",
          className: "black-background",
          bodyClassName: "grow-font-size",
          progressClassName: "fancy-progress-bar",
        });
      }
    },
  });

  useEffect(() => {
    let token = cookies.get('token');
    if (token) {
      console.log("Token exists: ", token);
      history.push("/admin/dashboard");
    }
  }, []);

  // Check for change in user data and run effect to set user and redirect to dashboard
  useEffect(() => {
    console.log('login effect', userData);
    if (userData) {
      dispatch({ type: "SET_USER", payload: userData })
      history.push("/admin/dashboard");
    }
  }, [userData]);

  const { handleSubmit, register, watch, errors } = useForm();

  // react-hook-form submission
  const onSubmit = (fields) => {
    console.log(fields);
    cookies.remove("token");
    loginMutation({
      variables: {
        email: fields.email,
        password: fields.password,
      },
    });
  };

  // if (login) return <Redirect to="/admin/dashboard" />;

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 text-sm font-bold">
                    <img
                      className="w-1/2 mx-auto"
                      src="https://marketcloud.co/img/main-logo/MarketCloud%20Logo_RedesignFinal.png"
                    />
                  </h6>
                </div>

                <hr className="mt-6 border-b-1 border-gray-400" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                {/* <div className="text-gray-500 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      aria-invalid={errors.email ? "true" : "false"}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      ref={register()}
                    />
                    {errors.email && (
                      <span role="alert">This field is required</span>
                    )}
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      aria-invalid={errors.password ? "true" : "false"}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      ref={register({ required: true })}
                    />
                    {errors.password && (
                      <span role="alert">This field is required</span>
                    )}
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Sign In
                    </button>
                    <ToastContainer />
                  </div>

                  {/* <div className="btn-wrapper text-center m-4">
                    <button
                      className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                      type="button"
                    >
                      <img
                        alt="..."
                        className="w-5 mr-1"
                        src={require("assets/img/github.svg")}
                      />
                      Github
                    </button>
                    <button
                      className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                      type="button"
                    >
                      <img
                        alt="..."
                        className="w-5 mr-1"
                        src={require("assets/img/google.svg")}
                      />
                      Google
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-gray-300"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-gray-300">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
