import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
// form
import { useForm } from "react-hook-form";
// graphql
import { useMutation } from "@apollo/client";
import { ADD_CUSTOMER } from "graphql/customers/add-customer.js";
import { ClientContext } from "contexts/ClientContext";
// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomerAddNew = () => {
  // context get account id
  const [state] = useContext(ClientContext);
  // state for error
  const [showError, setError] = useState("");
  // REDIRECT TO SPECIFIC PAGE
  let history = useHistory();
  // mutation
  const [addCustomerMutation] = useMutation(ADD_CUSTOMER, {
    onCompleted: () => {
      toast.success("New Customer Added!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
      setTimeout(() => history.push("/admin/customers"), 2000);
    },
    onError: (error) => {
      console.log(error.message, "Error");
      setError(error.message);
    },
  });

  // form
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (fields) => {
    // console.log("FIELDS:", fields);
    addCustomerMutation({
      variables: {
        account_id: state.clientId,
        firstname: fields.firstname,
        lastname: fields.lastname,
        email: fields.email,
        number: fields.number,
        points_balance: 0,
        date: Date.now(),
      },
    });
  };

  return (
    <>
      <div className="relative block w-full">
        <div className="px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
            {/* Header */}
            <div className="rounded-t mb-0 px-2 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3 className={"font-semibold text-lg "}>Add New Customer</h3>
                </div>
                <div>
                  <Link
                    to="/admin/customers"
                    className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  >
                    <i className="fas fa-chevron-left"></i>
                    <span className="inline-block ml-1">Customers</span>
                  </Link>
                </div>
              </div>
            </div>
            {/* END OF HEADER */}
            <div className="py-6 px-6">
              {/* FORM */}
              <div className="pt-9">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {showError ? (
                    <span className="mb-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-500 bg-red-200 uppercase last:mr-0 mr-1">
                      {showError}
                    </span>
                  ) : null}
                  {/* First & Last Name */}
                  <div className="w-full">
                    <div className="flex flex-wrap">
                      <div className="mb-3 w-full lg:w-6/12 pr-4">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          First Name
                        </label>
                        <input
                          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          name="firstname"
                          ref={register({
                            required: {
                              message: "First Name is required",
                              value: true,
                            },
                          })}
                        />
                        {errors.firstname && (
                          <span className="mt-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-500 bg-red-200 uppercase last:mr-0 mr-1">
                            {errors.firstname.message}
                          </span>
                        )}
                      </div>
                      <div className="mb-3 w-full lg:w-6/12">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          Last Name
                        </label>
                        <input
                          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          name="lastname"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Email & Number */}
                  <div className="w-full">
                    <div className="flex flex-wrap">
                      <div className="mb-3 w-full lg:w-6/12 pr-4">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          Email
                        </label>
                        <input
                          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          name="email"
                          type="email"
                          ref={register({
                            required: {
                              message: "Email is required",
                              value: true,
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="mt-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-500 bg-red-200 uppercase last:mr-0 mr-1">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                      <div className="mb-3 w-full lg:w-6/12">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          Number
                        </label>
                        <input
                          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          name="number"
                          ref={register}
                        />
                      </div>
                    </div>
                  </div>
                  {/* CTA */}
                  <div className="mt-2">
                    <button
                      type="submit"
                      className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    >
                      Submit
                    </button>
                  </div>
                  {/* TOAST */}
                  <ToastContainer />
                </form>
              </div>
              {/* END OF FORM */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerAddNew;
