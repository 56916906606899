import React from "react";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER } from "graphql/customers/customer.js";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const CustomerDetail = (props) => {
  const getId = props.match.params.id;
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: {
      id: getId,
    },
    pollInterval: 100,
  });

  if (loading)
    return (
      <div className="px-4">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Customer...</span>
        </div>
      </div>
    );
  if (error)
    return <div className="border-t text-center py-3">No Data Found :(</div>;
      
  const customerPointsData = data.customer.point.map((point, i) => (
    <tr key={i}>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
        <Link to={ "/admin/customers/"+props.match.params.id+"/point/"+point.id } className={ (point.status === false) ? "text-red-500 hover:text-blue-600" : "text-black hover:text-blue-600" }>
          <Moment format="YYYY-MM-DD HH:mm">
            {point.transaction_date}
          </Moment>
        </Link>
      </td>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
        <Link to={ "/admin/customers/"+props.match.params.id+"/point/"+point.id } className={ (point.status === false) ? "text-red-500 hover:text-blue-600" : "text-black hover:text-blue-600" }>
        {point.transaction_type === 'redeem' ? '-' : '+'} {point.amount}</Link>
      </td>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
        <Link to={ "/admin/customers/"+props.match.params.id+"/point/"+point.id } className={ (point.status === false) ? "text-red-500 hover:text-blue-600" : "text-black hover:text-blue-600" }>{point.transaction_type}</Link>
      </td>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
        <Link to={ "/admin/customers/"+props.match.params.id+"/point/"+point.id } className={ (point.status === false) ? "text-red-500 hover:text-blue-600" : "text-black hover:text-blue-600" }>
          <Moment format="YYYY-MM-DD HH:mm">
              {point.expiry}
          </Moment>
        </Link>
      </td>
      <td className={(point.status === false) ? "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-red-500" : 'border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4'}>
        { (point.status === false) ? "Transaction Void" : "" }
      </td>
    </tr>
  ));
  const customerOrderData = data.customer.order.map((order, i) => (
    <tr key={i}>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
        <Link to={"/admin/orders/"+ order.id } className="hover:text-blue-600">{order.order_number}</Link>
      </td>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
        {order.total}
      </td>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
        <Moment format="YYYY-MM-DD HH:mm">{order.date}</Moment>
      </td>
    </tr>
  ));
  console.log(data.customer);

  return (
    <>
      <div className="relative block w-full">
        <div className="flex flex-wrap">
          <div className="w-full xl:w-3/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white py-3">
              <div className="rounded-t mb-0 px-4 border-0">
                <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-xl ">
                      {data.customer.firstname} {data.customer.lastname}
                    </h3>
                    <p className="py-1 text-sm">{data.customer.email}</p>
                    <p className="py-1 text-sm">{data.customer.number}</p>
                    <p className="py-1 text-sm">{data.customer.points_balance}&nbsp;points</p>
                    <p className="py-1 text-sm">Referral Code: <strong>{data.customer.referral_code}</strong></p>
                    <div className="py-3">
                      <Link to={"/admin/customers/"+ data.customer.id + "/point"} className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                        <i className="fas fa-hand-holding-usd mr-2 text-sm text-white"></i>
                        Create Points
                      </Link>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-3/4 mb-12 xl:mb-0 px-4">
            {/* ORDER HISTORY */}
            <div className="bg-white rounded pt-3 hover:shadow-md">
              <h3 className="font-semibold text-lg px-4 py-3">Order History</h3>
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                      }
                    >
                      Order
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                      }
                    >
                      Total
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                      }
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>{customerOrderData}</tbody>
              </table>
            </div>
            {/* POINTS HISTORY */}
            <div className="bg-white rounded pt-3 mt-4 hover:shadow-md">
              <h3 className="font-semibold text-lg px-4 py-3">Points History</h3>
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                      }
                    >
                      Date
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                      }
                    >
                      Points
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                      }
                    >
                      Type
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                      }
                    >
                      Expiry
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-600 border-gray-200"
                      }
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>{customerPointsData}</tbody>
              </table>
            </div> {/* END OF POINTS HISTORY */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetail;
