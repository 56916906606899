import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

const OrdersTable = (props) => {
    useEffect(() =>{
        props.refetch();
    });
    return (
        <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                    <tr>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>ORDER NUMBER</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>CUSTOMER</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>EMAIL</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>TOTAL</th>
                        <th className={ "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"}>DATE</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.map((order,i) => 
                            <tr key={i} className="border-t hover:bg-gray-100">
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    <Link to={"/admin/orders/"+ order.id} className="font-semibold text-blue-600">{order.order_number}</Link>
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    <Link to={"/admin/customers/"+ order.customer.id} className="font-semibold text-blue-600">{order.customer.firstname} {order.customer.lastname}</Link>
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    {order.customer.email}
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    ${order.total}
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
                                    <Moment format="YYYY-MM-DD HH:mm">{order.date}</Moment>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default OrdersTable;