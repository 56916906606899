import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Rewards from "views/admin/Rewards.js";
import Tables from "views/admin/Tables.js";
// Pages
import Pages from "views/admin/Pages/Index.js";
import PageDetail from "views/admin/Pages/PageDetail.js";
import PageAddNew from "views/admin/Pages/PageAddNew.js";
import PageBannerOrder from "views/admin/Pages/PageBannerOrder";
import PageCouponOrder from "views/admin/Pages/PageCouponOrder";
//Content Types
import ContentTypes from "views/admin/ContentTypes/Index.js";
import ContentTypeCreate from "views/admin/ContentTypes/ContentTypeCreate.js";
import ContentTypeUpdate from "views/admin/ContentTypes/ContentTypeUpdate.js";
//Content Tags
import ContentTags from "views/admin/ContentTags/Index.js";
import ContentTagUpdate from "views/admin/ContentTags/ContentTagUpdate.js";
import ContentTagCreate from "views/admin/ContentTags/ContentTagCreate.js";
// Customers
import Customers from "views/admin/Customers/Index.js";
import CustomerDetail from "views/admin/Customers/CustomerDetail.js";
import CustomerAddPoint from "views/admin/Customers/CustomerAddPoint.js";
import CustomerUpdatePoint from "views/admin/Customers/CustomerUpdatePoint";
import CustomerAddNew from "views/admin/Customers/CustomerAddNew.js";
import CustomersImport from "views/admin/Customers/CustomersImport";
// Leads
import Leads from "views/admin/Leads/Index.js";
import LeadDetail from "views/admin/Leads/LeadDetail.js";
import LeadCreate from "views/admin/Leads/LeadCreate";
// Deals
import Deals from "views/admin/Deals/Index.js";
import DealDetail from "views/admin/Deals/DealDetail.js";
import DealCreate from "views/admin/Deals/DealCreate.js";
import DealStageBoard from "views/admin/Deals/DealStageBoard.js";
import DealCalendar from "views/admin/Deals/DealCalendar";
// Media
import Media from "views/admin/Media/Index.js";
import MediaDetail from "views/admin/Media/MediaDetail.js";
import MediaCreate from "views/admin/Media/MediaCreate.js";
//Forms
import Forms from "views/admin/Forms/Index.js";
import FormAddNew from "views/admin/Forms/FormAddNew.js";
import FormDetail from "views/admin/Forms/FormDetail.js";
//Email Jobs
import EmailJobs from "views/admin/EmailJobs/Index.js";
import EmailJobDetail from "views/admin/EmailJobs/EmailJobDetail.js";
//Logs
import Logs from "views/admin/Logs/Index.js";
//Mails
import Mails from "views/admin/Mails/Index.js";
import MailAddNew from "views/admin/Mails/MailAddNew.js";
import MailDetail from "views/admin/Mails/MailDetail.js";
// Products
import Products from "views/admin/Products/Index";
import ProductDetail from "views/admin/Products/ProductDetail";
// Orders
import Orders from "views/admin/Orders/Index";
import OrderDetail from "views/admin/Orders/OrderDetail";
//Settings
import SettingsAccount from "views/admin/Settings/SettingsAccount.js";
import SettingsAddUser from "views/admin/Settings/SettingsAddUser.js";
import SettingsApi from "views/admin/Settings/SettingsApi.js";
import SettingsMail from "views/admin/Settings/SettingsMail.js";
import SettingsRewards from "views/admin/Settings/SettingsReward.js";
import SettingsUsers from "views/admin/Settings/SettingsUser.js";
import SettingsApiDetail from "views/admin/Settings/SettingsApiDetail.js"

import Cookies from 'universal-cookie';
const cookies = new Cookies();


export default function Admin() {
  
  const history = useHistory();
  
  let token = cookies.get('token');
  if (!token) {
    console.log("Token missing, redirecting to login");
    history.push("/auth/login");
  }

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-56 bg-gray-200">
        <AdminNavbar />
        {/* Header */}
        <div className="px-4 md:px-6 mx-auto relative pb-32 pt-12 md:pt-32 lg:pt-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            {/* Pages */}
            <Route path="/admin/pages" exact component={Pages} />
            <Route path="/admin/pages/new" exact component={PageAddNew} />
            <Route path="/admin/pages/banner" exact component={PageBannerOrder} />
            <Route path="/admin/pages/coupons" exact component={PageCouponOrder} />
            <Route path="/admin/pages/:id" exact component={PageDetail} />
            {/* Content Types */}
            <Route path="/admin/contenttypes" exact component={ContentTypes} />
            <Route
              path="/admin/contenttypes/:account_id/new"
              exact
              component={ContentTypeCreate}
            />
            <Route
              path="/admin/contenttypes/:id"
              exact
              component={ContentTypeUpdate}
            />

            {/* Content Tags */}
            <Route path="/admin/contenttags" exact component={ContentTags} />
            <Route
              path="/admin/contenttags/:account_id/new"
              exact
              component={ContentTagCreate}
            />
            <Route
              path="/admin/contenttags/:id"
              exact
              component={ContentTagUpdate}
            />

            {/* Customers */}
            <Route path="/admin/customers" exact component={Customers} />
            <Route
              path="/admin/customers/new"
              exact
              component={CustomerAddNew}
            />
            <Route
              path="/admin/customers/import"
              exact
              component={CustomersImport}
            />
            <Route
              path="/admin/customers/:id"
              exact
              component={CustomerDetail}
            />
            <Route
              path="/admin/customers/:id/point"
              exact
              component={CustomerAddPoint}
            />
            <Route
              path="/admin/customers/:id/point/:point_id"
              exact
              component={CustomerUpdatePoint}
            />
            {/* Leads */}
            <Route path="/admin/leads" exact component={Leads} />
            <Route path="/admin/leads/:id" exact component={LeadDetail} />
            <Route
              path="/admin/leads/:account_id/new"
              exact
              component={LeadCreate}
            />
            {/* Deals */}
            <Route path="/admin/deals" exact component={Deals} />
            <Route path="/admin/deals/board" exact component={DealStageBoard} />
            <Route path="/admin/deals/new" exact component={DealCreate} vvv/>
            <Route path="/admin/deals/calendar" exact component={DealCalendar} vvv/>
            <Route path="/admin/deals/:id" exact component={DealDetail} vvv/>
            {/* Media */}
            <Route path="/admin/media" exact component={Media} />
            <Route path="/admin/media/new" exact component={MediaCreate} vvv/>
            <Route path="/admin/media/:id" exact component={MediaDetail} vvv/>
            {/* Forms */}
            <Route path="/admin/forms" exact component={Forms} />
            <Route path="/admin/forms/new" exact component={FormAddNew} />
            <Route path="/admin/forms/:id" exact component={FormDetail} vvv />
            {/* Email Jobs */}
            <Route path="/admin/emailjobs" exact component={EmailJobs} />
            <Route
              path="/admin/emailjobs/:id"
              exact
              component={EmailJobDetail}
            />
            {/* Logs */}
            <Route path="/admin/logs" exact component={Logs} />
            {/* Mails */}
            <Route path="/admin/mails" exact component={Mails} />
            <Route path="/admin/mails/new" exact component={MailAddNew} />
            <Route path="/admin/mails/:id" exact component={MailDetail} vvv />
            {/* Products */}
            <Route path="/admin/products" exact component={Products} vvv />
            <Route
              path="/admin/products/:id"
              exact
              component={ProductDetail}
              vvv
            />
            {/* Orders */}
            <Route path="/admin/orders" exact component={Orders} vvv />
            <Route path="/admin/orders/:id" exact component={OrderDetail} vvv />
            {/* Settings */}
            <Route
              path="/admin/settings/account"
              exact
              component={SettingsAccount}
            />
            <Route
              path="/admin/settings/adduser"
              exact
              component={SettingsAddUser}
            />
            <Route path="/admin/settings/api" exact component={SettingsApi} />
            <Route path="/admin/settings/api/:id" exact component={SettingsApiDetail} />
            <Route path="/admin/settings/mail" exact component={SettingsMail} />
            <Route
              path="/admin/settings/rewards"
              exact
              component={SettingsRewards}
            />
            <Route
              path="/admin/settings/users"
              exact
              component={SettingsUsers}
            />
            {/* Dynamic */}
            <Route path="/admin/rewards" exact component={Rewards} />
            <Route path="/admin/tables" exact component={Tables} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
        </div>
      </div>
    </>
  );
}
