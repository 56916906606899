import { gql } from "@apollo/client";

export const ADD_CONTENT_TYPE = gql`
  mutation createContentType(
    $account_id: String!
    $name: String!
    $schema: JSON
  ) {
    createContentType(account_id: $account_id, name: $name, schema: $schema)
  }
`;
