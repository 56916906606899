import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ClientContext } from "contexts/ClientContext";
import { getAllPages } from "graphql/pages/pages";
import { useQuery } from "@apollo/client";
import PageOrderTable from "components/Pages/PageOrderTable";
import { resetServerContext } from 'react-beautiful-dnd';

const PageBannerOrder = ()  => {
    // client details
    const [ client ] = useContext(ClientContext);
    // verify contenttype
    let contenttypeId = "";
    if(client.clientId === "16ade2c4-becb-4c96-841f-aa27bb904980"){
        contenttypeId = "ba52ddb2-35c1-4eae-bc02-a520ab1527fe";
    }
    else if(client.clientId === "10197bfb-a876-4c93-8ce0-463b835fa670"){
        contenttypeId = "718e7393-53e5-4c97-b2ba-5576a2e94ffc";
    }

    // query
    const { loading, error, data, refetch } = useQuery(getAllPages, {
        variables: {
            account_id: client.clientId,
            page: 1,
            per_page: 1000,
            status: true,
            search: "",
            contenttype_id: contenttypeId,
            sortby: "order",
            sort_direction: "ASC",
        },
    });

    useEffect(() => {
        if(data) {
            // console.log("coupon data ->", data);
            refetch();
        }
    });

    resetServerContext();  

    return (
        <>
            <div className="px-4">
                <div className="bg-white px-6">
                    {/* HEADER */}
                    <div className="flex flex-wrap items-center py-3">
                        <div className="w-full lg:w-6/12">
                            <h3 className="tracking-wide font-bold inline-block">Coupons</h3>
                        </div>
                        <div className="w-6/12 rounded-t bg-white mb-0 text-right">
                            <Link to={"/admin/pages"} className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                                <i className="fas fa-chevron-left"></i>
                                <span className="inline-block ml-1">Pages</span>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* banners */}
                <div className="border-t bg-white px-6 py-3">
                    {
                        loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p>Error</p>
                        ) : (

                            <PageOrderTable 
                            pages={data.getAllPages.rows} 
                            clientId={client.clientId}
                            {...{refetch}}/>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default PageBannerOrder;