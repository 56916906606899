import { gql } from "@apollo/client";

export const GET_ALL_CONTENT_TAGS = gql`
  query getAllContentTags(
    $account_id: String
    $page: Int
    $per_page: Int
    $search: String
  ) {
    getAllContentTags(
      account_id: $account_id
      page: $page
      per_page: $per_page
      search: $search
    ) {
      count
      rows {
        id
        account_id
        name
        slug
        contenttag_page {
          id
          page {
            id
            name
          }
          contenttag {
            id
            name
            slug
          }
        }
      }
    }
  }
`;
