import React from "react";
import { useQuery,useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import {useForm} from "react-hook-form";
import { useHistory } from "react-router-dom";
// graphql
import { FORMS } from "graphql/forms/forms";
import { ADD_LEAD } from "graphql/leads/add-lead.js";
// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const LeadCreate = (props) => {
    let history = useHistory();
    const accountId = props.match.params.account_id;
    // mutation
    const [ addLeadMutation ] = useMutation(ADD_LEAD, {
        onCompleted: () => {
            toast.success("New Lead added!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              });

            setTimeout(() => history.push('/admin/leads'), 3000 );
        },
        onError: (error) =>{
            toast.error(error, {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        }
    })

    // form
    const { handleSubmit, register, errors } = useForm();
    const onSubmit = (fields) => {
        console.log(fields);
        addLeadMutation({
            variables: {
                account_id: accountId,
                form_id: fields.form,
                firstname: fields.firstname,
                lastname: fields.lastname,
                email: fields.email,
                company: "",
                phone: fields.phone,
                body: null
            }
        })
    }
    // get all forms
    const {loading, error, data} = useQuery(FORMS, {
        variables: {
            account_id: accountId,
            page: 1,
            per_page: 1000,
            search:""
        }
    });

    if(loading) return <p>Loading...</p>;
    if(error) return <p>No Data Found :(</p>;
    const forms = data.getAllForms.rows;
    const formsOptions = forms.map((form,i) =>
        <option key={i} value={form.id}>{form.name}</option>
    )
    return (
        <div className="relative block w-full">
            <div className="px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
                    {/* Header */}
                    <div className="rounded-t mb-0 px-2 py-3 border-0">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3 className={ "font-semibold text-lg "}>
                                    Add New Lead
                                </h3>
                            </div>  
                            <div>
                                <Link to="/admin/leads" className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                                    <i className="fas fa-chevron-left"></i>
                                    <span className="inline-block ml-1">Leads</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div  className="px-6 py-3">
                            {/* SELECT LEAD */}
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Select Form
                                </label>
                                <select 
                                name="form"
                                ref={(register)}
                                className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                    {formsOptions}
                                </select>
                            </div>
                            {/* FIRST NAME & LAST NAME */}
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 pr-0 pr-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            First Name
                                        </label>
                                        <input
                                            name="firstname"
                                            ref={(register({
                                                required: {message: "First Name is required",value:true},
                                            }))}
                                            type="text"
                                            className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        />
                                        {errors.firstname && (
                                            <span className="mt-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-500 bg-red-200 uppercase last:mr-0 mr-1">
                                                {errors.firstname.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 pr-0">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            name="lastname"
                                            ref={(register)}
                                            type="text"
                                            className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* EMAIL & PHONE */}
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-6/12 pr-0 pr-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Email Address
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            ref={(register({
                                                required: {message: "Email Address is required",value:true},
                                            }))}
                                            className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        />
                                        {errors.email && (
                                            <span className="mt-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-500 bg-red-200 uppercase last:mr-0 mr-1">
                                                {errors.email.message}
                                            </span>
                                        )}
                                        
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 pr-0">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Phone
                                        </label>
                                        <input
                                            name="phone"
                                            ref={(register)}
                                            type="text"
                                            className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* NAME */}
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Name
                                </label>
                                <input
                                    name="name"
                                    ref={(register)}
                                    type="text"
                                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                />
                            </div>
                        </div>
                        {/* SUBMIT BTN */}
                        <div className="pt-0 pb-6 px-6">
                            <button type="submit" className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">Submit Form</button>
                        </div>
                        {/* ALERT */}
                        <ToastContainer />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LeadCreate;