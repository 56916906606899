import { gql } from "@apollo/client";

export const UPDATE_REWARD_SETTINGS = gql`
  mutation updateEarn(
    $account_id: String!
    $sales: Float
    $sales_type: Conversion
    $referral: Float
    $referral_type: Conversion
    $review: Int
    $review_type: Conversion
    $signup: Int
    $signup_type: Conversion
  ) {
    updateEarn(
      account_id: $account_id
      sales: $sales
      sales_type: $sales_type
      referral: $referral
      referral_type: $referral_type
      review: $review
      review_type: $review_type
      signup: $signup
      signup_type: $signup_type
    )
  }
`;
