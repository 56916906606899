import { gql } from '@apollo/client';

export const GET_ALL_MEDIA = gql`
    query getAllMedia(
            $account_id: String, 
            $page: Int, 
            $per_page: Int, 
            $search: String,
            $sortby: String,
            $sort_direction: String
        )
        {
        getAllMedia(
            account_id:$account_id, 
            page:$page, 
            per_page:$per_page, 
            search:$search,
            sortby:$sortby,
            sort_direction: $sort_direction
        ) 
            {
                id
                account_id
                filename
                folder_id
                type
                createdAt
            }
        }
`;