import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// components
import PageUpdateForm from "components/Pages/PageUpdateForm.js";
// gql
import { useQuery } from "@apollo/client";
import { GET_PAGE } from "graphql/pages/page";
import { ClientContext } from "contexts/ClientContext";

const PageDetail = (props) => {
  const [ state ] = useContext(ClientContext);
  const getId = props.match.params.id;
  const account_id = state.clientId;

  const { loading, error, data, refetch } = useQuery(GET_PAGE, {
    variables: {
      id: getId,
      account_id: account_id,
    },
  });
  if(loading) 
    return (
      <div className="px-4">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Form...</span>
        </div>
      </div>
    );
  if(error)
    return <div className="border-t text-center py-3">No Data Found :(</div>;
      console.log(data);
  return (
    <>
      <div className="bg-white">
        <div className="flex flex-wrap items-center py-3">
          {/* Heading */}
          <div className="w-full lg:w-6/12 px-6">
              {
                loading ? (
                  <h3 className="tracking-wide font-bold">Fetching...</h3>
                ) : (error) ? <h3 className="tracking-wide font-bold">Try Again</h3>
                : (
                <h3 className="tracking-wide font-bold">{ data.page.name }</h3>
                )
              }
          </div>
          <div className="w-full lg:w-6/12 text-right px-6">
              <Link to="/admin/pages" className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                <i className="fas fa-chevron-left"></i>
                <span> Back</span>
              </Link>
            </div>
        </div>
        {/* FORM */}
        <div className="px-6 py-4">
          <PageUpdateForm  {...{refetch}} page={data.page} account_id={account_id}/>
        </div>
        {/* END OF FORM */}
      </div>
    </>
  )
}

export default PageDetail;