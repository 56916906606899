import { gql } from "@apollo/client";

export const GET_CONTENT_TAG = gql`
  query contenttag($id: String!) {
    contenttag(id: $id) {
      id
      account_id
      name
      slug
      contenttag_page {
        id
      }
    }
  }
`;
