import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ClientContext } from "../../../contexts/ClientContext.js";
import ContentTagsTable from "components/ContentTags/ContentTagsTable.js";

export default function ContentTypes() {
  // GET ACCOUNT ID
  const [state] = useContext(ClientContext);

  const [findSearch, setFindSearch] = useState("");

  return (
    <>
      <div className="px-4">
        <div className="bg-white px-6">
          {/* HEADER */}
          <div className="flex flex-wrap items-center py-3">
          <div className="w-full lg:w-6/12">
            {/* SEARCH */}
            <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
              <div className="relative flex w-full flex-wrap items-stretch">
                <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-2">
                  <i className="fas fa-search"></i>
                </span>
                <input
                  type="text"
                  placeholder="Search Content Tags"
                  className="px-2 py-2 placeholder-gray-400 text-gray-700 relative bg-white bg-white border text-sm outline-none focus:outline-none focus:shadow-outline w-full pl-10"
                  onChange={(e) => {
                    setFindSearch(e.target.value);
                  }}
                />
              </div>
            </form>
          </div>
              <div className="w-full lg:w-6/12 lg:text-right">
                  <Link to={"/admin/contenttags/" + state.clientId + "/new"} className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                      <i className="fas fa-plus-circle"></i>
                      <span className="inline-block ml-1">New Content Tag</span>
                  </Link>
              </div>
          </div>
          {/* TABLE */}
          <div className="mt-2">
            <ContentTagsTable accountId={state.clientId} search={findSearch} />
          </div>
        </div>
      </div>
    </>
  );
}
