import { gql } from "@apollo/client";

export const GET_ORDER = gql`
query Order($id: String!){
    order(id: $id) {
        id
        total
        date
        order_number
        order_product {
            id
            sku
            row_total
            product {
                id
                name
            }
            quantity
        }
        customer {
            id
            firstname
            lastname
            email
        }
    }
}

`