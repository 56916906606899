import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { ClientContext } from "../../contexts/ClientContext.js";
import Moment from "react-moment";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { ToastContainer, toast } from "react-toastify";

import { getAllPages } from "graphql/pages/pages.js";
import { UPDATE_STATUS } from "graphql/pages/update-page-status.js";

const PagesTable = (props) => {
  // let pageList;
  const [activePage, setActivePage] = useState(1);

  // CUSTOMERS FIRST LOAD
  const [state] = useContext(ClientContext);
  // variables
  var page = activePage;
  var totalPage = "";
  const per_page = 20;
  let newStatus = (props.status === "null") ? null : (props.status === '')

  // Query
  const { loading, error, data, fetchMore, refetch } = useQuery(getAllPages, {
    variables: {
      account_id: props.accountId,
      page: page,
      per_page: per_page,
      search: props.search,
      status: props.status,
      sortby: "",
      sort_direction: "desc",
      contenttag_id: props.contenttagId,
      contenttype_id: props.contenttypeId
    },
  });


  // useEffect to Refetch new page
  useEffect(()=>{ 
    refetch();
  },[refetch])

  const [updateStatusMutation] = useMutation(UPDATE_STATUS, {
    onCompleted: () => {
      refetch();
      toast.success("Status Updated!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  if (loading)
    return (
      <div className="px-4 mb-3">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Pages..</span>
        </div>
      </div>
    );
  if (error)
    return <div className="border-t text-center py-3">No Data Found :(</div>;

  const pageCalculated = Math.ceil(data.getAllPages.count / per_page);
  totalPage = Math.round(pageCalculated);
  console.log("Count:", data.getAllPages.count);

  let currentId = "";
  function onChange(value, currentId) {
    console.log(value, currentId);
    let status = value;
    updateStatusMutation({
      variables: {
        id: currentId,
        status: status,
      },
    });
  }

  const listpages = data.getAllPages.rows.map((page, i) => (
    <tr key={i} className="border-t hover:bg-gray-100">
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        <Link to={"/admin/pages/" + page.id} className="text-blue-600">
          {page.name}
        </Link>{" "}
        <br></br>
        <Link
          to={"/admin/pages/" + page.id}
          className="font-semibold text-blue-600"
        >
          {page.path}
        </Link>
      </td>
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        <Link to={"/admin/pages/" + page.id}>
          {page.contenttype === null ? "" : page.contenttype.name}
        </Link>
      </td>
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        <Link to={"/admin/pages/" + page.id}>
          {page.contenttag_page.map((eachContentTag, i) => (
            <span key={i}>
              {eachContentTag.contenttag.name === null
                ? ""
                : eachContentTag.contenttag.name}
              <br></br>
            </span>
          ))}
          {page.contenttag_page.contenttag === undefined
            ? ""
            : page.contenttag_page.contenttag.name}
        </Link>
      </td>
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        <Switch
          onChange={(value) => {
            currentId = page.id;
            onChange(value, currentId);
          }}
          checked={page.status}
        />
      </td>
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        <Link to={"/admin/pages/" + page.id}>
        <Moment format="YYYY">{page.createdAt === null ? "" : page.createdAt}</Moment>
        </Link>
      </td>
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        <Link to={"/admin/pages/" + page.id}>
          <button className="text-blue-500 text-sm hover:text-blue-400 font-semibold">
            Update
          </button>{" "}
        </Link>
      </td>
    </tr>
  ));

  // pagination
  const pages = Math.ceil(data.getAllPages.count / per_page);
  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers
    .slice(activePage - 1, activePage + 9)
    .map((number) => {
      return (
        <button
          className={
            activePage === number
              ? "bg-blue-500 text-white inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:text-blue-400 hover:text-white focus:outline-none"
              : "inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none border"
          }
          key={number}
          onClick={() => {
            setActivePage(number);
            page = number;
            fetchMore({
              variables: { page: number },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                fetchMoreResult.getAllPages.rows = [
                  ...fetchMoreResult.getAllPages.rows,
                ];
                return fetchMoreResult;
              },
            });
          }}
        >
          {number}
        </button>
      );
    });
  return (
    <div>
      {/* TABLE */}
      <div className="relative flex flex-col min-w-0 break-words w-8/12 bg-white">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100 w-42"
                }
              >
                Name
              </th>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Content Type
              </th>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Content Tag
              </th>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Published
              </th>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Year
              </th>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>{listpages}</tbody>
        </table>
      </div>
      <div className="py-2 border-t text-right">
        {activePage > 1 ? (
          <button
            className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
            onClick={() => {
              const prevPage = activePage > 1 ? activePage - 1 : 1;
              setActivePage(prevPage);
              page = prevPage;
              fetchMore({
                variables: { page: prevPage },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  fetchMoreResult.getAllPages.rows = [
                    ...fetchMoreResult.getAllPages.rows,
                  ];
                  return fetchMoreResult;
                },
              });
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        ) : (
          ""
        )}
        {renderPageNumbers}
        {activePage < pages ? (
          <button
            className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
            onClick={() => {
              // const nextPageNum = activePage + 1;
              const nextPageNum = activePage < pages ? activePage + 1 : pages;
              setActivePage(nextPageNum);
              page = nextPageNum;
              fetchMore({
                variables: { page: nextPageNum },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  fetchMoreResult.getAllPages.rows = [
                    ...fetchMoreResult.getAllPages.rows,
                  ];
                  return fetchMoreResult;
                },
              });
            }}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        ) : (
          ""
        )}
      </div>
      <ToastContainer />
    </div>
  );
};
export default PagesTable;
