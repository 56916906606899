import React, { useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { ClientContext } from "../../../contexts/ClientContext.js";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
// graphql
import { UPDATE_CONTENT_TYPE } from "graphql/content-types/update-content-type.js";
import { GET_CONTENT_TYPE } from "graphql/content-types/content-type.js";
// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContentTypeUpdate = (props) => {
  const [state] = useContext(ClientContext);
  let history = useHistory();
  const getId = props.match.params.id;

  // mutation
  const [updateContentTypeMutation] = useMutation(UPDATE_CONTENT_TYPE, {
    onCompleted: () => {
      toast.success("Content Type Updated!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });

      setTimeout(() => history.push("/admin/contenttypes"), 3000);
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  const { loading, error, data } = useQuery(GET_CONTENT_TYPE, {
    variables: {
      id: getId,
    },
  });

  // form
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (fields) => {
    console.log(fields);
    updateContentTypeMutation({
      variables: {
        id: getId,
        account_id: state.clientId,
        name: fields.name,
        schema: fields.schema,
      },
    });
  };

  if (loading)
    return (
      <div className="px-4 mb-3">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Content Tag...</span>
        </div>
      </div>
    );
  if (error)
    return (
      console.log(error),
      (<div className="border-t text-center py-3">No Data Found :(</div>)
    );

  console.log(data);

  return (
    <div className="relative block w-full">
      <div className="px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
          {/* Header */}
          <div className="rounded-t mb-0 px-2 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className={"font-semibold text-lg "}>Edit Content Type</h3>
              </div>
              <div>
                <Link
                  to="/admin/contenttypes"
                  className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                >
                  <i className="fas fa-chevron-left"></i>
                  <span className="inline-block ml-1">Content Types</span>
                </Link>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-6 py-3">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Name
                </label>
                <input
                  name="name"
                  defaultValue={data.contenttype.name}
                  ref={register({
                    required: {
                      message: "Name is required",
                      value: true,
                    },
                  })}
                  type="text"
                  className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Schema
                </label>
                <table className="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">Name</th>
                    <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">Type</th>
                    {/* <th className="text-left">Action</th> */}
                  </thead>
                  <tbody>
                    {
                      data.contenttype.schema.map((type,i) => (
                        <tr key={i} className="border-t w-full border-b hover:bg-gray-100">
                          <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">{Object.keys(type)}</td>
                          <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">{Object.values(type)}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {/* SUBMIT BTN */}
            <div className="pt-0 pb-6 px-6">
              <button
                type="submit"
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              >
                Update
              </button>
            </div>
            {/* ALERT */}
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContentTypeUpdate;
