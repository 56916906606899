/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import AccountDropdown from "components/Dropdowns/AccountDropdown.js";
import PagesDropdown from "components/Dropdowns/PagesDropdown.js";

export default function Sidebar(props) {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [ pageDropdownShow, setPageDropdownShow ] = useState(false);
  const [ dealsDropdownShow, setDealsDropdownShow ] = useState(false);
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-56 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <div className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0">
            <AccountDropdown />
          </div>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Admin Layout Pages
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center z-10">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>

              <li className="items-center relative">
                <div className="flex items-center">
                    <div className="w-5/6">
                      <Link className={ "text-xs uppercase font-bold block " + (window.location.href.indexOf("/admin/pages") !== -1 ? "text-blue-500 hover:text-blue-600": "text-gray-800 hover:text-gray-600") }
                      to="/admin/pages">
                        <i className={ "fas fa-book-open mr-2 text-base " + (window.location.href.indexOf("/admin/pages") !== -1 ? "opacity-75" : "text-gray-400") }></i>
                        Contents
                      </Link>
                    </div>
                    <div className="w-1/6 text-right">
                      <button className={`${pageDropdownShow ? 'bg-gray-200' : ''} hover:bg-gray-100 px-2 rounded focus:outline-none`} onClick={() => { pageDropdownShow ? setPageDropdownShow(false) : setPageDropdownShow(true) }}>
                        <i className={ `${pageDropdownShow ? 'rotate-180' : '' } fas fa-chevron-down text-sm ease-linear transition-all duration-150` }></i></button>
                    </div>
                </div>
                <ul className={ ` ${pageDropdownShow ? 'h-auto ' : 'h-0 overflow-hidden'} ml-5 block pt-2 overflow-hidden ease-linear transition-all duration-150` }>
                  <li className="items-center py-2 px-2">
                    <Link className={ "text-xs uppercase font-semibold block " + (window.location.href.indexOf("/admin/contenttypes") !== -1 ? "text-blue-500 hover:text-blue-600" : "text-gray-800 hover:text-gray-600") }
                    to="/admin/contenttypes">
                      <i className={ "fas fa-window-restore mr-2 text-base " + (window.location.href.indexOf("/admin/contenttypes") !== -1 ? "opacity-75" : "text-gray-400") }></i>Content Types
                    </Link>
                  </li>
                  <li className="items-center py-2 px-2">
                    <Link className={ "text-xs uppercase font-semibold block " + (window.location.href.indexOf("/admin/contenttags") !== -1 ? "text-blue-500 hover:text-blue-600" : "text-gray-800 hover:text-gray-600") }
                    to="/admin/contenttags">
                      <i className={ "fas fa-window-restore mr-2 text-base " + (window.location.href.indexOf("/admin/contenttags") !== -1 ? "opacity-75" : "text-gray-400") }></i>Content Tags
                    </Link>
                  </li>
                  <li className="items-center py-2 px-2">
                    <Link className={ "text-xs uppercase font-semibold block " + (window.location.href.indexOf("/admin/pages/banner") !== -1 ? "text-blue-500 hover:text-blue-600" : "text-gray-800 hover:text-gray-600") }
                    to="/admin/pages/banner">
                      <i className={ "fas fa-th mr-2 text-base " + (window.location.href.indexOf("/admin/pages/banner") !== -1 ? "opacity-75" : "text-gray-400") }></i>Banners
                    </Link>
                  </li>
                  <li className="items-center py-2 px-2">
                    <Link className={ "text-xs uppercase font-semibold block " + (window.location.href.indexOf("/admin/pages/coupon") !== -1 ? "text-blue-500 hover:text-blue-600" : "text-gray-800 hover:text-gray-600") }
                    to="/admin/pages/coupons">
                      <i className={ "fas fa-percent mr-2 text-base " + (window.location.href.indexOf("/admin/pages/coupon") !== -1 ? "opacity-75" : "text-gray-400") }></i>Coupons
                    </Link>
                  </li>
                </ul>
              </li>
              
              <hr className="mt-2 mb-4 md:min-w-full" />

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/media") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/media"
                >
                  <i
                    className={
                      "fas fa-images mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/media") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Media
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/customers") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/customers"
                >
                  <i
                    className={
                      "fas fa-users mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/customers") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Customers
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/leads") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/leads"
                >
                  <i
                    className={
                      "fas fa-address-card mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/leads") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Leads
                </Link>
              </li>
              <li className="items-center relative">
                <div className="flex flex-wrap items-center">
                  <div className="w-6/12">
                    <Link className={ "text-xs uppercase py-3 font-bold block " + (window.location.href.indexOf("/admin/deals") !== -1 ? "text-blue-500 hover:text-blue-600" : "text-gray-800 hover:text-gray-600")
                      } to="/admin/deals">
                      <i className={ "fas fa-hand-holding-usd mr-2 text-sm " + (window.location.href.indexOf("/admin/deals") !== -1 ? "opacity-75" : "text-gray-400")} ></i>
                      Deals
                    </Link>
                  </div>
                  <div className="w-6/12 text-right">
                      <button className={`${dealsDropdownShow ? 'bg-gray-200' : ''} hover:bg-gray-100 px-2 rounded focus:outline-none`} onClick={() => { dealsDropdownShow ? setDealsDropdownShow(false) : setDealsDropdownShow(true) }}>
                        <i className={ `${dealsDropdownShow ? 'rotate-180' : '' } fas fa-chevron-down text-sm ease-linear transition-all duration-150` }></i>
                      </button>
                  </div>
                  <ul className={ ` ${dealsDropdownShow ? 'h-auto' : 'h-0 overflow-hidden'} ml-5 block overflow-hidden ease-linear transition-all duration-150` }>
                    <li className="items-center pb-2 px-2">
                      <Link className={ "text-xs uppercase font-semibold block " + (window.location.href.indexOf("/admin/deals/board") !== -1 ? "text-blue-500 hover:text-blue-600" : "text-gray-800 hover:text-gray-600") }
                      to="/admin/deals/board">
                        <i className={ "fas fa-columns mr-2 text-base " + (window.location.href.indexOf("/admin/deals/board") !== -1 ? "opacity-75" : "text-gray-400") }></i>Boards
                      </Link>
                    </li>
                    <li className="items-center py-2 px-2">
                      <Link className={ "text-xs uppercase font-semibold block " + (window.location.href.indexOf("/admin/deals/calendar") !== -1 ? "text-blue-500 hover:text-blue-600" : "text-gray-800 hover:text-gray-600") }
                      to="/admin/deals/calendar">
                        <i className={ "fas fa-calendar-alt mr-2 text-base " + (window.location.href.indexOf("/admin/deals/calendar") !== -1 ? "opacity-75" : "text-gray-400") }></i>Calendar
                      </Link>
                    </li>
                  </ul>

                </div>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/products") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/products"
                >
                  <i
                    className={
                      "fas fa-box-open mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/products") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Products
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/orders") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/orders"
                >
                  <i
                    className={
                      "fas fa-shopping-cart mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/orders") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Orders
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/forms") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/forms"
                >
                  <i
                    className={
                      "fas fa-file-alt mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/forms") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Forms
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/mails") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/mails"
                >
                  <i
                    className={
                      "fas fa-envelope-open-text mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/mails") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Mails
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/emailjobs") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/emailjobs"
                >
                  <i
                    className={
                      "fas fa-envelope mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/emailjobs") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Email Jobs
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/logs") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/logs"
                >
                  <i
                    className={
                      "fas fa-window-restore mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/logs") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Logs
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/settings/account") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/settings/account"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/settings/account") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Settings
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/rewards") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/rewards"
                >
                  <i
                    className={
                      "fas fa-trophy mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/rewards") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Rewards
                </Link>
              </li>

              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/tables") !== -1
                      ? "text-blue-500 hover:text-blue-600"
                      : "text-gray-800 hover:text-gray-600")
                  }
                  to="/admin/tables"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/tables") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Tables
                </Link>
              </li> */}
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
          </div>
        </div>
      </nav>
    </>
  );
}
