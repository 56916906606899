import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer
   } from "recharts";
import cubejs from "@cubejs-client/core";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";

const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
    apiUrl: "https://cubejs.marketcloud.co/cubejs-api/v1"
});
   
const dateFormatter = item => moment(item).format("MMM");
const tickDateFormatter = item => moment(item).format("MMMM YYYY");

const ChartContent = (props) => {
    return (
        <div className="mt-4">
        <QueryRenderer
        query={props.measures}
        cubejsApi={cubejsApi}
        render={({ resultSet }) => {
            if (!resultSet) {
            return <p style={{ "paddingLeft": "200px" }}>Loading...</p>
            }

            return (
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={resultSet.chartPivot()}>
                <XAxis dataKey="x" tickFormatter={dateFormatter} />
                <YAxis/>
                <Tooltip labelFormatter={tickDateFormatter}/>
                <Bar dataKey={props.dataKey} fill="#4299e1" />
                </BarChart>
            </ResponsiveContainer>
            );
        }}
        />
        </div>
    )
}

export default ChartContent;