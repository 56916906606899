import { gql } from "@apollo/client";

export const GET_ALL_LOGS = gql`
  query getAlllogs(
    $account_id: String,
    $page: Int,
    $per_page: Int,
    $search: String,
    $sortby: String,
    $sort_direction: String
  ) {
    getAllLogs(
      account_id: $account_id,
      page: $page,
      per_page: $per_page,
      search: $search,
      sortby: $sortby,
      sort_direction: $sort_direction
    ) {
      id
      model
      action
      name
      account_id
      object_id
      user_id
      user_name
      body
      createdAt 
    }
  }
`;
