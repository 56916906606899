import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ClientContext } from "contexts/ClientContext";
import { Link } from "react-router-dom";
// gql
import { useQuery,useMutation } from "@apollo/client";
import { GET_FORM } from "graphql/forms/form";
import { UPDATE_FORM } from "graphql/forms/update-forms.js";
// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// modal
import FormsDeleteModal from "components/Forms/FormsDeleteModal.js";
// switch
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
// history
import { useHistory } from "react-router-dom";

let account_id;
let addName;
let status;
let fromName = "";
let fromEmail = "";
let to = "";
let subject = "";
let mail_id = "";

const FormDetail = (props) => {
  let history = useHistory();
  // state for clientcontext
  const [ state ] = useContext(ClientContext);
  // state for custom field
  const [ indexes, setIndexes ] = useState([]);
  const [ counter, setCounter ] = useState(0);
  const [ showModal, setShowModal ] = useState(false);
  // useForm
  const { register, handleSubmit} = useForm();
  // mutations
  const [updateFormMutation] = useMutation(UPDATE_FORM, {
    onCompleted: () => {
      toast.success("Form Updated!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
      setTimeout(() => history.push('/admin/forms'), 3000 );
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });
  // GET FORM info
  const { loading, error, data } = useQuery(GET_FORM, {
    onCompleted: (data) => {
      console.log("DATA:", data);
      if(data.form.body.length === undefined) {
        setIndexes(Object.entries(data.form.body));
      } else setIndexes(data.form.body);
    },
    variables: {
      id: props.match.params.id
    }
  });

  if(loading) return <p>Loading...</p>;
  if(error) return <p>Error...</p>;
  // on submit
  const onSubmit = fields => {
    addName = fields.name;
    fromName = fields.from_name;
    fromEmail = fields.from_email;
    to = fields.to;
    subject = fields.subject;
    account_id = state.clientId;
    mail_id = fields.mail_id;

    updateFormMutation({
      variables: {
        id: props.match.params.id,
        account_id: account_id ,
        name: addName,
        body: fields.fields,
        from_name: fromName,
        from_email: fromEmail,
        to: to,
        subject: subject,
        status: status,
        mail_id: mail_id,
      },
    });
  }
  // adding field
  const addField = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  }
  // removing field
  const removeField = index => () => {
    setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index) ]);
    setCounter(prevCounter => prevCounter - 1);
  }

  // switch onChange
  function onChange(value) {
    if(value === true) {
      status = 1;
    } else {
      status = 0;
    }
  }

  // notifications data
  let emailNotificationSetting;
  if(data.form.form_settings != null) {
    emailNotificationSetting = data.form.form_settings.notification_email;
    // console.log("NOTIF SETTING NOT NULL" , emailNotificationSetting.from_name === "");
  } else {
    // console.log("NOTIF SETTING NULL" , data.form.form_settings);
    emailNotificationSetting =  "";
  }

  return (
    <>
    <div className="px-4">
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 border-0">
        {/* MODAL */}
        { showModal ? ( <FormsDeleteModal toggle={setShowModal} formName={data.form.name} formId={data.form.id}/> ) : null }
        {/* END OF MODAL */}
        {/* HEADER */}
        <div className="rounded-t mb-0 px-2 py-3 border-0 bg-white">
            <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className={ "font-semibold text-lg "}>
                        Update Form
                    </h3>
                </div>  
                <div>
                    <Link to="/admin/forms" className="inline-block bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                        <i className="fas fa-chevron-left"></i>
                        <span className="inline-block ml-1">Forms</span>
                    </Link>
                    <button onClick={ () => setShowModal(true) } className="ml-2 inline-block bg-red-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                        <i className="fas fa-trash"></i>
                        <span className="inline-block ml-1">Delete Form</span>
                    </button>
                </div>
            </div>
        </div>
        {/* END OF HEADER */}
        <div className="flex-auto px-6 lg:px-4 py-10 pt-0 bg-white">
        {/* FORM */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap justify-center">
              {/* Form Name */}
              <div className="w-full">
                <div className="relative w-full mt-3 mb-3">
                  <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                    Form Name
                  </label>
                  <input name="name" ref={register( { required: true } )} type="text" defaultValue={data.form.name} className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"/>
                </div>
              </div>
              {/* end of form name */}
              {/* custom fields */}
              {
                indexes.map((index,i) => {
                  const fieldName = `fields[${i}]`;
                  return (
                    <fieldset name={fieldName} key={fieldName} className="w-full lg:w-12/12 flex flex-wrap items-center">
                      <div className="relative w-1/2 lg:w-5/12 mt-3 mb-3 pr-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Field Name</label>
                          <input type="text" name={ `${fieldName}.name` } defaultValue={ index.name } ref={register} className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"/>
                        </div>
                      </div>
                      <div className="relative  w-1/2 lg:w-5/12 mt-3 mb-3 pr-4">
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Field Label</label>
                          <input type="text" name={ `${fieldName}.label` } defaultValue={ index.label } ref={register} className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"/>
                        </div>
                      </div>
                      <div className="btn-box relative lg:w-1/6  mt-3 mb-3 pr-4">
                        <button type="button" onClick={removeField(index)} className="bg-red-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                          <i className="fa fa-minus"></i>
                        </button>
                      </div>
                    </fieldset>
                  )
                })
              }
              <div className="text-left w-full ">
                <button type="button" onClick={addField} className="inline-block bg-transparent border border-dotted border-gray-400 text-blue-500 active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" >Add Field</button>
              </div>
              {/* end of custom fields */}
              {/* Status */}
              <div className="w-full lg:w-12/12 mt-4">
                <div className="relative w-full mb-3">
                  <label className="inline-block uppercase text-gray-700 text-xs font-bold mb-2 mr-2"
                  >Status</label>
                  <Switch onChange={onChange} defaultChecked={data.form.status} className="inline-block"/>
                </div> 
              </div>
              {/* end of status */}
              {/* EMAIL NOTIFICATION */}
              <div className="relative w-full mt-3 mb-3 pr-4">
                <div className="w-full border-t mb-3">
                  <h1 className="text-base font-bold py-2 mt-3 text-gray-600 tracking-wide">Email Notification</h1>
                </div>
                <div className="flex flex-wrap">
                  <div className="relative w-full lg:w-6/12 mb-3 pr-4">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">From (NAME)</label>
                    <input className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    name="from_name" type="text" ref={register()} 
                    defaultValue={ (emailNotificationSetting != "" && emailNotificationSetting.from_name != "") ? emailNotificationSetting.from_name : "" }
                    />
                  </div>
                  <div className="relative w-full lg:w-6/12 mb-3 pl-4">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">From (Email)</label>
                    <input className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    name="from_email" type="text" ref={register()}
                    defaultValue={ (emailNotificationSetting != "" && emailNotificationSetting.from_email != "") ? emailNotificationSetting.from_email : "" }
                    />
                  </div>
                </div>
                <div className="relative w-full mb-3 pr-4">
                  <label className="block uppercase text-gray-700 text-xs font-bold mb-2">To</label>
                  <input className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  name="to" type="text" ref={register()}
                  defaultValue={ (emailNotificationSetting != "" && emailNotificationSetting.to != "") ? emailNotificationSetting.to : "" }
                  />
                </div>
                <div className="relative w-full mb-3 pr-4">
                  <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Subject</label>
                  <input className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  name="subject" type="text" ref={register()}
                  defaultValue={ (emailNotificationSetting != "" && emailNotificationSetting.subject != "") ? emailNotificationSetting.subject : "" }
                  />
                </div>
                <div className="relative w-full mb-3 pr-4">
                  <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Mail ID</label>
                  <input className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  name="mail_id" type="text" ref={register()}
                  defaultValue={ (emailNotificationSetting != "" && emailNotificationSetting.mail_id != "") ? emailNotificationSetting.mail_id : "" }
                  />
                </div>
              </div>
            </div>
            {/* SUBMIT CTA */}
            <div className="mt-0">
              <button className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="submit">
                Submit
              </button>
            </div>
            {/* END OF SUBMIT CTA */}
          </form>
        {/* END OF FORM */}
        <ToastContainer />
        </div>
      </div>
    </div>
    </>
  )
}

export default FormDetail;