import { gql } from '@apollo/client';

export const GET_PRODUCT = gql `
query Product($id: String!){
    product(id: $id) {
        id
        sku
        name
        date
        price
        custom_attributes
    }
}
`