import React from "react";
import { useQuery } from "@apollo/client";
import DealComment from "components/Deals/DealComment";
import DealInformation from "components/Deals/DealInformation";
import { ME } from "graphql/user/login.js"
import { GET_MEDIA } from "graphql/media/media.js"
import MediaInformation from "components/Media/MediaInformation";

const MediaDetail = (props) => {
    const getId = props.match.params.id;
    const { loading, error, data } = useQuery(GET_MEDIA, {
        variables: {
            id: getId,
        }
    });

    if(loading) return <p>Loading...</p>;
    if(error) return <p>No Data Found :( ...</p>;
    const media = data.media;
    return (
        <>
            <div className="relative block w-full overflow-x-auto">
                <div className="flex flex-wrap">
                    <div className="w-full xl:w-6/12 px-4">
                        {/* Deal Information */}
                        <img src={ "https://d2w3j2ad8yyl6t.cloudfront.net/"+ media.account_id + '/' + media.folder_id + '/' + media.filename }/>
                    </div>
                    <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
                        {/* Comment Section */}
                        <MediaInformation information={data.media}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MediaDetail