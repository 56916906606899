import React, { useEffect, useRef, useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ClientContext } from "contexts/ClientContext";
import Moment from 'react-moment';
import { GET_DEAL } from "graphql/deals/deal";
import { Account } from "graphql/user/login";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// Mutation
import { UPDATE_DEAL } from "graphql/deals/update-deal";
import { UPDATE_DEAL_STAGE } from "graphql/deals/update-deal-stage";
import { ASSIGN_USER } from "graphql/deals/assign-user";
// datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const DealInformation = (props) => {
    
    // REF
    let nameRef = useRef();
    let amountRef = useRef();
    let descriptionRef = useRef();
    let dateDueRef = useRef();
    // state
    const [ isEditing, setEditing ] = useState();
    let [updateName, setUpdateName] = useState("");
    let [updateAmount, setUpdateAmount] = useState("");
    let [updateDescription, setUpdateDescription] = useState("");
    let [updateDateDue, setUpdateDateDue] = useState(props.dealDueDate);
    let [updateDealStage,setUpdateDealStage] = useState("Won");
    let [dealUser,setAssignUser] = useState("");
    const [ saveBtnShow, setSaveBtnShow ] = useState(false);
    //client state
    const [ state ] = useContext(ClientContext);
    
    // useEffect for autofocus input
    useEffect(() => {
        if (nameRef && nameRef.current && isEditing === "name") return nameRef.current.focus();
        if (amountRef && amountRef.current && isEditing === "amount") return amountRef.current.focus();
        if (descriptionRef && descriptionRef.current && isEditing === "description") return descriptionRef.current.focus();
        if (dateDueRef && dateDueRef.current && isEditing === "date_due") return dateDueRef.current.focus();
    }, [isEditing, nameRef,amountRef,descriptionRef,dateDueRef]);

    // mutation
    const [ dealMutation ] = useMutation(UPDATE_DEAL, {
        onCompleted: () => refetch(),
        onError: (error) => {
            console.log("Error: ",error);
            toast.error("Try Again", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        }
    });

    const [stageMutation] = useMutation(UPDATE_DEAL_STAGE, {
        onCompleted: () => refetch(),
        onError: (error) => {
            console.log("Error: ",error);
            toast.error("Try Again", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        }
    });

    const [userMutation] = useMutation(ASSIGN_USER, {
        onCompleted: () => refetch(),
        onError: (error) => {
            console.log("Error: ",error);
            toast.error("Try Again", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
            });
        }
    });

    // GET DEAL INFORMATION
    const { loading, error, data, refetch } = useQuery(GET_DEAL, {
        variables: {
            id: props.dealId,
            fetchPolicy: "network-first"
        }
    });
    

    // GET ACCOUNT USER
    const per_page = 20;
    const { loading: accountsLoading, error: accountsError, data: accountsData} = useQuery(Account, {
        variables: {
            id: state.clientId,
            fetchPolicy: "no-cache",
        },
        });
    if(loading) return <p>Loading...</p>;
    if(error) return <p>No Data Found :( </p>;
    if(accountsLoading) return <p>Loading...</p>;
    if(accountsError) return <p>Account's Error :( </p>;

    const account = accountsData.account.membership;
    console.log("Account data:", account)

    const dealData = data.deal;
    console.log("deal data:", dealData)

    // save to database the new information
    function newUpdate() {
        if(updateName === "") { updateName = dealData.name };
        if(updateAmount === "") { updateAmount = dealData.amount };
        if(updateDescription === "") { updateDescription = dealData.description };
        if(updateDateDue === "") { updateDateDue = dealData.date_due };
        // deal
        var newDeal = {
            id: dealData.id,
            account_id: dealData.account_id,
            name: updateName,
            description: updateDescription,
            date_due: updateDateDue,
            amount: Number(updateAmount),
        }
        console.log(updateDateDue);

        // update the deal info
        dealMutation({
            variables: newDeal
        });
        toast.success("Deal Updated", {
            position: "bottom-right",
            className: "black-background",
            bodyClassName: "grow-font-size",
            progressClassName: "fancy-progress-bar",
        });
    }
    // for enter or tab or esc
    const handleKeyDown = (e,type) => {
        const { key } = e;
        const keys = ["Escape", "Tab"];
        const enterKey = "Enter";
        const allKeys = [...keys, enterKey];
        if (
        (type === "textarea" && keys.indexOf(key) > -1) ||
        (type !== "textarea" && allKeys.indexOf(key) > -1)
        ) {
            setEditing();
            newUpdate();
        }
    }

    // showing the input
    function showTypingArea(dataFor,refFor, type) {
        return (
             (type === "input") ? (
                <input
                    type="text"
                    name="name"
                    placeholder={dataFor}
                    className="w-full border-b py-2 focus:outline-none text-black"
                    ref={refFor}
                    onBlur={ () => { setEditing(false); newUpdate(); } }
                    onKeyDown={ (e) => handleKeyDown(e, type) }
                    onChange={ (e) => { 
                        if(refFor === nameRef) {
                            setUpdateName(e.target.value);
                        } else if (refFor === amountRef) {
                            setUpdateAmount(e.target.value);
                        } else if(refFor === dateDueRef) {
                            setUpdateDateDue(e.target.value);
                        }
                    } }
                />
            ) : (
                <textarea
                    className="w-full border-b border-blue-400 py-2 text-black focus:outline-none"
                    ref={refFor}
                    placeholder={dataFor}
                    onBlur={ () => { setEditing(false); newUpdate(); } }
                    onKeyDown={ (e) => handleKeyDown(e, type) }
                    onChange={ (e) => { 
                        setUpdateDescription(e.target.value);
                    } }
                ></textarea>
            )
        )
    }
    // updating stage
    function updateStage(e) {
        setUpdateDealStage(e); 
        stageMutation({
            variables: {
                id: props.dealId,
                stage: e
            }
        });
        toast.success("Deal Stage Updated", {
            position: "bottom-right",
            className: "black-background",
            bodyClassName: "grow-font-size",
            progressClassName: "fancy-progress-bar",
        });
    }

    // Assign user to Deal
    function assignUser(e) {
        setAssignUser(e); 
        userMutation({
            variables: {
                id: props.dealId,
                user_id: e
            }
        });
        toast.success("Deal User Assigned", {
            position: "bottom-right",
            className: "black-background",
            bodyClassName: "grow-font-size",
            progressClassName: "fancy-progress-bar",
        });
    }


    return (
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white py-3">
            {/* HEADER NAME */}
            <div className="rounded-t mb-0 px-4 border-0">
                <div className="flex">
                    <div className="relative w-full max-w-full flex-grow flex-1">
                        <h3 className="font-semibold text-xl ">
                            {dealData.name}
                        </h3>
                    </div>
                </div>
            </div>
            {/* Name */}
            <div className="px-4 pt-2 pb-1 text-sm">
                <span className="text-gray-600 tracking-wide">Name: </span>
                { isEditing === "name" ? (
                    showTypingArea(dealData.name, nameRef, "input")
                ) : (
                    <span className="block w-full py-1 cursor-pointer" onClick={ () => { setEditing("name"); } }>{ dealData.name }</span>
                )
                }
            </div>
            {/* Amount */}
            <div className="px-4 py-1 text-sm">
                <span className="text-gray-600 tracking-wide">Amount: </span>
                { isEditing === "amount" ? (
                    showTypingArea(dealData.amount, amountRef, "input")
                ) : (
                    <span className="block w-full py-1 cursor-pointer" onClick={ () => { setEditing("amount"); } }>{ dealData.amount }</span>
                )
                }
            </div>
            {/* Description */}
            <div className="px-4 py-1 text-sm">
                <span className="text-gray-600 tracking-wide">Description: </span>
                { isEditing === "description" ? (
                    showTypingArea(dealData.description, descriptionRef, "textarea")
                ) : (
                    <span className="block w-full py-1 cursor-pointer" onClick={ () => { setEditing("description"); } }>{ dealData.description }</span>
                )
                }
            </div>
            {/* DATE DUE */}
            <div className="px-4 py-1 text-sm">
                <span className="text-gray-600 tracking-wide cursor-pointer">Date Due: </span>
                <div className="flex">
                <DatePicker className={ (saveBtnShow) ? "px-2 border py-1 focus:outline-none" : 'cursor-pointer py-1 focus:outline-none' }
                            dateFormat="yyyy-MM-dd h:mm aa"
                            showTimeSelect
                            selected={ (updateDateDue === undefined) ? Date.parse(dealData.date_due) : Date.parse(updateDateDue)}
                            onChange={date => { setUpdateDateDue( date ); setSaveBtnShow(true); } } 
                            onClick={ () => setSaveBtnShow(true) }
                />
                { saveBtnShow ? (<button className="ml-1 bg-blue-400 text-white px-3 rounded" onClick={ () => { newUpdate(); setSaveBtnShow(false); } }>Save</button>) : (<div></div>)}
                </div>
            </div>
            {/* SELECT STAGE */}
            <div className="px-4 py-1 text-sm">
                <span className="text-gray-600 tracking-wide">Deal Stage:</span>
                <select className="border w-full p-3 mt-1 focus:outline-none" 
                    value={dealData.stage} 
                    onChange={ (e) => { 
                        updateStage(e.target.value);
                    } }>
                    <option value="Prospect" >Prospect</option>
                    <option value="Qualified">Qualified</option>
                    <option value="Proposal">Proposal</option>
                    <option value="On Hold">On Hold</option>
                    <option value="Won">Won</option>
                    <option value="Lost">Lost</option>
                </select>
            </div>
            {/* Assigned Account/User Rep */}
            <div className="px-4 py-1 text-sm">
                <span className="text-gray-600 tracking-wide">Assigned Account Rep:</span>
                {dealData.user &&
                <select className="border w-full p-3 mt-1 focus:outline-none" 
                value={dealData.user.id} onChange={ (e) => { 
                    assignUser(e.target.value);
                } }>
                    {
                        account.map((membership,i) => 
                            <option key={i} value={ membership.user.id }>{membership.user.name}</option>
                        )
                    }
                </select>
                }
                {!dealData.user &&
                <select className="border w-full p-3 mt-1 focus:outline-none"
                 onChange={ (e) => { 
                    assignUser(e.target.value);
                } }>
                    <option value="0">- Unassigned -</option>
                    {
                        account.map((membership,i) => 
                            <option key={i} value={ membership.user.id }>{membership.user.name}</option>
                        )
                    }
                </select>
                }
            </div>
            {/* Lead */}
            <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Lead: </span> <span className="text-black">
                {dealData.lead &&
                    <Link to={"/admin/leads/"+ dealData.lead.id} className="text-blue-600">{dealData.lead.firstname} {dealData.lead.lastname}</Link>
                } 
                </span>
            </div>
            {/* Create Customer */}
            <div className="px-4 py-2 text-sm">
                <a href="#" className="inline-block w-full bg-green-500 text-center rounded uppercase font-bold px-1 py-2 text-white text-xs">
                    <i className="fas fa-users"></i> Create Customer
                </a>
            </div>
            {/* Created */}
            <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Created: </span> <span className="text-black"><Moment format="YYYY-MM-DD HH:mm">{ dealData.createdAt }</Moment></span>
            </div>
            {/* Updated */}
            <div className="px-4 py-2 text-sm">
                <span className="text-gray-600 tracking-wide">Updated: </span> <span className="text-black"><Moment format="YYYY-MM-DD HH:mm">{ dealData.updatedAt }</Moment></span>
            </div>

        </div>
    )
}

export default DealInformation;