import { gql } from "@apollo/client";

export const GET_MEDIA = gql `
   query media($id: String!){
  media(id: $id) {
    id
    account_id
    filename
    type
    folder_id
    createdAt
  }
}
`;