import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ClientContext } from "../../../contexts/ClientContext.js";
import FormsTable from "components/Forms/FormsTable.js";

export default function Forms() {
  // GET ACCOUNT ID
  const [state] = useContext(ClientContext);

  return (
    <>
      <div className="px-4">
        <div className="bg-white px-6">
          {/* HEADER */}
          <div className="flex flex-wrap items-center py-3">
              <div className="w-full lg:w-6/12">
                  <h3 className="tracking-wide font-bold">FORMS</h3>
              </div>
              <div className="w-full lg:w-6/12 lg:text-right">
                  <Link to={"/admin/forms/new"} className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                      <i className="fas fa-plus-circle"></i>
                      <span className="inline-block ml-1">New</span>
                  </Link>
              </div>
          </div>
          {/* TABLE */}
          <div className="mt-2">
            <FormsTable accountId={state.clientId} />
          </div>
        </div>
      </div>
    </>
  );
}
