import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ClientContext } from "../../../contexts/ClientContext.js";
import { useQuery, useMutation, fromError } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";

import { Account } from "graphql/user/login";
import { DELETE_MEMBER } from "graphql/account/delete-member";
import { UPDATE_MEMBER } from "graphql/account/update-member";

import ButtonRow from "components/Settings/ButtonRow.js";

const CardUserSettings = () => {
  const [state] = useContext(ClientContext);

  const { loading, error, data, refetch } = useQuery(Account, {
    variables: {
      id: state.clientId,
      fetchPolicy: "no-cache",
    },
  });

  const [delUserMutation] = useMutation(DELETE_MEMBER, {
    onCompleted: () => {
      refetch();
      toast.success("Member Deleted!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  const [updateUserMutation] = useMutation(UPDATE_MEMBER, {
    onCompleted: () => {
      refetch();
      toast.success("Member Updated!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  function deleteMember(memberId) {
    console.log(memberId);
    delUserMutation({
      variables: {
        id: memberId,
      },
    });
  }

  function updateMember(e, memberId) {
    console.log(memberId);
    updateUserMutation({
      variables: {
        id: memberId,
        access: e,
      },
    });
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>No Data Found :(</p>;
  console.log(data);
  let currentId = "";

  const userInfo = data.account.membership
    .filter((roleExclude) => roleExclude.user.role !== "api")
    .map((membership, i) => (
      <tr key={i} className="border-t hover:bg-gray-100">
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {membership.user.name} <br></br>{" "}
          <p className="font-bold text-blue-600">{membership.user.email}</p>
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          <select
            name="access"
            // ref={register()}
            onChange={(e) => {
              currentId = data.account.id;
              updateMember(e.target.value, currentId);
            }}
            className="border w-full rounded px-3 py-2 mr-3"
            defaultValue={membership.access}
          >
            <option value="admin">Admin</option>
            <option value="standard">Standard</option>
          </select>
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {membership.user.role}
        </td>
        <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          <button
            className="text-red-500 font-bold uppercase text-xs outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
            onClick={() => {
              currentId = membership.id;
              deleteMember(currentId);
            }}
          >
            Remove
          </button>
        </td>
      </tr>
    ));

  if (data.account.membership === null) return <p>No Data Found :(</p>;

  return (
    <div className="px-4">
      <div className="bg-white">
        {/* SETTINGS MENU */}
        <ButtonRow/>
        {/* HEADER */}
        <div className="flex flex-wrap items-center p-4">
          <div className="w-full lg:w-6/12">
            <h3 className="font-semibold tracking-wide">USERS</h3>
          </div>
          <div className="w-full lg:w-6/12 text-right">
            <Link
              to={"/admin/settings/adduser"}
              className="inline-block bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              <i className="fas fa-plus-circle"></i>
              <span className="inline-block ml-1">Add User</span>
            </Link>
          </div>
        </div>
        {/* FORM OR TABLE */}
        <div className="pb-4 px-4">
          <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    className={
                      "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                    }
                  >
                    Team Member
                  </th>
                  <th
                    className={
                      "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                    }
                  >
                    Access
                  </th>
                  <th
                    className={
                      "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                    }
                  >
                    Role
                  </th>
                  <th
                    className={
                      "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                    }
                  >
                    Edit/Remove
                  </th>
                </tr>
              </thead>
              <tbody>{userInfo}</tbody>
            </table>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardUserSettings;
