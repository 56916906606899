import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GET_ALL_CONTENT_TAGS } from "graphql/content-tags/content-tags.js";
import { useQuery } from "@apollo/client";

const ContentTypesTable = (props) => {
  // let pageList;
  const [activePage, setActivePage] = useState(1);

  // variables
  var page = activePage;
  var totalPage = "";
  const per_page = 20;

  // Query
  const { loading, error, data, fetchMore } = useQuery(GET_ALL_CONTENT_TAGS, {
    variables: {
      account_id: props.accountId,
      page: page,
      per_page: per_page,
      search: props.search,
    },
  });

  if (loading)
    return (
      <div className="px-4 mb-3">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Content Types...</span>
        </div>
      </div>
    );
  if (error)
    return <div className="border-t text-center py-3">No Data Found :(</div>;

  const pageCalculated = Math.ceil(data.getAllContentTags.count / per_page);
  totalPage = Math.round(pageCalculated);
  console.log("Count:", data.getAllContentTags.count);

  const contenttypes = data.getAllContentTags.rows.map((contentTag, i) => (
    <tr key={i} className="border-t w-full border-b hover:bg-gray-100">
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        {contentTag.name}
      </td>
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        {contentTag.slug}
      </td>
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        <Link to={"/admin/contenttags/" + contentTag.id}>
          <button className="font-semibold text-blue-600">
            Edit Content Tag
          </button>
        </Link>
      </td>
    </tr>
  ));

  // pagination
  const pages = Math.ceil(data.getAllContentTags.count / per_page);
  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers
    .slice(activePage - 1, activePage + 9)
    .map((number) => {
      return (
        <button
          className={
            activePage === number
              ? "bg-blue-500 text-white inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:text-blue-400 hover:text-white focus:outline-none" :
              "inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none border"} 
          key={number}
          onClick={() => {
            setActivePage(number);
            page = number;
            fetchMore({
              variables: { page: number },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                fetchMoreResult.getAllContentTags.rows = [
                  ...fetchMoreResult.getAllContentTags.rows,
                ];
                return fetchMoreResult;
              },
            });
          }}
        >
          {number}
        </button>
      );
    });
  return (
    <div>
      {/* TABLE */}
      <div className="relative flex flex-col min-w-0 break-words w-8/12 rounded bg-white">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Name
              </th>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Slug
              </th>
              <th
                className={
                  "px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100"
                }
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>{contenttypes}</tbody>
        </table>
      </div>
      <div className="py-2 border-t text-right">
        {activePage > 1 ? (
          <button
            className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
            onClick={() => {
              const prevPage = activePage > 1 ? activePage - 1 : 1;
              setActivePage(prevPage);
              page = prevPage;
              fetchMore({
                variables: { page: prevPage },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  fetchMoreResult.getAllContentTags.rows = [
                    ...fetchMoreResult.getAllContentTags.rows,
                  ];
                  return fetchMoreResult;
                },
              });
            }}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        ) : (
          ""
        )}
        {renderPageNumbers}
        {activePage < pages ? (
          <button
            className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
            onClick={() => {
              // const nextPageNum = activePage + 1;
              const nextPageNum = activePage < pages ? activePage + 1 : pages;
              setActivePage(nextPageNum);
              page = nextPageNum;
              fetchMore({
                variables: { page: nextPageNum },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  fetchMoreResult.getAllContentTags.rows = [
                    ...fetchMoreResult.getAllContentTags.rows,
                  ];
                  return fetchMoreResult;
                },
              });
            }}
          >
            <i className="fas fa-chevron-right"></i>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default ContentTypesTable;
