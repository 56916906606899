import { gql } from '@apollo/client';

export const GET_ALL_LEADS = gql`
  query GetAllLeads($account_id: String, $form_id: String, $page: Int, $per_page: Int, $search: String, $status: String) {
    getAllLeads(account_id:$account_id, form_id:$form_id, page:$page, per_page:$per_page, search:$search, status:$status) {
      count
      rows {
        id
        firstname
        lastname
        email
        phone
        createdAt
        updatedAt
        body
        status
        utm_source
        utm_medium
        utm_campaign
        utm_content
      }
    }
  }
`;