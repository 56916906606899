import React, { useContext, useEffect, useState } from "react";
import { useQuery,useMutation } from "@apollo/client";
import { GET_ALL_DEALS } from "graphql/deals/deals";
import { ClientContext } from "contexts/ClientContext";
import DealBoard from "./DealBoard";
//test
import { DragDropContext, Draggable, Droppable,resetServerContext } from 'react-beautiful-dnd';
import { UPDATE_DEAL_STAGE } from "graphql/deals/update-deal-stage";

const DealStageBoard = () => {
    const [state] = useContext(ClientContext);
    const { loading:prospectLoading, error: prospectError, data:prospectData, refetch:prospectRefetch  } = useQuery(GET_ALL_DEALS, {
        variables: {
            account_id: state.clientId,
            stage: "Prospect",
            page: 1,
            per_page: 10000,
            search: "",
            sortby: "",
            sort_direction: "desc"
        },
        pollInterval: 1,
    });
    const { loading:qualifiedLoading, error: qualifiedError, data:qualifiedData, refetch:qualifiedRefetch  } = useQuery(GET_ALL_DEALS, {
        variables: {
            account_id: state.clientId,
            stage: "Qualified",
            page: 1,
            per_page: 10000,
            search: "",
            sortby: "",
            sort_direction: "desc"
        },
        pollInterval: 1,
    });
    const { loading:proposalLoading, error: proposalError, data:proposalData, refetch:proposalRefetch  } = useQuery(GET_ALL_DEALS, {
        variables: {
            account_id: state.clientId,
            stage: "Proposal",
            page: 1,
            per_page: 10000,
            search: "",
            sortby: "",
            sort_direction: "desc"
        },
        pollInterval: 1,
    });
    const { loading:onHoldLoading, error: onHoldError, data:onHoldData, refetch:onHoldRefetch  } = useQuery(GET_ALL_DEALS, {
        variables: {
            account_id: state.clientId,
            stage: "On Hold",
            page: 1,
            per_page: 10000,
            search: "",
            sortby: "",
            sort_direction: "desc"
        },
        pollInterval: 1,
    });
    const { loading:wonLoading, error: wonError, data:wonData, refetch:wonRefetch  } = useQuery(GET_ALL_DEALS, {
        variables: {
            account_id: state.clientId,
            stage: "Won",
            page: 1,
            per_page: 10000,
            search: "",
            sortby: "",
            sort_direction: "desc"
        },
        pollInterval: 1,
    });
    const { loading:lostLoading, error: lostError, data:lostData, refetch:lostRefetch  } = useQuery(GET_ALL_DEALS, {
        variables: {
            account_id: state.clientId,
            stage: "Lost",
            page: 1,
            per_page: 10000,
            search: "",
            sortby: "",
            sort_direction: "desc"
        },
        pollInterval: 1,
    });

    if(prospectLoading) return <p>Loading...</p>;
    if(prospectError) return <p>No Data Found :(</p>;
    // qualified
    if(qualifiedLoading) return <p>Loading...</p>;
    if(qualifiedError) return <p>No Data Found :(</p>;
    // proposal
    if(proposalLoading) return <p>Loading...</p>;
    if(proposalError) return <p>No Data Found :(</p>;
    // on hold
    if(onHoldLoading) return <p>Loading...</p>;
    if(onHoldError) return <p>No Data Found :(</p>;
    // won
    if(wonLoading) return <p>Loading...</p>;
    if(wonError) return <p>No Data Found :(</p>;
    // lost
    if(lostLoading) return <p>Loading...</p>;
    if(lostError) return <p>No Data Found :(</p>;

    resetServerContext();
    

    return (
        <div className="relative block w-full">
            <DealBoard prospect={prospectData.getAllDeals.rows} 
            qualified={qualifiedData.getAllDeals.rows} 
            proposal={proposalData.getAllDeals.rows} 
            onHold={onHoldData.getAllDeals.rows} 
            won={wonData.getAllDeals.rows} 
            lost={lostData.getAllDeals.rows}/>
        </div>
    )
}

export default DealStageBoard;