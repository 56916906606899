import React from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const ClientContext = React.createContext();

const initialState = {
    clientId: null,
    clientName: null,
    formId:null,
}

const reducer = (state, action) => {
    switch(action.type) {
        case "CLIENT": 
            return {
                ...state,
                clientId: action.payload.id,
                clientName: action.payload.name,
                formId: action.payload.form_id,
            };
        case "SET_CLIENT":
            return {
                ...state,
                clientId: action.payload.id
            }
        default: 
            return state;
    }
}
// Create a provider for components to consume and subscribe to changes
export const ClientContextProvider = props => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
  
    return <ClientContext.Provider value={[state, dispatch]}>{props.children}</ClientContext.Provider>;
  };