import { gql } from '@apollo/client';

export const getAllPages = gql `
query getAllPages(
    $account_id: String, $page: Int, $per_page: Int, $search: String, $status: Boolean, 
    $sort_direction: String, $sortby: String,
    $contenttag_id: String,
    $contenttype_id: String)
    {
    getAllPages(account_id:$account_id, page:$page, per_page:$per_page, 
    search:$search, status: $status,
    sortby: $sortby,
    sort_direction: $sort_direction,
    contenttag_id: $contenttag_id,
    contenttype_id: $contenttype_id) 
    {
        count
        rows {
           id
           title
           name
           description
           slug
           path
           image
           body
           parent_id
           status
           createdAt
           updatedAt
           order
           contenttype {
                name
                id
            }
            contenttag_page {
                contenttag {
                    name
                    id
                }
            }
           status
        }
    }
}

`