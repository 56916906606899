import { gql } from "@apollo/client";

export const GET_CONTENT_ALLOW_CHILD = gql `
    query getContentAllowChild (
        $account_id: String!
    ) 
    {
        getContentAllowChild(account_id: $account_id) {
            id
            name
            slug
        }
  }
`