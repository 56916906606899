import { gql } from "@apollo/client";

export const GET_EMAIL_JOB = gql`
  query emailJob($id: Int!) {
    emailJob(id: $id) {
      account_id
      id
      isSent
      mail_id
      mailData
      emailOptions
      opened
      result
      time
    }
  }
`;
