import { gql } from "@apollo/client";

export const GET_ALL_CONTENT_TYPES = gql`
  query getAllContentTypes(
    $account_id: String
    $page: Int
    $per_page: Int
    $search: String
  ) {
    getAllContentTypes(
      account_id: $account_id
      page: $page
      per_page: $per_page
      search: $search
    ) {
      count
      rows {
        id
        account_id
        name
        schema
        allow_child
        page{
          id
          name
          slug
          description
          title
        }
      }
    }
  }
`;
