import React, { useState, useContext } from "react";
import { ClientContext } from "../../../contexts/ClientContext.js";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

import { ADD_FORM } from "graphql/forms/add-forms.js";

// // components
let addName;
let account_id;

const AddForms = (props) => {
  const [ state ] = useContext(ClientContext);
  let history = useHistory();
  account_id = state.clientId;
  console.log("account_id", account_id);

  const [inputList, setInputList] = useState([{ name: "", label: "" }]);

  const [addFormMutation] = useMutation(ADD_FORM, {
    onCompleted: () => {
      toast.success("New form added!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
      setTimeout(() => history.push('/admin/forms'), 3000 );
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (fields) => {
    addName = fields.formName;
    addFormMutation({
      variables: {
        account_id: account_id,
        name: addName,
        body: inputList,
        status: false,
      },
    });
  };

  //adding custom fields
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    console.log("list", list);
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { name: "", label: "" }]);
  };

  const lists = inputList.map((list, i) => {
    return (
      <div key={i} className="w-full">
        <div className="w-full flex flex-wrap items-center">
          <div className="relative w-1/2 lg:w-5/12 mt-3 mb-3 pr-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Field Name
              </label>
              <input
                className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                name="name"
                ref={register()}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>
          <div className="relative w-1/2 lg:w-5/12 mt-3 mb-3 pr-4">
            <div className="relative w-full mb-3 pl-4">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Form Label
              </label>
              <input
                className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                name="label"
                ref={register()}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>
          <div className="btn-box relative lg:w-1/6  mt-3 mb-3 pr-4">
            {inputList.length !== 1 && (
              <button
                className="bg-red-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                onClick={() => handleRemoveClick(i)}
              >
                <i className="fa fa-minus"></i>
              </button>
            )}
          </div>  
        </div>
        <div>
          {inputList.length - 1 === i && (
            <button
              className="inline-block bg-transparent border border-dotted border-gray-400 text-blue-500 active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={handleAddClick}
            >
              Add
            </button>
          )}
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
            {/* Header */}
            <div className="rounded-t mb-0 px-2 py-3 border-0">
                <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 className={ "font-semibold text-lg "}>
                            Add Form
                        </h3>
                    </div>  
                    <div>
                        <Link to="/admin/forms" className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                            <i className="fas fa-chevron-left"></i>
                            <span className="inline-block ml-1">Forms</span>
                        </Link>
                    </div>
                </div>
            </div>
            {/* end of header */}
            {/* Forms */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="w-full">
                <div className="px-6 py-3">
                  <div className="relative w-full mb-3">
                    <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        Form Name
                    </label>
                    <input
                      type="text"
                      name="formName"
                      ref={register({ required: {message: "Form Name is required",value:true} })}
                      className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    />
                    {errors.name && (
                        <span className="mt-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-500 bg-red-200 uppercase last:mr-0 mr-1">
                            {errors.name.message}
                        </span>
                    )}
                  </div>
                  {lists}
                  {/* SUBMIT BTN */}
                  <div className="pt-6 pb-6">
                      <button type="submit" className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">Submit Form</button>
                  </div>
                </div>
              </div>
              {/* ALERT */}
              <ToastContainer />
            </form>
            {/* end of forms */}
        </div>
      </div>
    </>
  );
};

export default AddForms;
