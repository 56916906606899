import { gql } from "@apollo/client";

export const GET_MAIL = gql`
  query mail($id: String!) {
    mail(id: $id) {
      name
      title
      body
      file
    }
  }
`;
