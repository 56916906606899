import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { GET_ALL_MEDIA } from "graphql/media/allmedia";
import { ClientContext } from "contexts/ClientContext";
import MediaTable from "components/Media/MediaTable";

const Media = (props) => {
    // get client id
    const [state] = useContext(ClientContext);
    // search and sort 
    // const [ changeStage, setChangeStage ] = useState("");
    const [ findSearch, setFindSearch ] = useState("");
    const [ activePage , setActivePage ] = useState(1);
    // components
    let page = 1;
    let per_page = 30;
    console.log("ACCOUNT:", state.clientId);
    // get all media
    const { loading,error,data, fetchMore, refetch } = useQuery(GET_ALL_MEDIA, {
        variables: {
            account_id: state.clientId,
            page: page,
            per_page: per_page,
            search: findSearch,
            sortby: "createdAt",
            sort_direction: "desc"
        }
    });
    // console.log("here:", data.count);
    // console.log("AllMedia length:", data.getAllMedia.length);
    // pagination
    const count = (loading) ? 0 : (( error )) ? 0 : data.getAllMedia.length;
    // const count = 200; // hard code temporarily
    const pages = Math.ceil(count / per_page);
    // console.log("count:", data.getAllMedia.length);
    console.log("Media pages:", pages);
    const pageNumbers = [];
    for(let i = 1; i <= pages; i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers
    .slice(activePage - 1, activePage + 9)
    .map(number => {
        return(
            <button 
            className={ 
                (activePage === number) ? 
                "bg-blue-500 text-white inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:text-blue-400 hover:text-white focus:outline-none" :
                "inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none border"} 
            key={number}
            onClick={
                () => {
                    setActivePage(number);
                    page = number;
                    fetchMore({
                        variables: { page: number },
                        updateQuery: (prevResult, { fetchMoreResult }) => {
                            fetchMoreResult.getAllMedia = [
                                ...fetchMoreResult.getAllMedia
                            ];
                            return fetchMoreResult;
                        }
                    })
                }
            }>
                {number}
            </button>
        )
    });
    

    return (
        <div className="px-4">
            {/* FILTERS */}
            <div className="bg-white">
                <div className="py-4 px-6">
                    <div className="flex flex-wrap w-full items-center">
                        <div className="w-full lg:w-6/12">
                            <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                                <div className="relative flex w-full flex-wrap items-stretch">
                                    <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-2">
                                        <i className="fas fa-search"></i>
                                    </span>
                                    <input 
                                    type="text" 
                                    placeholder="Search here..." 
                                    className="px-2 py-2 placeholder-gray-400 text-gray-700 relative bg-white bg-white border text-sm outline-none focus:outline-none focus:shadow-outline w-full pl-10"
                                    onChange={ (e) => { setFindSearch(e.target.value) } }
                                    />
                                </div>
                            </form>
                        </div>
                        {/* <div className="w-full lg:w-6/12 text-right">
                            <h3 className="inline-block mr-2 ">Stage: </h3>
                            <select className="border px-2 py-2 mr-3 focus:outline-none focus:shadow-outline" value={changeStage}
                                onChange={ (e) => { setChangeStage(e.target.value) }  }
                            >
                                <option value="">Focus</option>
                                <option value="Prospect">Prospect</option>
                                <option value="Qualified">Qualified</option>
                                <option value="Proposal">Proposal</option>
                                <option value="On Hold">On Hold</option>
                                <option value="Won">Won</option>
                                <option value="Lost">Lost</option>
                            </select>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* END OF FILTERS */}


            <div className="bg-white mt-5 px-6">
                {/* HEADER */}
                <div className="flex flex-wrap items-center py-3">
                    <div className="w-full lg:w-6/12">
                        <h3 className="tracking-wide font-bold">MEDIA</h3>
                    </div>
                    <div className="w-full lg:w-6/12 lg:text-right">
                        <Link to={"/admin/media/new" } className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                            <i className="fas fa-plus-circle"></i>
                            <span className="inline-block ml-1">New</span>
                        </Link>
                    </div>
                </div>
                {/* TABLE */}
                {
                    loading ? (
                        <div className="px-4 mb-3">
                            <div className="inline-block bg-white shadow-md py-2 rounded-full">
                                <span className="block px-4">Fetching Media...</span>
                            </div>
                        </div>
                    ) : error ? (
                        <div className="border-t text-center py-3">No Data Found</div>
                    ) : (
                        <div className="mt-2">
                            <MediaTable {...{refetch}} data={data.getAllMedia}/>
                        </div>
                    )
                }
                {/* PAGINATION */}
                <div className="py-2 border-t text-right">
                    {/* PREV Button */}
                    { (activePage > 1) ? (
                        <button className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                        onClick={
                            () => {
                                const prevPage = (activePage > 1) ? activePage - 1 : 1;
                                setActivePage(prevPage);
                                page = prevPage;
                                fetchMore({
                                    variables: { page: prevPage },
                                    updateQuery: (prevResult, { fetchMoreResult }) => {
                                        fetchMoreResult.getAllMedia = [
                                            ...fetchMoreResult.getAllMedia
                                        ];
                                        return fetchMoreResult;
                                    }
                                })
                            }
                        } >
                            <i className="fas fa-chevron-left"></i>
                        </button>
                    ) : (
                        <button 
                        disabled 
                        className="text-gray-500 align-middle inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                        >
                            <i className="fas fa-chevron-left"></i>
                        </button>
                    ) }
                    {/* render numbers */}
                    {renderPageNumbers}
                    {/* NEXT Button */}
                    { (activePage < pages) ? (
                            <button className="inline-block align-middle text-base text-center w-8 h-8 bg-white mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                            onClick={
                                () => {
                                    // const nextPageNum = activePage + 1;
                                    const nextPageNum = (activePage < pages) ? activePage + 1 : pages;
                                    setActivePage(nextPageNum);
                                    page = nextPageNum;
                                    fetchMore({
                                        variables: { page: nextPageNum },
                                        updateQuery: (prevResult, { fetchMoreResult }) => {
                                            fetchMoreResult.getAllMedia = [
                                                ...fetchMoreResult.getAllMedia
                                            ];
                                            return fetchMoreResult;
                                        }
                                    })
                                }
                            } >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                            ) : (
                                <button 
                                disabled 
                                className="text-gray-500 inline-block align-middle text-base text-center w-8 h-8 bg-white mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                                >
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            )
                        }
                </div>
            </div>
        </div>
    )
}

export default Media;