import axios from "axios";
import { AuthContext } from "contexts/AuthContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import moment from 'moment';

const LeadsExport = (props) => {
    const [ state ] = useContext(AuthContext);
    let [ leads, setLeads ] = useState("");
    function fetchData() {
        axios({
            "method": "GET",
            "url" : "https://backend.marketcloud.co/lead-download",
            "headers": {
                "Authorization" : "Bearer "+ state.token
            },
            "params": {
                "account_id": props.accountId,
                "form_id": props.formId == "" ? props.initialForm : props.formId,
            },
        }).then((response) => {
            setLeads(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        fetchData();
    },[fetchData, props.formId]);

    return (
        // <div class="inline-block">
            <CSVLink className="inline-block active:bg-green-600 font-bold uppercase text-xs px-4 py-2 rounded hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                data={leads} 
                filename={"leads_"+ moment(new Date()).format()+ ".csv"}
            >
                <i className="fas fa-file-export"></i>
                <span className="inline-block ml-1">Export</span>    
            </CSVLink>
        // </div>
    )
}

export default LeadsExport;