import { gql } from "@apollo/client";

export const UPDATE_PAGE = gql`
  mutation updatePage(
    $id: String!
    $account_id: String!
    $name: String
    $title: String
    $description: String
    $slug: String
    $path: String
    $image: String
    $body: JSON
    $status: Boolean
    $order: Int
  ) {
    updatePage(
      id: $id
      account_id: $account_id
      name: $name
      title: $title
      description: $description
      slug: $slug
      path: $path
      image: $image
      body: $body
      status: $status
      order: $order
    )
  }
`;
