import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Moment from "react-moment";
// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// gql
import { GET_POINT } from "graphql/points/point";
import { useMutation, useQuery } from "@apollo/client";
import { ClientContext } from "contexts/ClientContext";
import { VOID_POINT } from "graphql/points/void-point";

const CustomerUpdatePoint = (props) => {
    // CONTEXT to get Account Id
    const [ state ] = useContext(ClientContext);
    // REDIRECT
    let history = useHistory();
    // MUTATION
    const [ voidPointMutation ] = useMutation(VOID_POINT, {
        onCompleted: () => {
            toast.error("Transaction Void!", {
                position: "bottom-right",
                className: "black-background",
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar"
            });
            setTimeout(() => history.push('/admin/customers/'+ props.match.params.id),2000);
        },
        onError: (error) => { console.log(error, "error"); }
    })
    // GET POINT
    const { loading, error, data } = useQuery(GET_POINT, {
        variables: {
            id: parseInt(props.match.params.point_id),
        }
    });
    
    const voidPoint = (accountId, pointId) => {
        voidPointMutation({
            variables: {
                id: pointId,
                account_id: accountId
            }
        })
    }

    return(
        <>
            {
                loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>Error :( Please Try Again</p>
                ) : (
                    <div className="relative block w-full">
                        <div className="px-4">
                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                                {/* Header */}
                                <div className="rounded-t mb-0 px-2 py-3 border-0">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <h3 className={ "font-semibold text-lg "}>
                                                Edit Point
                                            </h3>
                                        </div>  
                                        <div>
                                            <Link to={"/admin/customers/"+ props.match.params.id} className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                                                <i className="fas fa-chevron-left"></i>
                                                <span className="inline-block ml-1">Back</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* END OF HEADER */}
                                <div className="px-6 py-3">
                                {/* VOI */}
                                {   (data.point.status === false) ? (
                                        <div className="mb-5 bg-red-400 px-4 py-3 text-white text-sm shadow" role="alert">
                                            Transaction Void
                                        </div>
                                    ) : null
                                }
                                <div className="flex flex-wrap mb-3">
                                    {/* TRANSACTION TYPE */}
                                    <div className="w-full lg:w-6/12 pr-0 pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-600 text-xs mb-2">
                                                Transaction Type</label>
                                            <span className="text-sm capitalize font-bold">{ data.point.transaction_type }</span>
                                        </div>
                                    </div>
                                    {/* TRANSACTION DATE */}
                                    <div className="w-full lg:w-6/12 pr-0 lg:pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-600 text-xs mb-2">
                                                Transaction Date</label>
                                            <span className="text-sm capitalize font-bold"><Moment format="YYYY-MM-DD HH:mm">{ data.point.transaction_date }</Moment></span>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap mb-3">
                                    {/* SALES VALUE */}
                                    <div className="w-full lg:w-6/12 pr-0 pr-4">
                                        <div className="relative w-full mb-3">
                                        <label className="block uppercase text-gray-600 text-xs mb-2">
                                                Sales Value</label>
                                            <span className="text-sm capitalize font-bold">{ data.point.salevalue }</span>
                                            
                                        </div>
                                    </div>
                                    {/* POINT AWARDED */}
                                    <div className="w-full lg:w-6/12 pr-0 lg:pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-600 text-xs mb-2">
                                                Point Awarded</label>
                                            <span className="text-sm capitalize font-bold">{ data.point.amount }</span>
                                                
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap mb-3">
                                    {/* EXPIRY */}
                                    <div className="w-full lg:w-6/12 pr-0 pr-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-600 text-xs mb-2">
                                                Expiry</label>
                                            <span className="text-sm capitalize font-bold"><Moment format="YYYY-MM-DD HH:mm">{ data.point.expiry }</Moment></span>
                                               
                                                
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 pr-0 lg:pr-4">
                                    </div>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    >Description</label>
                                    <p className="text-sm capitalize font-bold">{data.point.transaction_description}</p>
                                </div>
                                {/* SUBMIT BTN */}
                                <div className="pt-0 pb-6">
                                    {/* <button type="submit" className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">Update</button> */}
                                    <button type="submit" onClick={ () => voidPoint(state.clientId, data.point.id)} className="bg-red-400 text-white active:bg-red-500 hover:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">VOID Point</button>
                                </div>

                                <ToastContainer />
                            </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default CustomerUpdatePoint;