import React, { useContext } from "react";
import { ClientContext } from "../../../contexts/ClientContext.js";
import LeadForms from "components/Leads/LeadForms.js";

export default function Leads() {
    // GET ACCOUNT ID
    const [ state ] = useContext(ClientContext);
    
    return (
        <div className="px-4">
            <LeadForms accountId={state.clientId}/>
        </div>
    )
}