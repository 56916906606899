import React, { useContext } from "react";
import { createPopper } from "@popperjs/core";
import { Link, useHistory } from "react-router-dom";
import { ME } from "graphql/user/login";
import { useQuery, useApolloClient } from "@apollo/client";

import { AuthContext } from "contexts/AuthContext.js";

const UserDropdown = (props) => {
  const history = useHistory();

  const [state, dispatch] = useContext(AuthContext);
  const client = useApolloClient();
  // For clear & reset store checking:
  // console.log("apollo client:", client);
  const logout = () => {
    dispatch({ type: "LOGOUT" });
    client.clearStore().then(() => {
    console.log("Logged out");
    history.push("/auth/login");
    });
  };

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const { loading, error, data } = useQuery(ME);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const userName = data.me.name;
  const userEmail = data.me.email;
  const firstLetter = userName.charAt(0);
  return (
    <>
      <a
        className="text-gray-600 block pr-3"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <div className="inline-block align-middle bg-gray-300 text-center font-bold -mt-2 text-2xl px-2 rounded ">{firstLetter}</div>
          <div>
            <h3 className="hidden lg:block ml-2 text-xs">Hello!</h3>
            <h3 className="hidden lg:block ml-2 text-sm">{userName}</h3>
          </div>
          <i className="fas fa-chevron-down ml-2"></i>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-right list-none text-left rounded shadow-lg min-w-48 overflow-hidden"
        }
      >
        <Link 
          to="/admin/settings"
          className={
            "text-sm px-4 py-2 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800 hover:bg-gray-100"
          }
          onClick={(e) => {
            closeDropdownPopover();
          }}
        >
          <i className="fa fa-user-circle mr-3"></i>
          Profile
        </Link>
        <a
          href="#pablo"
          className={
            "text-sm px-4 py-2 font-normal block w-full whitespace-no-wrap bg-transparent text-red-500 border-t hover:bg-gray-100"
          }
          onClick={logout}
        >
          <i className="fa fa-sign-out-alt mr-3"></i>
          Logout
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
