import React, { useEffect,useState,useContext } from "react";
import { Link } from "react-router-dom";
import DealForm from "./DealForm";
import { useQuery, useMutation } from "@apollo/client";
import { FORMS } from "graphql/forms/forms";
import { ClientContext } from "contexts/ClientContext";

const DealAddForm = (props) => {
    // CONTEXT
    const [ state] = useContext(ClientContext);
    const  { loading, error, data, refetch } = useQuery(FORMS, {
        variables: {
            account_id: state.clientId,
            page: 1,
            per_page: 1000,
            search: ""
        }
    });
    return (
        <div className="relative block w-full">
            <div className="px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
                    {/* Header */}
                    <div className="rounded-t mb-0 px-2 py-3 border-0">
                        <div className="flex flex-wrap items-center">
                            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3 className={ "font-semibold text-lg "}>
                                    Add New Deal
                                </h3>
                            </div>  
                            <div>
                                <Link to="/admin/deals" className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                                    <i className="fas fa-chevron-left"></i>
                                    <span className="inline-block ml-1">Deals</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="py-6 px-6">
                        {
                            loading ? (
                                <p>Forms Loading....</p>
                            ) : error ? (
                                <p>Forms Error. Please Try Again</p>
                            ) : (
                                <DealForm form={data.getAllForms.rows} initialForm={data.getAllForms.rows[0].id} forms={true} leads={true}/>
                            )
                        }
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default DealAddForm;