import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_PAGE } from "graphql/pages/update-pages";
import arrayMove from 'array-move';
import Moment from "react-moment";
import { DragDropContext, Droppable, Draggable , resetServerContext} from 'react-beautiful-dnd';

const PageOrderTable = (props) => {
    const [ pages, updatePages ] = useState(props.pages);
    const [ save, setSave ] = useState();
    let updateItems; 

    // mutation
    const [ updatePageMutation ] = useMutation(UPDATE_PAGE, {
        onCompleted: () => {
            setSave("SAVED"); 
            setTimeout(function(){
                setSave();
            },1000);
        },
        onError: (error) =>console.log(error, "error"),
    });
    // ARRAY THE ITEMS
    function onDragUpdate(update) {
        if(!update.destination) return;
        let newItem = arrayMove(pages, update.source.index, update.destination.index);
        updateItems = newItem;
    }
    function handleOnDragEnd(result) {
        if(!result.destination) return;
        // MUTATE THE UPDATED ITEMS
        if(updateItems != undefined) {
            updatePages(updateItems);
            updateItems.map((item,i) => {
                updatePageMutation({
                    variables: {
                        id: item.id,
                        account_id: props.clientId,
                        order: i,
                    }
                });
            });
            setSave(true);
            props.refetch();
        }

    }

    resetServerContext();

    return (
        <div className="w-full">
            {save ?
            <div className="fixed right-0 left-0 text-center" style={{top: "20px"}}>
            <span className="inline-block mx-auto bg-black text-white text-sm font-semibold px-4 py-2 rounded-full shadow-lg">Auto-Save!</span>
            </div>: <></> }
            <ul className="flex w-full border-b">
                <li className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100" style={{width: "80px"}}>#</li>
                <li className="w-1/2 px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">Thumbnail</li>
                <li className="w-1/2 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">Name</li>
                <li className="w-1/2 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">Slug</li>
                <li className="w-1/2 pl-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">Status</li>
                <li className="w-1/2 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">Year</li>
            </ul>
            <DragDropContext
            onDragUpdate={onDragUpdate}
            onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="banners">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {
                                pages.map((page, i) => {
                                    return (
                                        <Draggable key={page.id} draggableId={page.id} index={i} type="Columns">
                                            {(provided) => (
                                                <div className="flex border-b items-center py-3 hover:bg-gray-100 bg-white" 
                                                ref={provided.innerRef} 
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                    <div className="px-3" style={{width: "80px"}}><i className="fas fa-bars text-gray-400"></i></div>
                                                    <div className="w-1/2 px-3">
                                                        <img src={page.image != null ? page.image.replace("https://d2w3j2ad8yyl6t.cloudfront.net/","https://marketcloudco-resized.s3-ap-southeast-1.amazonaws.com/") : ""} style={{maxHeight: 100 + 'px'}}/>
                                                    </div>
                                                    <div className="w-1/2 font-semibold text-sm">{page.name}</div>
                                                    <div className="w-1/2 text-xs mr-4">/{page.slug}</div>
                                                    <div className="w-1/2 text-xs">{page.status.toString() === "true" ? "Published" : "Unpublished"}</div>
                                                    <div className="w-1/2 text-xs"><Moment format="YYYY">{page.createdAt}</Moment></div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext> 
        </div>
    )
}

export default PageOrderTable;