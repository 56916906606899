import { gql } from '@apollo/client';

export const GETS3SIGNED_URL = gql `
    mutation getS3signedurl(
        $account_id: String!, 
        $filename: String!,
        $content_type: String!,
        $folder_id: String
    )
    {
    getS3signedurl(
        account_id: $account_id, 
        filename: $filename,
        content_type: $content_type,
        folder_id: $folder_id
    )
}

`