import React from "react";
// graphql
import { useQuery } from "@apollo/client";
import { GET_PRODUCT } from "graphql/products/product";

const ProductDetail = (props) => {
    const getId = props.match.params.id;
    // get product
    const { loading, error, data } = useQuery(GET_PRODUCT, {
        variables: {
            id:getId
        }
    });

    if(loading) return  <div className="px-4">
                            <div className="inline-block bg-white shadow-md py-2 rounded-full">
                                <span class="block px-4">Fetching Lead...</span>
                            </div>
                        </div>;
    if(error) return <div className="border-t text-center py-3">No Data Found :(</div>;
    
    console.log(data);
    const categories = data.product.custom_attributes.category_ids;
    return (
        <>
            <div className="relative block w-full ">
                <div className="flex flex-wrap">
                    {/* Information */}
                    <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                            <div className="rounded-t mb-0 px-4 py-3 border-0">
                                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3 className="font-semibold text-lg mb-2 ">
                                        {data.product.name}
                                    </h3>
                                    <div className="py-2">
                                        <span className="text-gray-600 tracking-wide">Title: </span>
                                        <span className="text-black">{data.product.name}</span>
                                    </div>
                                    <div className="py-2">
                                        <span className="text-gray-600 tracking-wide">SKU: </span>
                                        <span className="text-black">{data.product.sku}</span>
                                    </div>
                                    <div className="py-2">
                                        <span className="text-gray-600 tracking-wide">Price: </span>
                                        <span className="text-black">${data.product.price}</span>
                                    </div>
                                    <div className="py-2">
                                        <span className="text-gray-600 tracking-wide">Url/Slug: </span>
                                        <span className="text-black">{data.product.custom_attributes.url_key}</span>
                                    </div>
                                    <div className="py-2">
                                        <span className="text-gray-600 tracking-wide">Categories: </span>
                                        <span className="text-black">{ categories  }</span>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                            <div className="rounded-t mb-0 px-4 py-3 border-0">
                                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3 className="font-semibold text-lg ">
                                        Description:
                                    </h3>
                                    <p className="text-black py-2 tracking-wider text-base" dangerouslySetInnerHTML={{__html: data.product.custom_attributes.description}}></p>
                                </div>
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                            <div className="rounded-t mb-0 px-4 py-3 border-0">
                                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3 className="font-semibold text-lg ">
                                        Manufacturer
                                    </h3>
                                    <p className="text-black py-2 tracking-wider text-base">Brand: <strong>{data.product.custom_attributes.manufacturer}</strong></p>
                                    <p className="text-black py-2 tracking-wider text-base">Country: <strong>{data.product.custom_attributes.country_of_manufacture}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Image */}
                    <div className="w-full xl:w-4/12 px-4">
                        <img src={"https://m2.glovida.com/media/catalog/product/"+data.product.custom_attributes.image}
                        className="shadow"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDetail;