import { gql } from "@apollo/client";

export const REMOVE_CONTENT_TAG = gql`
  mutation removeContenttag(
    $account_id: String
    $page_id: String
    $contenttag_id: String
  ) {
    removeContenttag(
      account_id: $account_id
      page_id: $page_id
      contenttag_id: $contenttag_id
    )
  }
`;
