import { gql } from "@apollo/client";

export const GET_POINT = gql`
    query point($id: Int!){
        point(id: $id) {
            id
            customer_id
            reward_id
            transaction_date
            transaction_type
            transaction_description
            amount
            salevalue
            status
            expiry
        }
    }
`;  