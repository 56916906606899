import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ADD_FORM } from "graphql/forms/add-forms.js";

// // components
let addName;
let fieldName;
let fieldLabel;
let account_id;

const AddMails = (props) => {
  account_id = props.accountId;
  console.log("account_id", account_id);

  const [inputList, setInputList] = useState([
    { fieldName: "", fieldLabel: "" },
  ]);

  const [addMailMutation] = useMutation(ADD_FORM, {
    onCompleted: () => {
      toast.success("New form added!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
    },
    onError: (error) => {
      console.log(error, "error");
    },
  });

  const { handleSubmit, register } = useForm();

  const onSubmit = (fields) => {
    addName = fields.name;
    fieldName = fields.fieldName;
    fieldLabel = fields.fieldLabel;
    console.log("field name", fieldName, "field label", fieldLabel);
    let newMail = {
      fieldName: fieldName,
      fieldLabel: fieldLabel,
    };

    console.log("test newMail", newMail);
    addMailMutation({
      variables: {
        account_id: account_id,
        name: addName,
        body: newMail,
        status: false,
      },
    });
  };

  //adding custom fields
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { fieldName: "", fieldLabel: "" }]);
  };

  const lists = inputList.map((list, i) => {
    return (
      <div key={i} className="w-full">
        <div className="w-full flex flex-wrap items-center">
          <div className="relative w-1/2 lg:w-5/12 mt-3 mb-3 pr-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Field Name
              </label>
              <input
                className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                name="fieldName"
                ref={register()}
                value={list.fieldName}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>
          <div className="relative w-1/2 lg:w-5/12 mt-3 mb-3 pr-4">
            <div className="relative w-full mb-3 pl-4">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Mail Label
              </label>
              <input
                className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                name="fieldLabel"
                ref={register()}
                value={list.fieldLabel}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>
          <div className="btn-box relative lg:w-1/6  mt-3 mb-3 pr-4">
            {inputList.length !== 1 && (
              <button
                className="bg-red-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                onClick={() => handleRemoveClick(i)}
              >
                <i className="fa fa-minus"></i>
              </button>
            )}
          </div>
        </div>
        <div>
          {inputList.length - 1 === i && (
            <button
              className="inline-block bg-transparent border border-dotted border-gray-400 text-blue-500 active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={handleAddClick}
            >
              Add
            </button>
          )}
        </div>
      </div>
    );
  });
  return (
    <>
    <div className="px-4">
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-gray-800 text-xl font-bold">Add Mail</h6>
            <div className="w-1/2 text-right">
              <Link
                to={"/admin/mails"}
                className="bg-gray-700 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              >
                <i className="fas fa-chevron-left"></i>
                <span className="inline-block ml-1">Mails</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex-auto px-6 py-10 pt-0 bg-white">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap ">
              <div className="w-full lg:w-12/124">
                <div className="relative w-full mt-3 mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Mail Name
                  </label>
                  <input
                    name="name"
                    ref={register({ required: true })}
                    type="text"
                    className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              {lists}
              {/* SUBMIT BTN */}
              <div className="pt-6 pb-6">
                  <button type="submit" className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">Submit</button>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    </div>
    </>
  );
};

export default AddMails;
