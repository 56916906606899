import { gql } from "@apollo/client";

export const GET_EMAIL_JOBS = gql`
  query getEmailJobs(
    $account_id: String
    $page: Int
    $per_page: Int
    $search: String
    $sortby: String
    $isSent: Boolean
  ) {
    getEmailJobs(
      account_id: $account_id
      page: $page
      per_page: $per_page
      search: $search
      sortby: $sortby
      isSent: $isSent
    ) {
      count
      rows {
        id
        isSent
        mailData
        result
        time
        emailOptions
        opened
      }
    }
  }
`;
