import React, { useContext } from "react";
import { ClientContext } from "../../../contexts/ClientContext.js";
import CustomerForms from "components/Customers/CustomerForms.js";

export default function Customers() {
    // GET ACCOUNT ID
    const [ client ] = useContext(ClientContext);

    return (
        <>
            <div className="relative block w-full overflow-x-auto">
                <div className="px-4">
                    <CustomerForms accountId={client.clientId}/> 
                </div>
            </div>
        </>
    )
}