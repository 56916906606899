import { gql } from "@apollo/client";

export const VOID_POINT = gql `
    mutation voidPoint(
        $id: Int!,
        $account_id: String!
    )
    {
        voidPoint(
            id:$id, 
            account_id:$account_id
        )
    }
`;