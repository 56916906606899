import { gql } from '@apollo/client';

export const GET_LEAD = gql`
    query lead($id: String!){
        lead(id: $id) {
            id
            firstname
            lastname
            email
            phone
            createdAt
            updatedAt
            body
            form {
                id
                name
            }
            comments {
                id
                date
                name
                comment
            }
            deal{
                id
                name
            }
            status
        }
    }
`;
