import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ClientContext } from "../../../contexts/ClientContext.js";
import PagesTable from "components/Pages/PagesTable.js";
// gql
import { useQuery } from "@apollo/client";
import { GET_ALL_CONTENT_TYPES } from "graphql/content-types/content-types.js";
import { GET_ALL_CONTENT_TAGS } from "graphql/content-tags/content-tags.js";

export default function Pages() {
  // GET ACCOUNT ID
  const [state] = useContext(ClientContext);
  const [ changeFilter, setChangeFilter ] = useState();
  const [ findSearch, setFindSearch] = useState("");
  const [ chooseContentTag, setChooseContentTag ] = useState("");
  const [ chooseContentType, setChooseContentType ] = useState("");
  // content types
  const { loading, data, error } = useQuery(GET_ALL_CONTENT_TYPES, {
    variables: {
      account_id: state.clientId,
      page: 1,
      per_page: 1000,
      search: ""
    }
  });
  // content tags
  const { loading: tagsLoading, error: tagsError, data: tagsData } = useQuery(GET_ALL_CONTENT_TAGS, {
    variables: {
      account_id: state.clientId,
      page: 1,
      per_page: 1000,
      search: "",
    }
  })
  return (
    <div className="px-4">
      <div className="bg-white">
        <div className="py-4 px-6">
          <div className="flex flex-wrap w-full items-end">
            <div className="w-full lg:w-6/12">
              {/* SEARCH */}

              {/* <span className="block text-sm pb-2 mr-2 text-gray-500">Search:</span> */}
              <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                <div className="relative flex w-full flex-wrap items-stretch">
                  <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-2">
                    <i className="fas fa-search"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Search Pages"
                    className="px-2 py-2 placeholder-gray-400 text-gray-700 relative bg-white bg-white border text-sm outline-none focus:outline-none focus:shadow-outline w-full pl-10"
                    onChange={(e) => {
                      setFindSearch(e.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="w-full lg:w-6/12">
              <div className="flex">
                {/* Choose Status */}
                <div className="w-1/2 mr-3">
                  {/* Choose Form */}
                  <span className="block text-sm pb-2 mr-2 text-gray-500">Status:</span>
                  <select className="border px-2 py-2 mr-3 focus:outline-none focus:shadow-outline w-full"
                  value={changeFilter}
                  onChange={
                    (e) => {
                      setChangeFilter(e.target.value);
                    }
                  }>
                    <option value="null">All</option>
                    <option value="true">Published</option>
                    <option value="false">Unpublished</option>
                  </select>
                </div>
                {/* Choose Content Type */}
                <div className="w-1/2 mr-3">
                  <span className="block text-sm pb-2 mr-2 text-gray-500">Content Type:</span>
                  {
                    loading ? (
                      <p>Loading...</p>
                    ) : error ? (
                      <p>Error. Try Again</p>
                    ) : (
                      <select className="border px-2 py-2 mr-3 focus:outline-none focus:shadow-outline w-full"
                      onChange={(e)=>{setChooseContentType(e.target.value)}}
                      >
                      <option value="">All</option>
                      { data.getAllContentTypes.rows.map((type, i) => (
                          <option key={i} value={type.id}>{type.name}</option>
                      )) }
                      </select>
                    )
                  }
                </div>
                {/* Choose Content Tag */}
                <div className="w-1/2">
                  <span className="block text-sm pb-2 mr-2 text-gray-500">Content Tag:</span>
                  {
                    tagsLoading ? (
                      <p>Loading...</p>
                    ) : tagsError ? (
                      <p>Error. Try Again</p>
                    ) : (
                      <select className="border px-2 py-2 mr-3 focus:outline-none focus:shadow-outline w-full"
                      onChange={(e) => {setChooseContentTag(e.target.value)}}
                      >
                      <option value="">All</option>
                      { tagsData.getAllContentTags.rows.map((tag, i) => (
                          <option key={i} value={tag.id}>{tag.name}</option>
                      )) }
                      </select>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-6 mt-5">
        {/* HEADER */}
        <div className="flex flex-wrap items-center py-3">
          <div className="w-full lg:w-6/12">
              <h3 className="tracking-wide font-bold">CONTENTS</h3>
          </div>
          <div className="w-6/12 rounded-t bg-white mb-0 text-right">
            <Link
              to={"/admin/pages/new"}
              className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              <i className="fas fa-plus-circle"></i>
              <span className="inline-block ml-1">Add Page</span>
            </Link>
          </div>
        </div>
        {/* TABLE */}
        <div className="mt-2  overflow-x-auto">
          <PagesTable accountId={state.clientId} 
          search={findSearch} 
          status={(changeFilter === "true") ? true : (changeFilter === "false") ? false : null}
          contenttagId={chooseContentTag}
          contenttypeId={chooseContentType}
          />
        </div>
      </div>
    </div>
  );
}
