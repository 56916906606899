import { gql } from "@apollo/client";

export const ADD_CONTENT_TAG = gql`
  mutation createContentTag(
    $account_id: String
    $name: String!
    $slug: String 
  ) {
    createContentTag(account_id: $account_id, name: $name, slug: $slug)
  }
`;
