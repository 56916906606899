import { gql } from "@apollo/client";

export const GET_ALL_REWARDS = gql`
  query getAllRewards(
    $accountid: String
    $page: Int
    $per_page: Int
    $search: String
  ) {
    getAllRewards(
      accountid: $accountid
      page: $page
      per_page: $per_page
      search: $search
    ) {
      count
      rows {
        id
        name
        points_required
      }
    }
  }
`;
