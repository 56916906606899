import { gql } from '@apollo/client';

export const GET_ALL_DEALS = gql`
    query getAllDeals(
            $account_id: String, 
            $stage: String, 
            $page: Int, 
            $per_page: Int, 
            $search: String,
            $sortby: String,
            $sort_direction: String
        )
        {
        getAllDeals(
            account_id:$account_id, 
            stage:$stage, 
            page:$page, 
            per_page:$per_page, 
            search:$search,
            sortby:$sortby,
            sort_direction: $sort_direction
        ) 
            {
                count
                rows {
                    id
                    name
                    description
                    amount
                    stage
                    user{
                        id
                        name
                    }
                    date_due
                    createdAt
                }
            }
        }
`;