import React, { useContext,useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { GET_ALL_LEADS } from "graphql/leads/leads.js";
import { useQuery } from "@apollo/client";
import { ClientContext } from "../../contexts/ClientContext.js";
import Moment from "react-moment";
import { get } from "react-hook-form";
import ShowMoreText from 'react-show-more-text';

const LeadsTable = (props) => {
  // state
  const [ activePage, setActivePage ] = useState(1);
  // LEADS FIRST LOAD
  const [state] = useContext(ClientContext);
  // variables
  let page = activePage;
  const per_page = 20;

  // Query: GET ALL LEADS
  const { loading, error, data, fetchMore, refetch } = useQuery(GET_ALL_LEADS, {
    variables: {
      account_id: props.accountId,
      form_id: props.formId == "" ? props.initialForm : props.formId,
      page: page,
      per_page: per_page,
      search: props.search,
      status: props.status,
    },
  });
  console.log("Data filter check:", props.status)

  if (loading)
    return (
      <div className="mb-3 py-3">
        <div className="inline-block bg-white shadow-md py-2 rounded-full">
          <span className="block px-4">Fetching Leads...</span>
        </div>
      </div>
    );
  if (error) return <div className="border-t text-center py-3">No Data Found :(</div>;
  
  const leadData = data.getAllLeads.rows;
  console.log("lead data:",leadData);
  
  // PAGINATION
  const pages = Math.ceil(data.getAllLeads.count / per_page);
  const pageNumbers = [];
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = pageNumbers
  .slice(activePage - 1, activePage + 9)
  .map((number) => {
    return (
      <button
        className={
          activePage === number
            ? "bg-blue-500 text-white inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:text-blue-400 hover:text-white focus:outline-none" :
            "inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none border"} 
        key={number}
        onClick={() => {
          setActivePage(number);
          page = number;
          fetchMore({
            variables: { page: number },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              fetchMoreResult.getAllLeads.rows = [
                ...fetchMoreResult.getAllLeads.rows,
              ];
              return fetchMoreResult;
            },
          });
        }}
      >
        {number}
      </button>
    );
  });
  

  return (
    <div>
      {/* Table */}
      <div className="block w-full overflow-x-auto">
        <table className="items-center w-full bg-transparent border-collapse">
          {/* Table Header */}
          <thead>
            <tr>
              <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">
                NAME
              </th>
              <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">
                EMAIL
              </th>
              <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">
                NUMBER
              </th>
              <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">
                STATUS
              </th>
              <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">
                DATE
              </th>
              <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">
                UTM SOURCE
              </th>
              <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">
                UTM MEDIUM
              </th>
              <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">
                UTM CAMPAIGN
              </th>
              <th scope="col" className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">
                UTM CONTENT
              </th>
              { (data.getAllLeads.count === 0) ? "" : CustomHeader(leadData[0].body)}
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {ShowLeads(leadData)}
          </tbody>
        </table>
      </div>
      {/* FOOTER PAGINATION */}
      <div className="py-2 border-t text-right">
        {/* PREV Button */}
        { (activePage > 1) ? (
            <button className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
            onClick={
                () => {
                    const prevPage = (activePage > 1) ? activePage - 1 : 1;
                    setActivePage(prevPage);
                    page = prevPage;
                    fetchMore({
                        variables: { page: prevPage },
                        updateQuery: (prevResult, { fetchMoreResult }) => {
                            fetchMoreResult.getAllLeads.rows = [
                                ...fetchMoreResult.getAllLeads.rows
                            ];
                            return fetchMoreResult;
                        }
                    })
                }
            } >
                <i className="fas fa-chevron-left"></i>
            </button>
        ) : (
            <button 
            disabled 
            className="text-gray-500 align-middle inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
            >
                <i className="fas fa-chevron-left"></i>
            </button>
        ) }
        {/* Render Numbers */}
        {renderPageNumbers}
        {/* NEXT Button */}
        { (activePage < pages) ? (
            <button className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
            onClick={
                () => {
                    // const nextPageNum = activePage + 1;
                    const nextPageNum = (activePage < pages) ? activePage + 1 : pages;
                    setActivePage(nextPageNum);
                    page = nextPageNum;
                    fetchMore({
                        variables: { page: nextPageNum },
                        updateQuery: (prevResult, { fetchMoreResult }) => {
                            fetchMoreResult.getAllLeads.rows = [
                                ...fetchMoreResult.getAllLeads.rows
                            ];
                            return fetchMoreResult;
                        }
                    })
                }
            } >
                <i className="fas fa-chevron-right"></i>
            </button>
            ) : (
                <button 
                disabled 
                className="text-gray-500 align-middle inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                >
                    <i className="fas fa-chevron-right"></i>
                </button>
            )
        }
      </div>
    </div>
  )
}

// CREATE CUSTOM HEADER
function CustomHeader(lead) {
  if(lead != null) {
    const getBody = Object.keys(lead);
    return getBody.map((item,i) =>
      <th scope="col" style={{ maxWidth: "500px" }} key={i} className="px-3 align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left bg-gray-100 text-gray-800 border-gray-100">{ item }</th>
    )
  }
}

// SHOW ALL LEADS
function ShowLeads(leads) {
  return leads.map((lead, i) => (
    <tr key={lead.id} className="border-t w-full border-b hover:bg-gray-100">
      {/* NAME */}
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
        <Link to={"/admin/leads/" + lead.id} className="font-semibold text-blue-600">
          {lead.firstname} {lead.lastname}
        </Link>
      </td>
      {/* EMAIL */}
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {lead.email}
      </td>
      {/* NUMBER */}
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {lead.phone}
      </td>
      {/* STATUS */}
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {lead.status}
      </td>
      {/* DATE */}
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          <Moment format="YYYY-MM-DD HH:mm">{lead.createdAt}</Moment>
      </td>
      {/* UTM SOURCE */}
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {lead.utm_source}
      </td>
      {/* UTM MEDIUM */}
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {lead.utm_medium}
      </td>
      {/* UTM CAMPAIGN */}
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {lead.utm_campaign}
      </td>
      {/* UTM CONTENT */}
      <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
          {lead.utm_content}
      </td>
      {/* CUSTOM FIELDS */}
      {
        lead.body != null ? (
          Object.values(lead.body).map((item, i) => 
            <td key={i} className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap py-4">
              <ShowMoreText
                lines={3}
                more='Show more'
                less='Show less'
                className='content-css'
                anchorClass='my-anchor-css-class'
                expanded={false}
                width={150}
              >{item}</ShowMoreText>
            </td>
          )
        ) : (
          <></>
        )
      }

    </tr>
  ))
}


export default LeadsTable;