import React from "react";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// Create Auth Context for login state management
export const AuthContext = React.createContext();
const initialState = {
  isAuthenticated: false,
  user: {},
  token: null,
  id: null,
  email: null,
  role: null,
  membership: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
        cookies.set('token', action.payload.token, { path: '/' });
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token
      };
      case "SET_TOKEN":
        let token = cookies.get('token');
      return {
        ...state,
        isAuthenticated: true,
        token: token
      };
      case "SET_USER":
        console.log("SET USER: ");
      return {
        ...state,
        user: action.payload.me
      };
    case "LOGOUT":
        cookies.remove('token', { path: '/' });
        cookies.remove('clientId', { path: '/' });
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null
      };
    default:
      return state;
  }
};

// Create a provider for components to consume and subscribe to changes
export const AuthContextProvider = props => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
  
    return <AuthContext.Provider value={[state, dispatch]}>{props.children}</AuthContext.Provider>;
  };

