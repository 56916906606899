import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { ClientContext } from "contexts/ClientContext";
import ProductsTable from "components/Products/ProductsTable";
import { GET_ALL_PRODUCTS } from "graphql/products/products";

const Products = () => {
    // get client id
    const [state] = useContext(ClientContext);
    // search and sort 
    const [ findSearch, setFindSearch ] = useState("");
    const [ activePage , setActivePage ] = useState(1);
    // components
    let page = 1;
    let per_page = 10;
    // get all products
    const { loading,error,data, fetchMore, refetch } = useQuery(GET_ALL_PRODUCTS, {
        variables: {
            accountid: state.clientId,
            page: page,
            per_page: per_page,
            search: findSearch,
            sortby: "",
            sort_direction: "desc"
        }
    });
    const count = (loading) ? 0 : (( error )) ? 0 : data.getAllProducts.count;
    const pages = Math.ceil(count / per_page);
    const pageNumbers = [];
    for(let i = 1; i <= pages; i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers
    .slice(activePage - 1, activePage + 9)
    .map(number => {
        return(
            <button 
            className={ 
                (activePage === number) ? 
                "bg-blue-500 text-white inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:text-blue-400 hover:text-white focus:outline-none" :
                "inline-block align-middle text-sm text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none border"} 
            key={number}
            onClick={
                () => {
                    setActivePage(number);
                    page = number;
                    fetchMore({
                        variables: { page: number },
                        updateQuery: (prevResult, { fetchMoreResult }) => {
                            fetchMoreResult.getAllProducts.rows = [
                                ...fetchMoreResult.getAllProducts.rows
                            ];
                            return fetchMoreResult;
                        }
                    })
                }
            }>
                {number}
            </button>
        )
    });
    

    return (
        <div className="px-4">
            {/* FILTERS */}
            <div className="bg-white">
                <div className="py-4 px-6">
                    <div className="flex flex-wrap w-full items-center">
                        <div className="w-full lg:w-6/12">
                            <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
                                <div className="relative flex w-full flex-wrap items-stretch">
                                    <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                                        <i className="fas fa-search"></i>
                                    </span>
                                    <input 
                                    type="text" 
                                    placeholder="Search Product Name"
                                    className="px-2 py-2 placeholder-gray-400 text-gray-700 relative bg-white bg-white border text-sm outline-none focus:outline-none focus:shadow-outline w-full pl-10"
                                    onChange={ (e) => { setFindSearch(e.target.value) } }
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* END OF FILTERS */}

            {/* TABLE */}
            <div className="bg-white mt-5 px-6">
                {/* HEADER */}
                <div className="flex flex-wrap items-center py-3">
                    <div className="w-full lg:w-6/12">
                        <h3 className="tracking-wide font-bold">PRODUCTS</h3>
                    </div>
                </div>
                {/* TABLE */}
                {
                    loading ? (
                        <div className="px-4 mb-3">
                            <div className="inline-block bg-white shadow-md py-2 rounded-full">
                                <span className="block px-4">Fetching products...</span>
                            </div>
                        </div>
                    ) : error ? (
                        <div className="border-t text-center py-3">No Data Found :(</div>
                    ) : (
                        <div className="mt-2">
                            <ProductsTable {...{refetch}} data={data.getAllProducts.rows}/>
                        </div>
                    )
                }
                {/* PAGINATION */}
                <div className="py-2 border-t text-right">
                    {/* PREV Button */}
                    { (activePage > 1) ? (
                        <button className="inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                        onClick={
                            () => {
                                const prevPage = (activePage > 1) ? activePage - 1 : 1;
                                setActivePage(prevPage);
                                page = prevPage;
                                fetchMore({
                                    variables: { page: prevPage },
                                    updateQuery: (prevResult, { fetchMoreResult }) => {
                                        fetchMoreResult.getAllProducts.rows = [
                                            ...fetchMoreResult.getAllProducts.rows
                                        ];
                                        return fetchMoreResult;
                                    }
                                })
                            }
                        } >
                            <i className="fas fa-chevron-left"></i>
                        </button>
                    ) : (
                        <button 
                        disabled 
                        className="text-gray-500 align-middle inline-block align-middle text-base text-center w-8 h-8 bg-white mr-2 mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                        >
                            <i className="fas fa-chevron-left"></i>
                        </button>
                    ) }
                    {/* render numbers */}
                    {renderPageNumbers}
                    {/* NEXT Button */}
                    { (activePage < pages) ? (
                            <button className="inline-block align-middle text-base text-center w-8 h-8 bg-white mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                            onClick={
                                () => {
                                    // const nextPageNum = activePage + 1;
                                    const nextPageNum = (activePage < pages) ? activePage + 1 : pages;
                                    setActivePage(nextPageNum);
                                    page = nextPageNum;
                                    fetchMore({
                                        variables: { page: nextPageNum },
                                        updateQuery: (prevResult, { fetchMoreResult }) => {
                                            fetchMoreResult.getAllProducts.rows = [
                                                ...fetchMoreResult.getAllProducts.rows
                                            ];
                                            return fetchMoreResult;
                                        }
                                    })
                                }
                            } >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                            ) : (
                                <button 
                                disabled 
                                className="text-gray-500 inline-block align-middle text-base text-center w-8 h-8 bg-white mb-2 hover:bg-gray-300 hover:text-white focus:outline-none"
                                >
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            )
                        }
                </div>
            </div>
        </div>
    )
}

export default Products;