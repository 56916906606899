import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
  query customer($email: String, $id: String) {
    customer(email: $email, id: $id) {
      id
      firstname
      lastname
      email
      number
      date
      createdAt
      updatedAt
      points_balance
      referral_code
      order {
          total
          date
          id
          order_number
      }
      point {
          id
          transaction_date
          transaction_type
          expiry
          reward_id
          amount
          status
      }
    }
  }
`;
