import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
// form
import { useForm, Controller } from "react-hook-form";
// toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// switch
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
// gql
import { useMutation } from "@apollo/client";
import { UPDATE_PAGE } from "graphql/pages/update-pages";
import PageUpdateTags from "./PageUpdateFields/PageUpdateTags";
// Text Editor
import ReactQuill from "react-quill";
// datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components
import PageImageForm from "components/Pages/PageImageForm";
import PageMediaLibraryModal from "components/Pages/PageMediaLibraryModal.js";

const PageUpdateForm = (props) => {
  let status;
  let history = useHistory();
  // state
  const [ showModal, setShowModal ] = useState(false);
  // Effect
  // useEffect(() => {
  //   props.refetch();
  // },[props.refetch]);
  // form
  const { handleSubmit, register, control } = useForm();
  // mutations
  const [ updatePageMutation ] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success("Page Updated!", {
        position: "bottom-right",
        className: "black-background",
        bodyClassName: "grow-font-size",
        progressClassName: "fancy-progress-bar",
      });
      setTimeout(() => history.push("/admin/pages/"), 2000);
    },
    onError: (error) => {
      console.log(error, "error");
    },
  })
  // on submit
  const onSubmit = (fields) => {
    console.log(fields.schema);
    updatePageMutation({
      variables: {
        id: props.page.id,
        account_id: props.account_id,
        name: fields.name,
        title: fields.title,
        description: fields.description,
        status: status,
        order: fields.order, //testing
        body: fields.schema
      }
    })
  }
  // switcher
  const switcherOnChange = (value) => {
    status = value;
  }
  return (
    <>
      {/* FORM START */}
      <div className="relative mb-3">
        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Name</label>
        <input className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        defaultValue={props.page.name}
        name="name"
        type="text"
        ref={ register() }
        />
      </div>
      {/* Title */}
      <div className="relative mb-3">
        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Title</label>
        <input className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        name="title"
        type="text"
        ref={register()}
        defaultValue={props.page.title}
        />
      </div>
      {/* end of title */}
      {/* Slug */}
      <div className="relative mb-3">
        <label className="block text-gray-700 text-xs font-bold mb-2">
          <span className="uppercase">Slug:</span>
          <span className="ml-2 text-gray-500">
            {props.page.path}
          </span>
        </label>
        <input className="bg-gray-100 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm  w-full focus:outline-none"
        readOnly
        name="slug"
        type="text"
        ref={register()}
        defaultValue={props.page.slug}
        />
      </div>
      {/* end of slug */}

      {/* TAGS */}
      <PageUpdateTags account_id={props.account_id} page_id={props.page.id} tags={props.page.contenttag_page} />
      {/* END OF TAGS */}

      {/* Status & Content Type */}
      <div className="relative mb-3">
        <div className="flex flex-wrap w-full items-center">
          <div className="w-full lg:w-6/12 pr-4">
            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Content Type</label>
            <span className="block uppercase text-gray-700 text-xs mb-2">
              {props.page.contenttype === null
              ? "NIL"
              : props.page.contenttype.name}
            </span>
          </div>
          <div className="w-full lg:w-6/12 pr-4">
            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Status</label>
            <Switch
                onChange={switcherOnChange}
                defaultChecked={props.page.status}
              />
          </div>
        </div>
      </div>
      {/* End of Status & Content Type */}

      {/* Description */}
      <div className="relative mb-3">
        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">Meta Description</label>
        <input
          name="description"
          ref={register()}
          type="text"
          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          defaultValue={props.page.description}
        />
      </div>
      {/* End of Description */}

      {/* IMAGE */}
      <PageImageForm  
      image={props.page.image} 
      accountId={props.account_id} 
      pageId={props.page.id} 
      pageSlug={props.page.slug} 
      pagePath={props.page.path}
      {...{setShowModal}}/>
      {/* END OF IMAGE */}

      {/* MODAL */}
      {
          showModal ? (
            <PageMediaLibraryModal 
            toggle={setShowModal} 
            accountId={props.account_id} 
            pageId={props.page.id} 
            pageSlug={props.page.slug} 
            pagePath={props.page.path}/>
          ) : (
          null
          )
      }
      {/* END OF MODAL */}

      {/* BODY */}
      {
        (Array.isArray(props.page.contenttype.schema)) ? (
          props.page.contenttype.schema.map((field, i) => {
            switch(Object.values(field)[0]) {
              case "string":
                return <div className="pb-4" key={i}>
                  <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                      {Object.keys(field)}
                  </label>
                  <Controller
                      control={control}
                      name={`schema['${Object.keys(field)}']`}
                      defaultValue={`${( props.page.body === null || props.page.body[Object.keys(field)] === undefined) ? " " : props.page.body[Object.keys(field)]}`}
                      render={({ onChange, onBlur, value }) => (
                          <ReactQuill
                              theme="snow"
                              onChange={(text, delta, source, editor) => 
                                {onChange(text);}
                              }
                              defaultValue={`${(props.page.body === null ||  props.page.body[Object.keys(field)] === undefined) ? " " : props.page.body[Object.keys(field)]}`}
                            
                          />
                      )}
                  />
                </div>;
                case "textOnly":
                  return <div className="pb-4" key={i}>
                  <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                      {Object.keys(field)}
                  </label>
                    <Controller
                      control={control}
                      name={`schema['${Object.keys(field)}']`}
                      defaultValue={`${( props.page.body === null || props.page.body[Object.keys(field)] === undefined) ? " " : props.page.body[Object.keys(field)]}`}
                      render={({ onChange, onBlur, value }) => (
                        <input
                          name={`schema['${Object.keys(field)}']`}
                          ref={register()}
                          type="text"
                          className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          defaultValue={`${( props.page.body === null || props.page.body[Object.keys(field)] === undefined) ? " " : props.page.body[Object.keys(field)]}`}
                        /> 
                      )}
                  />

                  </div>;
              case "date":
                return <div className="pb-4" key={i}>
                  <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                    {Object.keys(field)}
                  </label>
                  <div className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                    <Controller
                        name={`schema['${Object.keys(field)}']`}
                        control={control}
                        defaultValue={
                          props.page.body === null || props.page.body[Object.keys(field)] === undefined ? new Date(Date.now())
                          : props.page.body != null && props.page.body[Object.keys(field)] === null ? null
                          : props.page.body != null && props.page.body[Object.keys(field)] === "" ? null
                          : props.page.body != null && props.page.body[Object.keys(field)] != undefined ? new Date(Date.parse(props.page.body[Object.keys(field)]))
                          : new Date(Date.parse(props.page.body[Object.keys(field)]))
                          // props.page.body != null || props.page.body[Object.keys(field)] != undefined  ? 
                          // Date.parse(props.page.body[Object.keys(field)])
                          // : props.page.body === null ? " "
                          // : Date.parse(`${props.page.body[Object.keys(field)]}`)
                        }
                        render={({ onChange, value }) => (
                            <DatePicker
                                className="w-full focus:outline-none"
                                dateFormat="yyyy-MM-dd"
                                selected={value}
                                onChange={onChange}
                            />
                        )}
                    />
                  </div>
                </div>;
              case "number":
                return <div className="pb-4" key={i}>
                  <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                    {Object.keys(field)}
                  </label>
                  <input className="border px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  name={`schema['${Object.keys(field)}']`}
                  type="number"
                  ref={register()}
                  defaultValue={`${(props.page.body === null ||  props.page.body[Object.keys(field)] === undefined) ? " " : props.page.body[Object.keys(field)]}`}
                  />
                </div>;
              default:
                return <></>;
            }
          })
        ) : (
          <></>
        )
      }
      {/* END OF BODY */}
      {/* Submit Button */}
      <button
          className="bg-green-500 text-center rounded uppercase font-bold px-3 py-2 text-white text-xs"
          onClick={handleSubmit(onSubmit)}
        >
        Update Page
      </button>
      {/* FORM END */}
      {/* Toast */}
      <ToastContainer />
    </>
  )
}

export default PageUpdateForm;